import React from 'react'
import {Document, Page, StyleSheet } from '@react-pdf/renderer';

export default function QuotePDFile({ pdfData }) {
    const globalStyles = StyleSheet.create({
        body: {
          fontSize: 10,
        },
      });
    
      // PDF-specific styles
      const styles = StyleSheet.create({
        divider: {
          width: "100%",
          borderBottomWidth: 2,
          borderBottomColor: "black",
          marginBottom: 10,
          marginTop: 10,
        },
        header: {
          flexDirection: "row",
          justifyContent: "space-evenly",
          paddingBottom: 60,
          paddingTop: 30,
          alignItems: "center",
        },
        fcType: {
          width: 40,
          height: 40,
          borderWidth: 2,
          borderColor: "black",
          padding: 3,
          textAlign: "center",
          fontSize: 30,
        },
        section1: {
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 2,
        },
        seller: {
          flexDirection: "column",
        },
        fcInfo: {
          flexDirection: "row",
        },
        fcInfoCol: {
          flexDirection: "column",
          marginHorizontal: 5,
        },
        client: {
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 2,
        },
        clientColumns: {
          flexDirection: "column",
        },
        table: {
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 2,
        },
      });
  return (
    <Document>
    <Page size="A4" style={globalStyles.body}></Page>
    </Document>
  )
}
