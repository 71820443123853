import React, { useEffect } from "react";
import Londres from "../../iconos/recomendados/Londres.jpg";
import Madrid from "../../iconos/recomendados/Madrid.jpg";
import Miami from "../../iconos/recomendados/Miami.jpg";
import Rio from "../../iconos/recomendados/Rio de Janeiro.webp";
import RecommendationCardFlight from "./RecommendationCardFlight";
import RecommendationCardPackages from "./RecommendationCardPackage";

import { Link, useParams } from "react-router-dom";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { usePackages } from "../../hooks/usePackages";
import { saveTourOperator } from "../../utils/saveTourOperator";


const data = [
  {
    image: Londres,
    title: "Londres",
    descode: "LHR",
  },
  {
    image: Madrid,
    title: "Madrid",
    descode: "MAD",
  },
  {
    image: Miami,
    title: "Miami",
    descode: "MIA",
  },
  {
    image: Rio,
    title: "Río de Janeiro",
    descode: "GIG",
  },
];

export default function RecommendationWrapper({
  isFlight,
  type,
}) {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id } = useParams();
  const { packages, getPackagesStep2 } = usePackages();
  const { currencies } = useGetCurrencies();
  useEffect(() => {
    getPackagesStep2();
}, [tourOP ]);

  return (
    <div className="md:flex md:flex-col md:w-full md:max-w-6xl md:px-3 md:pl-6 md:m-auto ">
      <div className="grid grid-cols-7 items-center mb:block md:inline-flex md:gap-80">
        <h2 className="text-2xl font-medium py-4 col-span-6 ">
          Te recomendamos los siguientes {isFlight ? "Vuelos" : "Paquetes"}!
        </h2>
        <Link
          to={`/${tour_op_id}/search-packages`}
          className="bg-[#CEDEF1] rounded-xl h-10 w-24 text-black shadow-sm shadow-lightBlue2 flex items-center justify-center text-black font-medium"
        >
          Ver Todos
        </Link>
      </div>

      <div className="flex md:justify-between mb:justify-center ">
        <div className="flex  mb:block md:inline-flex md:gap-6 mt-2 ">
          {type === "flights" ? (
            <>
              <>
                {data.map(({ image, title, descode }, index) => (
                  <div key={index} className=" ">
                    <RecommendationCardFlight {...{ image, title, descode }} />
                  </div>
                ))}
              </>
            </>
          ) : (
            <>
              {packages?.slice(0, 4)?.map((pack) => (
                <RecommendationCardPackages
                  key={pack.id}
                  pack={pack}
                  currencies={currencies}
                />
              ))}
            </>
          )}

         
        </div>
      </div>
    </div>
  );
}
