import React from "react";

export default function TableSuppliersHeader({ cols }) {
  return (
    <>
<thead className="rounded-lg">
  <tr className="bg-lightBlue  ">
    {cols.map((header, index) => (
      <th className="font-medium text-brightBlue text-center px-4" key={index}>
        {header}
      </th>
    ))}
  </tr>
</thead>
    </>
  );
}
