import React from "react";
import passengers from "../../../../iconos/passengers.png";
import { PASSENGER_DICTIONARY } from "../../../../utils/dictionaries/passengerDictionary";
export default function PassengerSelectorModule({
  register,
  handlePassenger,
  sumPassengers,
  passangerState
}) {
  const [passengerCounter] = passangerState;
  return (
    <div className="relative group/passengers mb:mb-6 md:mb-0">
      <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex flex-col items-center gap-1 justify-evenly cursor-pointer">
        <span className="flex gap-2 items-center">
          <img src={passengers} alt="passengers" className="w-4 h-3" />
          <p className="text-sm font-medium">Pasajeros</p>
        </span>
        <p className="w-[120px] py-1 px-2 mx-auto text-sm focus:outline-none bg-[#CEDEF1] rounded-md">
          {sumPassengers} {sumPassengers > 1 ? " pasajeros" : "pasajero"}
        </p>
      </div>
      <div className="absolute w-64 top-full mt-2 bg-[#EFEFEF] rounded-2xl p-4 flex flex-col gap-2 scale-0 group-hover/passengers:scale-100 transition-transform origin-top-left">
        <section className="flex flex-col gap-2">
          {Object.entries(passengerCounter).map((passenger) => {
            const { key, label, description, register, min } =
              PASSENGER_DICTIONARY[passenger[0]];
            return (
              <div className="flex justify-between items-center" key={key}>
                <div>
                  <h2 className="font-medium">{label}</h2>
                  <p className="text-xs text-gray">{description}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <button
                    type="button"
                    onClick={() => handlePassenger(key, register, min, "-")}
                    className="border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    -
                  </button>
                  <p className="text-xl">{passenger[1]}</p>
                  <button
                    type="button"
                    onClick={() => handlePassenger(key, register, min, "+")}
                    className="border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
}
