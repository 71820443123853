import React from 'react';

export default function OriginFilter({ handleDisplay, arrow, possibleCitiesAndMonth, displayedOricode, filters, handleChecks, capitalizeFirstLetter }) {
    return (
        <div>
            <section
                onClick={() => handleDisplay("origen")}
                id="origen-header"
                className="cursor-pointer"
            >
                <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
                    <h2 className="font-low">Ciudades de Partida</h2>
                    <img
                        src={arrow}
                        alt="arrow"
                        className="w-4 h-2 -rotate-180 transition-all"
                        id="origen-arrow"
                    />
                </header>
            </section>
            <section
                className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
                id="origen-body"
            >
                {Object.keys(possibleCitiesAndMonth.origin_code).map((el) => {

                    const oricode = possibleCitiesAndMonth.origin_code[el];

                    // Skip if the oricode is null or undefined, or if it has already been displayed
                    if (!oricode || displayedOricode.has(oricode)) {
                        return null;
                    }

                    // Add this oricode to the set of displayed codes
                    displayedOricode.add(oricode);
                    const formattedOricode = capitalizeFirstLetter(oricode);

                    // Check if the current oricode is in the filters.oricode array
                    const isChecked = filters.origin_code.includes(oricode);

                    return (
                        <div className="flex items-center" key={el}>
                            <input
                                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                type="checkbox"
                                id={el}
                                name="origen"
                                checked={isChecked} // Adjusted to check if the oricode is in the filters array
                                onChange={(evt) => handleChecks(evt, oricode, 'origin_code')}
                            />
                            <label className="flex gap-2 items-center">
                                <p className="max-w-[15ch] ml-2">{formattedOricode}</p>
                            </label>
                        </div>
                    );
                })}
            </section>
        </div>
    );
}