import React from "react";

export default function SelectQuoteStatus({ status }) {
  const statusOptions = [
    { value: "to-start", label: "To Start", color: "#FFDD57" },
    { value: "quote-asked", label: "Quote Asked", color: "#FFABAB" },
    { value: "info-missing", label: "Info Missing", color: "#FF61A6" },
    { value: "no-answer", label: "No Answer", color: "#FF6F91" },
    { value: "ask-info", label: "Ask Info", color: "#6A0572" },
    { value: "quote-sent", label: "Quote Sent", color: "#57A0FF" },
    { value: "book", label: "Booked", color: "#ABFFB0" },
  ];
  // const [currentStatus, setCurrentStatus] = React.useState('quote-sent'); 

  return (
    <>
      <select defaultValue={status} className="border-2 border-gray2 rounded-lg ">
        <option value="">Select Status</option>
        {statusOptions.map(({ value, label, color }) => (
          <option key={value} value={value} style={{ color }}>
            {label}
          </option>
        ))}
      </select>
    </>
  );
}
