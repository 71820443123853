import { useCallback, useState } from "react";
import axios from "axios";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export const useTours = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [loading, setLoading] = useState(false);

  const [tours, setTours] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createTour = async (pack) => {
    const response = await axios.post(BASE_URL + "v2/tours", pack, headers);
    return response;
  };

  const updateTour = async (tour) => {
    const { id, ...restOfTour } = tour;

    return await axios.patch(
      BASE_URL + `v2/tours/${id}`,
      {
        data: { ...restOfTour },
      },
      headers
    );
  };

  const deleteTour = async (id) => {
    return await axios.delete(BASE_URL + `v2/tours/${id}`, headers);
  };

  const getTours = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BASE_URL}v2/tours/package/${id}`,
          headers
        );
        setTours(response.data.response);
      } catch (error) {
        console.error(error); // Handle error appropriately
        if (error.response && error.response.status === 404) {
          setTours([]);
        }
      } finally {
        setLoading(false);
      }
    },
    [headers, BASE_URL]
  ); // Include headers as a dependency if it's coming from outside the function

  return {
    tours,
    loading,
    getTours,
    createTour,
    updateTour,
    deleteTour,
  };
};
