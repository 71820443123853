import { create } from "zustand";

// Load initial state from localStorage, or fallback to default values
const getInitialState = () => {
  const packageSelected = localStorage.getItem('packageSelected');
  const numberOfPassengers = localStorage.getItem('numberOfPassengers');
  
  return {
    packageSelected: packageSelected ? JSON.parse(packageSelected) : {},
    numberOfPassengers: numberOfPassengers ? JSON.parse(numberOfPassengers) : {
      ADT: 1,
      CHD: 0,
      INF: 0,
    },
  };
};

export const useSelectPaquetes = create((set) => ({
  ...getInitialState(),

  selectPackage: (p) => {
    set(() => ({
      packageSelected: p,
    }));
    localStorage.setItem('packageSelected', JSON.stringify(p)); // Save to localStorage
  },

  setNumberOfPassengers: (newPassengersValue) => {
    set(() => ({
      numberOfPassengers: newPassengersValue,
    }));
    localStorage.setItem('numberOfPassengers', JSON.stringify(newPassengersValue)); // Save to localStorage
  },
}));