import React from "react";
import edit from "../../iconos/edit_icon.png";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import useRulesFares from "../../hooks/useRulesFares";
import { ModalGeneric } from "../../utils/ModalGeneric";

import { useState } from "react";
import UpdateFeesModal from "./components/UpdateFeesModal";
import SEO from "../../utils/SEO";
import FormAddRulesFares from "./FormAddRulesFares";

import PanelSelector from "./PanelSelector";

export default function PanelRulesFare() {

  const { role_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [typeModal, setTypeModal] = useState("");
  const [editIndex, setEdit] = useState("");
  const { rulesFare, getRulesFares, createRulesFares } = useRulesFares();

  const postRulesFares = (data) => {
    createRulesFares({
      ruleFare: {
        ...data,
        role_id,
      },
    }).then(() => {
      getRulesFares();
      toggleVisibility();
    });
  };

  const openModal = (modal, index) => {
    toggleVisibility();
    setTypeModal(modal);
    setEdit(index);
  };

  const closeModal = () => {
    toggleVisibility();
    getRulesFares();
  };

  return (
    <>
      <SEO title={"Impuestos y Comisiones"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <div className="mt-4">
          <PanelSelector type={"rules"} />
        </div>
     
        <header className="my-8 flex justify-between items-center">
          <div>
            <h1 className="text-darkBlue font-[700] text-3xl">
              Reglas de Negocio
            </h1>
            <p className="text-darkBlue/70 text-lg">
              Controlá, gestioná y asigná comisiones, descuentos e impuestos
              según las reglas creadas.
            </p>
          </div>
          <button
            className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
            onClick={() => openModal("new")}
          >
            Agregar
          </button>
        </header>

        {rulesFare?.length > 0 && (
          <section className="bg-white p-8 rounded-3xl text-lg flex flex-col gap-2 mt-16">
            <table className="w-full border-collapse rounded-lg overflow-hidden ">
              <thead>
                <tr className="bg-lightBlue/30 rounded py-2 text-dark">
                  <th className="p-4 text-left">Nombre</th>
                  <th className="p-4 text-left">Tipo</th>
                  <th className="p-4 text-left">Monto</th>
                  <th className="p-4 text-left">Sobre</th>
                  <th className="p-4 text-left">Activa</th>
                  <th className="p-4 text-left">Única Regla</th>
                  <th className="p-4 text-left">Acción</th>
                </tr>
              </thead>
              <tbody>
                {rulesFare.map(
                  (
                    {
                      id,
                      name,
                      operation,
                      active,
                      operation_fiscal,
                      variable,
                      rate_type,
                      number,
                      running,
                    },
                    index
                  ) => (
                    <tr key={id} className="bg-white hover:bg-lightBlue/20">
                      <td className="p-4 whitespace-nowrap">{name}</td>
                      <td className="p-4 whitespace-nowrap">
                        {operation_fiscal}
                      </td>
                      <td>
                        <div className="flex inline-flex">
                          {operation === "suma" ? (
                            <>
                              <p className="mr-2">+</p>
                            </>
                          ) : (
                            <div>
                              <p className="mr-2">-</p>
                            </div>
                          )}
                          {rate_type === "porcentaje" ? (
                            <>
                              <p>%</p>
                            </>
                          ) : (
                            <>
                              <p>$</p>
                            </>
                          )}
                          <p>{number}</p>
                        </div>
                      </td>
                      <td className="p-4 whitespace-nowrap">{variable}</td>
                      <td className="p-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={active}
                          disabled
                          className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        />
                      </td>
                      <td className="p-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={running}
                          disabled
                          className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        />
                      </td>

                      <td className="p-4 whitespace-nowrap">
                        <button onClick={() => openModal("edit", index)}>
                          <img src={edit} alt="edit" className="h-8" />
                        </button>
                        {/* <button onClick={() => openModal("delete")}>
            <img src={trash} alt="delete" className="h-8" />
          </button> */}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </section>
        )}

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {typeModal === "new" && (
            <FormAddRulesFares onSubmit={postRulesFares} />
          )}
          {typeModal === "edit" && (
            <UpdateFeesModal
              datas={rulesFare[editIndex]}
              closeModal={closeModal}
            />
          )}
        </ModalGeneric>

        {/* 
        <Link
          to={`/${tour_op_id}/panel/profile/${agency_id === 1 ? "my-tour-operator" : "my-agency"
            }`}
          className="absolute left-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Anterior
        </Link>
        <Link
          to={`/${tour_op_id}/panel/profile/${agency_id === 1 ? "agencies" : "users"
            }`}
          className="absolute right-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Siguiente
        </Link> */}
      </main>
    </>
  );
}
