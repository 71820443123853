import React from "react";
import EmailButton from "./tableButtons/EmailButton";
import CredentialsButton from "./tableButtons/CredentialsButton";
import CallButton from "./tableButtons/CallButton";
import EditButton from "./tableButtons/EditButton";


export default function TableActionsPackages({
  supp
}) {

  return (
    <div className="flex flex-row">
      <CredentialsButton />
      <EditButton Supplier_id={supp.id}/>
      <EmailButton suppEmail={supp.email}/>
      <CallButton suppPhone={supp.phone_number}/>
    </div>
  );
}