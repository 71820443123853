import React from "react";

export default function MidFooter() {
  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-row gap-4 w-full gap-4 leading-0">
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          Institucional
        </a>
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          Condiciones Generales
        </a>
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          Políticas de privacidad
        </a>
        <a
          className="text-[.9rem] m-0 flex-grow"
          target="_blank"
          href={"https://thewalltrip.com"}
          rel="noreferrer"
        >
          Botón de arrepentimiento
        </a>
      </div>
    </div>
  );
}
