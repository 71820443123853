import SEO from "../../utils/SEO";
import { useNavigate, useParams } from "react-router-dom";
import BookingTable from "../../utils/bookingTable/BookingTable";

export const BOOKINGS_STATUS = [
  { key: "allBookings", label: "Todas las Reservas" },
  { key: "pending", label: "Pendiente" },
  // { key: "to-book", label: "Reservar" },
  { key: "for-ticketing", label: "Ok p Emisión" },
  { key: "to-ticket", label: "Emitir" },
  { key: "confirmed", label: "Confirmado" },
];

export default function MyBookings() {
  const { type, tour_op_id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <SEO title={"Mis Reservas"} />
      <main className="flex flex-col w-full mt-8 font-body">
        <header className="flex justify-between mb:flex-col md:flex-row items-center mb-4 block">
          <div>
          <span>
            <h1 className="font-[700] text-darkBlue text-3xl">Mis Reservas</h1>
            <p className="text-darkBlue">Dashboard Overview</p>
          </span>

          </div>
          
          <div className="relative flex items-center border-2 border-lightBlue rounded-3xl h-12">
            <button
              className="px-8 rounded-3xl w-32 z-10 text-lg"
              onClick={() =>
                navigate(`/${tour_op_id}/panel/sales/bookings/flight`)
              }
            >
              Vuelos
            </button>
            <button
              className="px-8 rounded-3xl w-32 z-10 text-lg"
              onClick={() =>
                navigate(`/${tour_op_id}/panel/sales/bookings/package`)
              }
            >
              Paquetes
            </button>
            <span
              className={`absolute h-12 w-32 bg-lightBlue transition-all rounded-3xl ${
                type === "package" ? "right-0" : "right-32"
              }`}
            />
          </div>
        </header>

        <BookingTable
          bookingStatus={BOOKINGS_STATUS}
          path={`/${tour_op_id}/panel/sales/bookings/`}
        />
      </main>
    </>
  );
}
