import { z } from "zod";

export const traveleSchema = z.object({
  first_name: z.string().min(1, { message: "Campo incompleto" }),
  last_name: z.string().min(1, { message: "Campo incompleto" }),
  gender: z.string().min(1, { message: "Campo incompleto" }),
  email_address: z.string().min(1, { message: "Campo incompleto" }),
  date_of_birth: z.string().min(1, { message: "Campo incompleto" }),
  category: z.union([z.string(), z.number()]).nullable().default(null),
});
