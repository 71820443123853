import { format } from "date-fns";
import React from "react";

export default function TourView({ data }) {
  return (

      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Detalles del Paquete</h2>
        <table className="w-full text-left">
          <tbody>
            <tr>
              <td className="font-bold">ID:</td>
              <td>{data.id}</td>
            </tr>
            <tr>
              <td className="font-bold">Fecha de Entrada:</td>
              <td>{format(new Date(data.dateIn), "dd/MM/yyyy")}</td>
            </tr>
            <tr>
              <td className="font-bold">Fecha de Salida:</td>
              <td>{format(new Date(data.dateOut), "dd/MM/yyyy")}</td>
            </tr>
            <tr>
              <td className="font-bold">Hoteles:</td>
              <td>{data.hotels}</td>
            </tr>
            <tr>
              <td className="font-bold">Categoría:</td>
              <td>{data.category}</td>
            </tr>
            <tr>
              <td className="font-bold">Plan de Comidas:</td>
              <td>{data.meal_plan}</td>
            </tr>
            <tr>
              <td className="font-bold">Días:</td>
              <td>{data.days}</td>
            </tr>
            <tr>
              <td className="font-bold">Noches:</td>
              <td>{data.nights}</td>
            </tr>
            <tr>
              <td className="font-bold">Stock:</td>
              <td>{data.stock}</td>
            </tr>
            <tr>
              <td className="font-bold">Stock Original:</td>
              <td>{data.original_stock}</td>
            </tr>
            <tr>
              <td className="font-bold">Disponibilidad:</td>
              <td>{data.availability}</td>
            </tr>
            <tr>
              <td className="font-bold">Comentarios:</td>
              <td>{data.comments}</td>
            </tr>
            <tr>
              <td className="font-bold">ID del Paquete:</td>
              <td>{data.id_package}</td>
            </tr>
          </tbody>
        </table>
      </div>
  
  );
}
