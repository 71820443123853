import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export default function QuoteSorting({ setSortCriteria, sortCriteria }) {
  const handleSortChange = (key) => {
    setSortCriteria((prev) => ({
      key,
    }));
  };

  const handleDirectionToggle = () => {
    setSortCriteria((prev) => ({
      ...prev,
      direction: prev.direction === "asc" ? "desc" : "asc", // Toggle direction
    }));
  };

  return (
    <div className="inline-flex items-center gap-2">
      <h1>Ordenar por</h1>
      <select
        className="border-0 rounded-lg text-blue-500 focus:outline-none appearance-none bg-transparent hover:bg-transparent w-1/3"
        onChange={(e) => handleSortChange(e.target.value)}
        value={sortCriteria.key}
      >
        <option value="date">próximos</option>
        <option value="name">Nombre</option>
      </select>
      <button
        className="flex items-center"
        aria-label={`Sort ${
          sortCriteria.direction === "asc" ? "descending" : "ascending"
        }`}
        onClick={handleDirectionToggle}
      >
        {sortCriteria.direction === "asc" ? (
          <FaArrowUp size={10} />
        ) : (
          <FaArrowDown size={10} />
        )}
      </button>
    </div>
  );
}
