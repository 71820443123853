import React from 'react'
import CallIcon from '@mui/icons-material/Call';

export default function CallButton(suppPhone) {
  return (
    <a
          className="relative mx-0.5 h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/link"
          href={`tel:${suppPhone}`}
        >
          <CallIcon
            sx={{
              color: "#A0AFCD",
              height: "22px",
              width: "22px",
            }}
            alt="llamar"
          />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/link:scale-100">
            Llamar
          </span>
        </a>
  )
}
