import clsx from "clsx";
import { NavLink, useLocation, useParams } from "react-router-dom";

export default function NavMenu({ image, label, alt, link }) {
  const params = useParams();
  const location = useLocation();

  return (
    <NavLink
      className={({ isActive, isPending }) =>
        isPending
          ? "flex flex-col items-center gap-2"
          : isActive
          ? "flex flex-col items-center font-medium gap-2"
          : "flex flex-col items-center gap-2"
      }
      to={link}
    >
      <img
        className={`w-8 relative ${label === "Vuelos" && "h-6"}`}
        src={image}
        alt={alt}
      />
      <p
        className={clsx(
          "text-black relative px-4 py-1 h-6 flex items-center justify-center rounded-md",
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-flight") ||
                location?.search.includes("flights")) &&
              label === "Vuelos",
          },
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-packages") ||
                location?.search.includes("package") ||
                location?.pathname.includes("package-detail")) &&
              label === "Paquetes",
          },
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-hotels") ||
                location?.search.includes("hoteles") ||
                location?.pathname.includes("hotels-request")) &&
              label === "Hoteles",
          },
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-activities") ||
                location?.search.includes("actividad")) &&
              label === "Actividades",
          },
          {
            "bg-[#CEDEF1]":
              (link.toLowerCase().includes(params?.type?.toLowerCase()) &&
                location.pathname !== "/") ||
              (label === "Vuelos" && location.pathname === "/"),
          }
        )}
      >
        {label}
      </p>
    </NavLink>
  );
}
