import { z } from "zod"
import { packageSchema } from "./packages";
import { tourSchema } from "./tours";
import { feeSchema } from "./fees";

export const completePackage = z.object({
    packageSchema: packageSchema,
    tourSchema: tourSchema,
    feeSchema: feeSchema,
});
