import React from 'react';

export default function MonthFilter({ handleDisplay, arrow, monthMap, filters, handleChecks }) {
    return (
        <div>
            <section
                onClick={() => handleDisplay("month")}
                id="month-header"
                className="cursor-pointer"
            >
                <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
                    <h2 className="font-low">Mes de Salida</h2>
                    <img
                        src={arrow}
                        alt="arrow"
                        className="w-4 h-2 -rotate-180 transition-all"
                        id="month-arrow"
                    />
                </header>
            </section>
            <section
                className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
                id="month-body"
            >
                {Object.entries(monthMap).map(([monthName, monthNumber]) => {
                    return (
                        <div className="flex items-center" key={monthNumber}>
                            <input
                                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                type="checkbox"
                                id={monthNumber}
                                name="month"
                                checked={filters.month.includes(monthNumber)} // Ensure the checkbox reflects the current filter state
                                onChange={(evt) => handleChecks(evt, monthNumber, 'month')}
                            />
                            <label  className="flex gap-2 items-center">
                                <p className="max-w-[15ch] ml-2">{monthName}</p>
                            </label>
                        </div>
                    );
                })}
            </section>
        </div>
    );
}