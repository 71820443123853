import edit from "../../iconos/edit_icon.png";
import trash from "../../iconos/delete.png";
import eye from "../../iconos/eye-blue.png";
import whatsapp from "../../iconos/whatsapp_logo.png";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import { useTravelers } from "../../hooks/useTravelers";


export default function TravelersTableButtons({ travelerData, openModal }) {

    return (
        <div className="flex justify-between gap-2 items-center">
            <button
                className="relative h-[24px] w-[24px] hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/edit"
                onClick={() => openModal(travelerData, "edit")}
            >
                <img src={edit} alt="edit" className="h-6" />
                <span className="absolute whitespace-nowrap bg-white border -top-8 -left-0 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
                    Editar
                </span>
            </button>


            <a
                href={`mailto:${travelerData?.email_address}`}
                className="relative h-[24px] w-[24px] flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/mail"
            >
                <MailOutlinedIcon
                    sx={{
                        color: "#A0AFCD",
                        height: "18px",
                        width: "18px",
                    }}
                    alt="mail"
                />
                <span className="absolute whitespace-nowrap bg-white border -top-8 left-1/2 transform -translate-x-1/2 py-0.5 px-1 rounded scale-0 transition-transform group-hover/mail:scale-100">
                    Enviar Mail
                </span>
            </a>
            <a
                href={`tel:${travelerData.number}`}
                className="relative h-[24px] w-[24px] flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/call"
            >
                <CallEndOutlinedIcon
                    sx={{
                        color: "#A0AFCD",
                        height: "18px",
                        width: "18px",
                    }}
                    alt="call"
                />
                <span className="absolute whitespace-nowrap bg-white border -top-8 left-1/2 transform -translate-x-1/2 py-0.5 px-1 rounded scale-0 transition-transform group-hover/call:scale-100">
                    Llamar Pasajero
                </span>
            </a>
            {/* <button
                className="relative h-[24px] w-[24px] hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/view"
                onClick={() => {
                    openModal(travelerData, "view");
                }}
            >
                <img src={eye} alt="view" className="h-6" />
                <span className="absolute whitespace-nowrap bg-white border -top-8 -left-0 py-0.5 px-1 rounded scale-0 transition-transform group-hover/view:scale-100">
                    Ver
                </span>
            </button> */}

            {/* <a
                href={`http://wa.me/${traveler?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="relative h-[24px] w-[24px] hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/wpp"
            >
                <img src={whatsapp} alt="wpp" className="h-5" />
                <span className="absolute whitespace-nowrap bg-white border -top-8 -left-0 py-0.5 px-1 rounded scale-0 transition-transform group-hover/wpp:scale-100">
                    Envíar Whatsapp
                </span>
            </a> */}
        </div>
    )
}
