import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Link } from "react-router-dom";
import arrow from "../../iconos/chevron_down_gray.png";
import numberWithCommas from "../../utils/numberWithCommas";

const handleDisplay = (item) => {
  const arrow = document.getElementById(`${item}-arrow`);
  const body = document.getElementById(`${item}-body`);
  const section = document.getElementById(`${item}-section`);

  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  section.classList.toggle("my-4");
};

export default function InvoicesByWeek({ week, weekDays, isClosed }) {
  let [monday, sunday] = weekDays.split("_");
  monday = format(new Date(monday), "dd MMM", { locale: es });
  sunday = format(new Date(sunday), "dd MMM yyyy", { locale: es });

  const firstID = week[0].id;

  return (
    <section
      className={`transition-all ${!isClosed && "my-4"}`}
      id={`${firstID}-section`}
    >
      <header
        className="border-b pb-1 px-4 my-2 flex items-center gap-4 cursor-pointer"
        onClick={() => handleDisplay(firstID)}
      >
        <img
          src={arrow}
          alt="arrow"
          id={`${firstID}-arrow`}
          className={`w-4 transition-all ${
            isClosed ? "rotate-0" : "-rotate-180"
          }`}
        />
        <h1 className="uppercase font-medium">
          BSP DEL {monday} AL {sunday}
        </h1>
      </header>
      <section
        id={`${firstID}-body`}
        className={`transition-transform duration-200 origin-top overflow-hidden ${
          isClosed && "scale-y-0 h-0"
        }`}
      >
        <div className="grid grid-cols-14 bg-lightBlue py-2 pl-4 rounded font-medium">
          <p>File</p>
          <p className="col-span-2 mb:hidden md:table-cell">Fecha de pago</p>
          <p className="col-span-2 mb:hidden md:table-cell">Fecha de salida</p>
          <p className="col-span-3 mb:hidden md:table-cell">Grupo de pax</p>
          <p className="col-span-2 mb:hidden md:table-cell">Facturado</p>
          <p className="col-span-2 mb:hidden md:table-cell">Pagado</p>
          <p className="col-span-2 mb:hidden md:table-cell">A pagar</p>
        </div>

        {week.map((booking) => {
          const parseFlight = JSON.parse(booking.Flights[0].options);
          const departureDate =
            parseFlight?.itineraries?.[0]?.segments?.[0]?.departure?.at;
          const { grandTotal, currency } = parseFlight.price;
          const { first_name, last_name } = booking.Travelers[0];

          return (
            <div key={booking.id} className="grid grid-cols-14 pl-4">
              <Link
                to={`${booking.id}`}
                className="bg-brightBlue py-0.5 text-white text-center rounded-sm w-12 text-sm my-0.5 transition-all hover:shadow-md hover:scale-105"
              >
                {booking.id}
              </Link>
              <p className="col-span-2">-</p>
              <p className="col-span-2">
                {format(new Date(departureDate), "dd/MM/yyy")}
              </p>
              <p className="col-span-3 capitalize">
                {last_name.toLowerCase()} {first_name.toLowerCase()}
              </p>
              <p className="col-span-2">-</p>
              <p className="col-span-2">-</p>
              <p className="col-span-2">
                {numberWithCommas(Number(grandTotal), 2)} {currency}
              </p>
            </div>
          );
        })}
      </section>
    </section>
  );
}
