import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import { saveTourOperator } from "../../utils/saveTourOperator";
import PanelSelector from "./PanelSelector";

export const PanelGeneralTourOperator = () => {
  const { tour_op_id } = useParams();
  const { agency_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  const { tourOP } = saveTourOperator();
  const {
    name,
    logo,
    domain,
    email,
    TourOperatorPhones,
    TourOperatorSocialMedia,
    TourOperatorAddresses,
  } = tourOP;

  return (
    <>
      <SEO title={"Mi Perfil"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <div className="mt-4">
          <PanelSelector type={"operator"} />
        </div>
        <header className="my-8 flex justify-between items-center">
          <div>
            <h1 className="text-darkBlue font-[700] text-3xl">Mi Perfil</h1>
            <p className="text-darkBlue/70 text-lg">
              Controlá, personalizá y gestioná todos tus datos.
            </p>
          </div>
        </header>

        <form className="bg-white p-16 pb-20 rounded-3xl">
          <h2 className="text-darkBlue font-[700] text-2xl">
            Nombre de Operadora
          </h2>

          <section className="grid grid-cols-3 gap-4 mt-4">
            <div className="col-span-2">
              <h3 className="font-medium text-xl mb-2">Información General</h3>
              <div className="grid grid-cols-2 gap-4">
                <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                  {name}
                </p>
                <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                  {email}
                </p>
                <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                  {name}
                </p>
                <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                  {TourOperatorAddresses?.[0]?.street_name},{" "}
                  {TourOperatorAddresses?.[0]?.city_name}
                </p>
                <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                  +{TourOperatorPhones?.[0]?.country_calling_code}{" "}
                  {TourOperatorPhones?.[0]?.number}
                </p>
                <a
                  href={domain}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-lightBlue/50 rounded-lg p-4 font-body underline"
                >
                  {domain}
                </a>
                <a
                  href={
                    TourOperatorSocialMedia?.find(
                      (red) => red?.redSocial === "Facebook"
                    )?.url
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="bg-lightBlue/50 rounded-lg p-4 font-body underline"
                >
                  {
                    TourOperatorSocialMedia?.find(
                      (red) => red?.redSocial === "Facebook"
                    )?.url
                  }
                </a>
                <a
                  href={
                    TourOperatorSocialMedia?.find(
                      (red) => red?.redSocial === "Whatsapp"
                    )?.url
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="bg-lightBlue/50 rounded-lg p-4 font-body underline"
                >
                  {
                    TourOperatorSocialMedia?.find(
                      (red) => red?.redSocial === "Whatsapp"
                    )?.url
                  }
                </a>
              </div>
            </div>

            <div>
              <h3 className="font-medium text-xl mb-2">Identidad Visual</h3>
              <div className="grid grid-rows-4 gap-4 h-min">
                <figure className="row-span-3 border border-gray rounded-xl grid justify-center items-center">
                  <img src={logo} alt="Logo" className="max-h-48" />
                </figure>

                <a
                  href={
                    TourOperatorSocialMedia?.find(
                      (red) => red?.redSocial === "Instagram"
                    )?.url
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="bg-lightBlue/50 rounded-lg p-4 font-body underline"
                >
                  {
                    TourOperatorSocialMedia?.find(
                      (red) => red?.redSocial === "Instagram"
                    )?.url
                  }
                </a>
              </div>
            </div>
          </section>
        </form>

        {/* <Link
          to={`/${tour_op_id}/panel/profile/${
            agency_id === 1 ? "fees" : "my-agency"
          }`}
          className="absolute right-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Siguiente
        </Link> */}
      </main>
    </>
  );
};
