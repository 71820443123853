import React from 'react'
import arrow from "../../iconos/chevron_down_gray.png";
import DestinationFilter from './packageFilters/DestinationFilter';
import OriginFilter from './packageFilters/OriginFilter';
import MonthFilter from './packageFilters/MonthFilter';
import CurrencyFilter from './packageFilters/CurrencyFilter';
import YearFilter from './packageFilters/YearFilter';
import { monthMap } from '../../utils/months';


export default function PackageFilters({ possibleCitiesAndMonth, filters, setFilters }) {
    const displayedDescode = new Set();
    const displayedOricode = new Set();

    
    const capitalizeFirstLetter = (text) => {
        if (typeof text !== 'string') return text; // Check if text is a string
        if (!text) return text; // Handle empty strings
        return text.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    };

    const handleChecks = (evt, filter, type) => {
        const { checked } = evt.target;
        console.log(type)
        setFilters((curr) => {
            let updatedArray;
           
            // Determine which filter type to update
            if (type === 'destination_code') {
                updatedArray = checked
                    ? [...curr.destination_code, filter] // Add the filter if checked
                    : curr.destination_code.filter(value => value !== filter); // Remove the filter if unchecked
            } else if (type === 'origin_code') {
                updatedArray = checked
                    ? [...curr.origin_code, filter] // Add the filter if checked
                    : curr.origin_code.filter(value => value !== filter); // Remove the filter if unchecked
            } else if (type === 'month') {
                updatedArray = checked
                    ? [...curr.month, filter] // Add the filter if checked
                    : curr.month.filter(value => value !== filter); // Remove the filter if unchecked
            } else if (type === 'currency') {
                updatedArray = checked
                    ? [...curr.currency, filter] // Add the filter if checked
                    : curr.currency.filter(value => value !== filter); // Remove the filter if unchecked
                } else if (type === 'year') {
                    updatedArray = checked
                        ? Array.from(new Set([...curr.year, filter])) // Add the filter and remove duplicates
                        : curr.year.filter(value => value !== filter); // Remove the filter if unchecked
                }
            return {
                ...curr,
                [type]: updatedArray, // Update the respective filter type directly in the filters object
            };
        });
    };

    const handleDisplay = (item) => {
        const body = document.getElementById(`${item}-body`);
        const arrow = document.getElementById(`${item}-arrow`);
        body.classList.toggle("scale-y-0");
        body.classList.toggle("h-0");
        arrow.classList.toggle("-rotate-180");
        arrow.classList.toggle("rotate-0");
    };

    const deleteFilters =()=>{
        setFilters(
            {
                month:  [],
                destination_code:  [],
                origin_code:  [],
                currency:  [],
                year:  [],
                
            
              }
        )
        console.log(filters)
    }

    return (
        <div className="flex flex-col pr-4 w-[230px]">
            <div className='flex inline-flex items-center'>
            <h2 className="font-medium p-2 text-xl">Filtros</h2>
            <button className='bg-transparent text-blue-500 text-xs mt-1' onClick={deleteFilters} >Borrar Filtros</button>
            </div>
            <DestinationFilter  capitalizeFirstLetter={capitalizeFirstLetter} handleDisplay={handleDisplay} arrow={arrow} possibleCitiesAndMonth={possibleCitiesAndMonth} displayedDescode={displayedDescode} filters={filters} handleChecks={handleChecks} />
            <OriginFilter capitalizeFirstLetter={capitalizeFirstLetter} handleDisplay={handleDisplay} arrow={arrow} possibleCitiesAndMonth={possibleCitiesAndMonth} displayedOricode={displayedOricode} filters={filters} handleChecks={handleChecks} />
            <MonthFilter handleDisplay={handleDisplay} arrow={arrow} monthMap={monthMap} filters={filters} handleChecks={handleChecks} />
            <YearFilter capitalizeFirstLetter={capitalizeFirstLetter} handleDisplay={handleDisplay} arrow={arrow} possibleCitiesAndMonth={possibleCitiesAndMonth} displayedOricode={displayedOricode} filters={filters} handleChecks={handleChecks} />
            <CurrencyFilter capitalizeFirstLetter={capitalizeFirstLetter} handleDisplay={handleDisplay} arrow={arrow} possibleCitiesAndMonth={possibleCitiesAndMonth} displayedOricode={displayedOricode} filters={filters} handleChecks={handleChecks} /> 
        </div>
    )
}
