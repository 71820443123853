import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import { useBookings } from "../../hooks/useBookings";
import { useEffect, useState } from "react";
import { saveTourOperator } from "../../utils/saveTourOperator";
import Anicarga from "../anicarga";
import InvoicesByWeek from "./InvoicesByWeek";
import InvoicesFilters from "./InvoicesFilters";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import useAgencies from "../../hooks/useAgencies";
import { useUsers } from "../../hooks/useUsers";
import { useTourOperators } from "../../hooks/useTourOperators";
import { BlobProvider } from "@react-pdf/renderer";
import InvoicesPDFile from "./InvoicesPDFile";
import download from "../../iconos/download.png";
const getWeekDates = (date) => {
  const currentDate = new Date(date);
  const currentDay = currentDate.getDay();
  const diff = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);

  const startDate = new Date(currentDate.setDate(diff));
  const endDate = new Date(currentDate.setDate(diff + 6));

  return [startDate, endDate];
};

const groupByWeek = (array) => {
  const grouped = {};

  array.forEach((item) => {
    const date = new Date(item.creation_date);
    const weekDates = getWeekDates(date);
    const weekKey = `${weekDates[0].toISOString().split("T")[0]}_${
      weekDates[1].toISOString().split("T")[0]
    }`;

    if (!grouped[weekKey]) {
      grouped[weekKey] = [];
    }

    grouped[weekKey].push(item);
  });

  return grouped;
};

const formatArray = (arr) => {
  return arr?.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: false,
    }),
    {}
  );
};

const filterBookings = (
  { operators, agencies, users, currencies },
  bookings,
  allCurrencies,
  allUsers,
  allAgencies
) => {
  const checkedCurrencies =
    allCurrencies && Object.keys(currencies).filter((el) => currencies[el]);
  const checkedAgencies =
    allAgencies && Object.keys(agencies).filter((el) => agencies[el]);
  const checkedUsers = allUsers && Object.keys(users).filter((el) => users[el]);

  const selectedCurrencies = allCurrencies?.filter((item) =>
    checkedCurrencies.includes(item.id.toString())
  );

  const selectedUsers = allUsers?.filter((item) =>
    checkedUsers.includes(item.id.toString())
  );

  const selectedAgency = allAgencies?.filter((item) =>
    checkedAgencies.includes(item.id.toString())
  );

  return bookings.filter(({ currency_type_id, user_id, agency_id }) => {
    const isCurrency = !allCurrencies
      ? true
      : selectedCurrencies?.length === 0 ||
        selectedCurrencies.some(({ id }) => currency_type_id === id);
    const isUser = !allUsers
      ? true
      : selectedUsers?.length === 0 ||
        selectedUsers.some(({ id }) => user_id === id);
    const isAgency = !allAgencies
      ? true
      : selectedAgency?.length === 0 ||
        selectedAgency.some(({ id }) => agency_id === id);

    return isCurrency && isUser && isAgency;
  });
};

export default function Invoices() {
  const { tour_op_id } = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tourOperators, getTourOperators } = useTourOperators();
  const {
    loading,
    bookings,
    getFlightBookings,
    getFlightBookingsByTourOperator,
  } = useBookings();
  const { currencies } = useGetCurrencies();
  const { agencies, getAgenciesByTourOperator } = useAgencies();
  const { users, getUsersByTourOperator } = useUsers();

  const [localBookings, setLocalBookings] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(1);
  const [filters, setFilters] = useState({
    operators: false,
    agencies: false,
    users: false,
    currencies: false,
  });
  
  useEffect(() => {
    tourOP.id !== 1 && getFlightBookings();
    getAgenciesByTourOperator();
    getUsersByTourOperator();
    getTourOperators();
  }, [tourOP]);

  useEffect(() => {
    tourOP.id === 1 && getFlightBookingsByTourOperator(selectedOperator);
  }, [selectedOperator]);

  useEffect(() => {
    if (bookings) setLocalBookings(bookings);
    // if (reservas) setLocalBookings(reservas.filter((x) => x.iata && x.estado === "Confirmado"));
  }, [bookings]);

  const user = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );

  const filteredUsers =
    user.agency_id === 1
      ? users
      : users.filter(({ agency_id }) => agency_id === user.agency_id);
  
  useEffect(() => {
    if (!agencies) return;

    setFilters((curr) => ({
      ...curr,
      agencies: formatArray(agencies),
    }));
  }, [agencies]);

  useEffect(() => {
    if (!users) return;

    setFilters((curr) => ({
      ...curr,
      users: formatArray(filteredUsers),
    }));
  }, [users]);

  useEffect(() => {
    if (!currencies) return;

    setFilters((curr) => ({
      ...curr,
      currencies: formatArray(currencies),
    }));
  }, [currencies]);

  const filteredBookings = filterBookings(
    filters,
    localBookings,
    currencies,
    filteredUsers,
    agencies
  );
  const groupedByWeek = localBookings && groupByWeek(filteredBookings);
  
  return (
    <main className="flex flex-col mt-8 font-body text-darkBlue mb:-ml-20 md:ml-0">
      <SEO title={"Facturación"} />
      <header className="flex justify-between items-center mb:flex-col md:flex-row">
        <div>
          <h1 className="font-[700] text-3xl">Facturación</h1>
          <h2 className="text-darkBlue/70 text-lg">
            Controlá y Gestioná las liquidaciones.
          </h2>
        </div>
        <div className="flex items-center gap-8 mb:flex-col">
          <InvoicesFilters
            dataToUse={[currencies, agencies, filteredUsers, tourOperators]}
            user={user}
            filtersState={[filters, setFilters]}
            tourOperatorState={[selectedOperator, setSelectedOperator]}
            tourOP={tourOP}
          />
          <Link
            to={`/${tour_op_id}/panel/invoicing/add-payment`}
            className="text-center button bg-darkBlue text-white w-44 hover:shadow-md transition-all"
          >
            Agregar Pago
          </Link>
        </div>
      </header>

      {loading && (
        <div className="w-full flex mt-32 justify-center">
          <Anicarga />
        </div>
      )}

      {!loading && localBookings && (
        <section className="mt-8 mb-4">
          <header className="w-full flex justify-between items-center bg-white py-4 px-8 rounded-md">
            <h2 className="text-xl capitalize font-semibold">{tour_op_id}</h2>
            {/* <a
              href="https://api.whatsapp.com/send/?phone=543512051784&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noreferrer"
              className="py-1 px-8 border border-darkBlue rounded transition-shadow hover:shadow-md"
            >
              Exportar detalle
            </a> */}
            <BlobProvider document={<InvoicesPDFile pdfData={groupedByWeek} />} >
                        {({ url, loading }) =>
                            loading ? (
                                'Loading document...'
                            ) : (
                                <a href={url} download="detalle_de_facturacion.pdf" className=" border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors">
                                    Exportar PDF
                                    <img src={download} alt="download" className="h-8 py-2.5" />
                                </a>
                            )
                        }
                    </BlobProvider>
          </header>

          <section>
            {Object.keys(groupedByWeek).map((week, index) => (
              <InvoicesByWeek
                key={groupedByWeek[week][0].id}
                week={groupedByWeek[week]}
                weekDays={week}
                isClosed={index > 2}
              />
            ))}
          </section>
        </section>
      )}
    </main>
  );
}
