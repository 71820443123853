import React, { memo, useEffect, useState } from "react";
import eye from "../../../iconos/eye.png";
import edit_icon from "../../../iconos/edit_icon.png";
import _ from "lodash";
import { Link } from "react-router-dom";
import { TableFeaturesVotes } from "../features/TableFeaturesVotes";
import { ControlVersions } from "../versions/ControlVersions";
import { ModalGeneric } from "../../../utils/ModalGeneric";
import FormAddTourOperator from "../../panelConfig/FormAddTourOperator";
import FormEditTourOperator from "../../panelConfig/FormEditTourOperator";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import SEO from "../../../utils/SEO";
import { useTourOperators } from "../../../hooks/useTourOperators";
import FormEditModules from "../../panelConfig/editModules";
import PanelSelector from "../../panelConfig/PanelSelector";

export const ClientsWrapper = () => {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { tourOperators, getTourOperators } = useTourOperators();
  const [modalType, setModalType] = useState();
  const [selectedID, setSelectedID] = useState();

  useEffect(() => {
    !isVisible && getTourOperators();
  }, [isVisible]);

  const openModal = (modalType) => {
    toggleVisibility();
    setModalType(modalType);
  };

  const closeModal = () => {
    toggleVisibility();
    getTourOperators();
  };

  return (
    <>
      <SEO title={"Administrador de Operadoras"} />
      <div className="mt-4">
          <PanelSelector type={"operators"} />
        </div>
      <div className="flex flex-col gap-4 my-2 w-full max-w-[1200px] mx-auto">
        <div className="flex justify-between">
          <h2 className="font-[500] text-[28px]">Clientes</h2>
          <button
            onClick={() => openModal("addTourOperator")}
            className="button bg-darkBlue text-white whitespace-nowrap px-2 hover:shadow-md transition-all"
          >
            + Agregar Operadora
          </button>
        </div>
        <ClientsTable
          tourOperators={tourOperators}
          setSelectedID={setSelectedID}
          openModal={openModal}
        />
      </div>
      <div className="bg-white border rounded-[20px] border-[#D9D9D9] py-4 px-8 w-full max-w-[1300px] mx-auto">
        <div className="w-full mx-auto max-w-[1200px]">
          <TableFeaturesVotes />
        </div>
        <div className="w-[70%] my-6 mx-auto h-[1px] bg-[#D3D3D3]"></div>
        <ControlVersions />
      </div>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        {modalType === "addTourOperator" && (
          <FormAddTourOperator closeModal={closeModal} />
        )}
        {modalType === "editTourOperator" && (
          <FormEditTourOperator
            selectedID={selectedID}
            closeModal={closeModal}
          />
        )}
        {modalType === "editModules" && (
          <FormEditModules selectedID={selectedID} closeModal={closeModal} />
        )}
      </ModalGeneric>
    </>
  );
};

const ClientsTable = ({ tourOperators, setSelectedID, openModal }) => {
  const headers = ["Nombre", "Logo empresa", "Dominio", "Editar"];

  return (
    <table className="w-full my-8 bg-white">
      <thead>
        <tr className="border border-[#D9D9D9]">
          {(headers || []).map((h, index) => (
            <th className="py-1 font-[500] text-[18px]" key={index}>
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tourOperators?.slice(1).map(({ id, name, logo }) => {
          const currentHREF = window.location.href
            .split("/")
            .slice(0, -4)
            .join("/");
          const domain = `${currentHREF}/${name
            .split(" ")
            .join("")
            .toLowerCase()}/flights`;

          return (
            <tr key={id}>
              <td className="text-center text-[16px]">{name}</td>
              <td className="py-4 text-center">
                {logo ? (
                  <img
                    className="w-20 mx-auto"
                    alt={"logo de: " + name}
                    src={logo}
                  />
                ) : (
                  "+Cargar"
                )}
              </td>
              {/* <td className="my-2">
              <div
                style={{
                  backgroundColor: op.mainColor ? op.mainColor : "inherit",
                }}
                className="w-8 h-8 mx-auto text-center rounded-full"
              ></div>
            </td>
            <td className="my-2">
              <div
                style={{
                  backgroundColor: op.secondaryColor
                    ? op.secondaryColor
                    : "inherit",
                }}
                className="w-8 h-8 mx-auto text-center rounded-full"
              ></div>
            </td> */}
              <td className="text-center text-[16px] underline text-[#335EF8]">
                <a href={domain} target="_blank" rel="noreferrer">
                  {domain}
                </a>
              </td>
              <td>
                <button
                  className="border px-2 py-1 mr-4"
                  onClick={() => {
                    openModal("editModules");
                    setSelectedID(id);
                  }}
                >
                  Editar Modulos
                </button>
                <button
                  onClick={() => {
                    openModal("editTourOperator");
                    setSelectedID(id);
                  }}
                  className="relative top-2.5"
                >
                  <img alt="editar fila" src={edit_icon} className="w-8 h-8" />
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
