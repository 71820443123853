import React from "react";
import { format } from "date-fns";

export default function ViewTravelerModal({ travelerData }) {
  const {
    first_name,
    last_name,
    date_of_birth,
    gender,
    email_address,
    traveler_document,
    category,
  } = travelerData;

  // Format the date of birth to dd/mm/yyyy
  const formattedDateOfBirth = date_of_birth
    ? format(new Date(date_of_birth), "dd/MM/yyyy")
    : "";

  return (
    <section className="min-w-[420px] p-4">
      <h2 className="text-xl font-semibold mb-4">Información de Pasajero</h2>
      <div className="grid grid-cols-2 items-center gap-2">
        {first_name?.length > 1 && (
          <div className="w-full relative flex flex-col">
            <label>Nombre</label>
            <input
              readOnly
              className="addPackInput capitalize"
              name="name"
              value={first_name.toLowerCase()}
            />
          </div>
        )}
        {last_name?.length > 1 && (
          <div className="w-full relative flex flex-col col-span-1">
            <label>Apellido</label>
            <input
              readOnly
              className="addPackInput capitalize"
              name="address"
              value={last_name.toLowerCase()}
            />
          </div>
        )}
        {category?.length > 1 && (
          <div className="w-full relative flex flex-col col-span-1">
            <label>Categoria</label>
            <input
              readOnly
              className="addPackInput capitalize"
              name="address"
              value={category.toLowerCase()}
            />
          </div>
        )}
        {email_address?.length > 1 && (
          <div className="w-full relative flex flex-col">
            <label>Email</label>
            <input
              readOnly
              className="addPackInput"
              name="email"
              value={email_address}
            />
          </div>
        )}
        {gender?.length >= 1 && (
          <div className="w-full relative flex flex-col">
            <label>Género</label>
            <input
              readOnly
              className="addPackInput"
              name="gender"
              value={gender}
            />
          </div>
        )}
        {date_of_birth && (
          <div className="w-full relative flex flex-col">
            <label>Fecha de Nacimiento</label>
            <input
              readOnly
              className="addPackInput"
              name="date_of_birth"
              value={formattedDateOfBirth}
            />
          </div>
        )}
        {traveler_document ? (
          <div className="w-full relative flex flex-col">
            <label>Documento de Viajero</label>
            <div className="flex flex-row items-center">
              <div className="border-2 border-lightGray rounded-lg p-1">
                {" "}
                {traveler_document?.doc_type === "IDENTITY_CARD"
                  ? "DNI"
                  : traveler_document?.doc_type === "PASSPORT"
                  ? "Pasaporte "
                  : traveler_document?.doc_type ?? "-"}
              </div>

              <input
                readOnly
                className="addPackInput"
                name="traveler_document"
                value={traveler_document.number}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}
