import React from "react";
import logo_walltrip from "../../../iconos/logos/walltrip.png";
import { Link } from "react-router-dom";


export default function FooterLogo({params,logo}) {
  return (
    <Link className="h-fit" to={`/${params.tour_op_id}/Flights`}>
      <img
        className="text-black max-w-[208px] max-h-14"
        src={logo || logo_walltrip}
        alt=""
      />
    </Link>
  );
}
