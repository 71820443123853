import ico_paquetes from "../iconos/icono-paquetes.png";
import ico_vuelos from "../iconos/icono-vuelos.png";
import ico_hoteles from "../iconos/hotel.png";
import ico_actividades from "../iconos/actividades.png";

const ALL_HEADER_OPTIONS = [
    {
      label: "Vuelos",
      path: "flights",
      image: ico_vuelos,
      alt: "vuelos-icono",
    },
    {
      label: "Paquetes",
      path: "packages",
      image: ico_paquetes,
      alt: "paquetes-icono",
    },
    {
      label: "Hoteles",
      path: "hotels",
      image: ico_hoteles,
      alt: "hoteles-icono",
    },
    {
      label: "Actividades",
      path: "search-activities",
      image: ico_actividades,
      alt: "actividades-icono",
    },
  ];
 export {ALL_HEADER_OPTIONS} 