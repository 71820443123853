/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef, useState } from "react";
import icono_flecha from "../iconos/icono-flecha.png";
import useGetAirports from "../hooks/useGetAirports";
import { useEffect } from "react";
import clsx from "clsx";
import useOnClickOutside from "../hooks/useClickOutside";

const PRINCIPAL_AIRPORTS = [
  1110, 83, 1644, 3365, 4686, 706, 5667, 1900, 2002, 4799, 3287, 3444, 2523,
  4454, 3074, 3063, 914, 1762, 1674,
];

function replaceAccents(str) {
  const accentMap = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
  };

  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\s]/g, "")
    .replace(/[áéíóúÁÉÍÓÚ]/g, (match) => accentMap[match] || match);
}

export const AutoComplete = ({
  classname,
  defaultValue,
  name,
  placeholder,
  handleAction,
  register,
  notIcon,
  notPlaceholder,
  justCities,
  inputState,
}) => {
  const { getAirports, airports } = useGetAirports();
  const [selected, setSelected] = useState(defaultValue ?? "s");
  const [inputValue, setInputValue] = inputState;
  const ref = useRef(null);

  useOnClickOutside(ref, () => setSelected("s"));

  useEffect(() => {
    getAirports();
  }, []);

  const inputValueWithoutAccents = replaceAccents(inputValue.toLowerCase());

  const listOfOptions = useMemo(
    () =>
      (!justCities
        ? airports.map((x) => ({
            id: x.id,
            airport: x.name,
            iata: x.iata,
            city: x.City.name,
            country: x.City.Country.name,
          }))
        : airports.map((x) => ({
            city: x.City.name,
            country: x.City.Country.name,
          }))
      )
        .sort((a, b) => {
          if (PRINCIPAL_AIRPORTS.includes(a.id)) return -1;
          if (PRINCIPAL_AIRPORTS.includes(b.id)) return +1;
          return 0;
        })
        .sort((a, b) => {
          if (a.city === "Ciudad de Córdoba" || a.city === "Buenos Aires")
            return -1;
          if (b.city === "Ciudad de Córdoba" || b.city === "Buenos Aires")
            return 1;
          return 0;
        })
        .map((opt) => {
          if (opt.country === "United States")
            return {
              ...opt,
              country: "USA",
            };
          return opt;
        }),
    [airports]
  );

  const filteredOptions =
    listOfOptions &&
    listOfOptions.filter((item) => {
      const lowerInputValue = inputValueWithoutAccents.toLowerCase();
      const lowerCity = replaceAccents(item?.city)?.toLowerCase();
      const lowerAirport = justCities
        ? replaceAccents(item?.country)?.toLowerCase()
        : replaceAccents(item?.airport)?.toLowerCase();

      return (
        lowerAirport?.includes(lowerInputValue) ||
        lowerCity?.includes(lowerInputValue)
      );
    });

  return (
    <div className="relative w-full">
      <input
        className={clsx(
          { [classname]: classname?.length },
          {
            "py-4 pl-4 text-sm buscador-centro-ubicacion-input focus:outline-none":
              !classname?.length,
          }
        )}
        type="text"
        {...register(name)}
        value={inputValue}
        name={name}
        defaultValue={defaultValue}
        autoComplete="off"
        placeholder={notPlaceholder ? "" : placeholder}
        onChange={(e) => {
          setInputValue(e.target.value);
          setSelected("");
        }}
        onBlur={(e) => handleAction(e.target.value)}
      />
      {notIcon ? null : (
        <img
          className="buscador-centro-ubicacion-icono"
          src={icono_flecha}
          alt=""
        />
      )}

      <div
        className={`absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl ${
          justCities ? "w-[250px]" : "w-[450px]"
        }`}
      >
        <div ref={ref} className="flex flex-col gap-1">
          {!selected.length ? (
            <>
              {!justCities &&
                filteredOptions.slice(0, 3).map((v, index) => {
                  return (
                    <div key={`${v?.city}-${index}`}>
                      {index === 0 && (
                        <p className="font-semibold text-brightBlue pl-3">
                          Aeropuerto
                        </p>
                      )}
                      <p
                        key={index}
                        onClick={(e) => {
                          !justCities
                            ? setSelected(v?.iata)
                            : setSelected(v?.city);
                          !justCities
                            ? setInputValue(
                                `[${v?.iata}] ${v?.city}, ${v?.country}`
                              )
                            : setInputValue(v?.city);
                          !justCities
                            ? handleAction(v?.iata)
                            : handleAction(v?.city);
                        }}
                        className="px-3 pt-1 m-0 w-full rounded cursor-pointer whitespace-pre-wrap transition-colors hover:bg-slate-100"
                      >
                        {!justCities ? `[${v?.iata}] ${v?.airport}` : ""}
                        {" - "}
                        {v?.city}, {v?.country?.toUpperCase().substr(0, 3)}
                      </p>
                    </div>
                  );
                })}
              {filteredOptions.slice(0, 3).map((v, index) => {
                return (
                  <div key={`${v?.city}-${index}`}>
                    {index === 0 && (
                      <p className="font-semibold text-brightBlue pl-3">
                        Ciudad
                      </p>
                    )}
                    <p
                      key={index}
                      onClick={(e) => {
                        !justCities
                          ? setSelected(v?.iata)
                          : setSelected(v?.city);
                        !justCities
                          ? setInputValue(v?.city + " - " + v?.iata)
                          : setInputValue(v?.city);
                        !justCities
                          ? handleAction(v?.iata)
                          : handleAction(v?.city);
                      }}
                      className="px-3 pt-1 m-0 w-full rounded cursor-pointer whitespace-pre-wrap transition-colors hover:bg-slate-100"
                    >
                      {v?.city}, {v?.country?.toUpperCase().substr(0, 3)}
                    </p>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
