import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import download from "../../../../../iconos/download.png";
import QuotePDFile from "./QuotePDFile";
import TableDateFiltering from "./TableDateFiltering";
import QuoteSorting from "./QuoteSorting";
export default function TableQuoteFiltering({
  searcher,
  sortCriteria,
  setSortCriteria,
  pdfData,
  setFilters,
  filters,
}) {
  const [searcherValue, setSearcherValue] = searcher;

  //   const handleExport = (dataExcel) => {
  //     console.log(dataExcel);

  //     // Sample data to be exported

  //     // Map dataExcel to new keys corresponding to the custom headers
  //     const mappedData = dataExcel.map((item) => ({
  //       Nombre: item.first_name,
  //       Apellido: item.last_name,

  //       Email: item.email_address,
  //       Genero: item.gender === "M" ? "Masculino" : "Femenino",
  //     }));
  //     // Convert the data to a worksheet
  //     const worksheet = XLSX.utils.json_to_sheet(mappedData);

  //     // Create a new workbook and append the worksheet
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //     // Generate the .xlsx file and trigger the download
  //     XLSX.writeFile(workbook, "pasajeros.xlsx");
  //   };

  return (
    <section className="flex gap-4 pb-4 mb-4 items-center border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-96 rounded"
        placeholder="Buscar por Destino, Nombre..."
        value={searcherValue.textValue}
        onChange={(e) =>
          setSearcherValue({
            ...searcherValue,
            textValue: e.target.value,
          })
        }
      />
      <QuoteSorting
        setSortCriteria={setSortCriteria}
        sortCriteria={sortCriteria}
      />
       <TableDateFiltering setFilters={setFilters} filters={filters} />

      {pdfData && pdfData.length > 0 && (
        <>
          <BlobProvider document={<QuotePDFile pdfData={pdfData} />}>
            {({ url, loading }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={url}
                  download="Cotizaciones.pdf"
                  className=" border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
                >
                  Exportar PDF
                  <img src={download} alt="download" className="h-8 py-2.5" />
                </a>
              )
            }
          </BlobProvider>

          {/* <button
            onClick={(e) => handleExport(pdfData)}
            className="border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
          >
            Exportar Excel
            <img src={download} alt="download" className="h-8 py-2.5" />
          </button> */}
        </>
      )}
    </section>
  );
}
