import React from "react";
import { BuscadorPaquetes } from "../../BuscadorPaquetes";
import PackageFilterMobileMenu from "../../PackageFilterMobileMenu";
import PackageFilters from "../../PackageFilters";
import PackageSorting from "../../packageSorting/PackageSorting";

export default function PackageListFilters({
  packageData,
  reducedPackages,
  searchTerm,
  setSearchTerm,
  setFilters,
  setPassengers,
  passengers,
  filters,
  sortCriteria,
  setSortCriteria,
}) {
  return (
    <div className="lg:grid mb:flex-col flex">
      <div className="mb:hidden lg:block">
        <div className="ml-2">
        <PackageSorting
          filteredPackages={packageData}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
        />
        </div>
        
        <BuscadorPaquetes
          filteredPackages={packageData}
          possibleCitiesAndMonth={reducedPackages}
          searcher={[searchTerm, setSearchTerm]}
          setFilters={setFilters}
          setPassengers={setPassengers}
          passengers={passengers}
        />

        <PackageFilters
          possibleCitiesAndMonth={reducedPackages}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <div className="lg:hidden mb:block overflow-scroll">
        <PackageFilterMobileMenu
          filteredPackages={packageData}
          possibleCitiesAndMonth={reducedPackages}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setFilters={setFilters}
          setPassengers={setPassengers}
          passengers={passengers}
          filters={filters}
        />
      </div>
    </div>
  );
}
