import React, { useState } from "react";
import { usePackages } from "../../../hooks/usePackages";
import { useUserStore } from "../../../hooks/useUserStore";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { completePackage } from "../../../schemas/completePackage";
import PackageSubForm from "./subForms/PackageSubForm";
import TourSubForm from "./subForms/TourSubForm";
import FeeSubForm from "./subForms/FeeSubForm";
import { WarningOutlined } from "@mui/icons-material";
import { Toaster, toast } from "react-hot-toast";
import SEO from "../../../utils/SEO";

export default function FormAddPackage() {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id } = useParams();
  const { createPackage } = usePackages();
  const [price, setPrice] = useState(0);
  const { user } = useUserStore();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: zodResolver(completePackage) });

  //  removes the values you dont load on the forms
  const removeEmptyValues = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
    );
  };

  const onSubmit = async (data) => {
    const packageData = data.packageSchema;
    const tourData = data.tourSchema;
    const feeData = data.feeSchema;

    try {
      await toast.promise(
        createPackage({
          package: removeEmptyValues({
            title: packageData?.title,
            origin_code: packageData?.origin_code,
            destination_code: packageData?.destination_code,
            description_general: packageData?.description_general,
            include: packageData?.include,
            not_included: packageData?.not_included,
            itinerary: packageData?.itinerary,
            terms: packageData?.terms,
            flyer: packageData?.flyer,
            portrait: packageData?.portrait,
            pictures: packageData?.pictures,
            country_id: tourOP.country_id,
            tour_op_id: tourOP.id,
            user_id: user.id,
            supplier_id: packageData?.supplier_id,
            package_category_id: packageData?.package_category_id,
            show: packageData?.show,
            tour: removeEmptyValues({
              dateIn: tourData?.dateIn,
              dateOut: tourData?.dateOut,
              hotels: tourData?.hotels,
              category: tourData?.category,
              meal_plan: tourData?.meal_plan,
              days: tourData?.days,
              nights: tourData?.nights,
              stock: tourData?.stock,
              original_stock: tourData?.stock,
              comments: tourData?.comments,
              availability: "Y",
            }),
            fee: removeEmptyValues({
              base: feeData?.base,
              fare: feeData?.fare,
              tax: feeData?.tax,
              price: String(price),
              currency_type_id: feeData?.currency_type_id,
              comments: feeData?.comments,
            }),
          }),
        }),
        {
          loading: "Procesando...",
          success: "Paquete Guardado",
          error: "Hubo un error, intentalo de nuevo!",
        }
      );
      navigate(`/${tour_op_id}/panel/packages/my-packages`, {
        replace: true,
      });
    } catch (e) {
      console.error("Error in submission:", e);
      throw new Error(e);
    }
  };

  return (
    <>
      <SEO title={"formulario paquete"} />
      <div>
        <Toaster />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <PackageSubForm
          register={register}
          errors={errors.packageSchema}
          setValue={setValue}
          watch={watch}
          clearErrors={clearErrors}
        />
        <TourSubForm
          register={register}
          errors={errors.tourSchema}
          setValue={setValue}
          watch={watch}
          clearErrors={clearErrors}
        />
        <FeeSubForm
          register={register}
          errors={errors.feeSchema}
          setValue={setValue}
          watch={watch}
          price={price}
          setPrice={setPrice}
        />

        <h1 className="">
          <WarningOutlined /> Podrás cargar más luego desde el panel{" "}
        </h1>
        <button
          type="submit"
          className="button ml-auto  bottom-0 right-0 text-lg w-1/3 bg-darkBlue text-white hover:shadow-lg transition-all mb-8"
        >
          Guardar Paquete
        </button>
      </form>
    </>
  );
}
