import SEO from "../../utils/SEO";
import UsersTable from "./UsersTable";
import RolesTable from "./RolesTable";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { Login } from "../auth/Login";
import { Link, useParams } from "react-router-dom";
import { useUsers } from "../../hooks/useUsers";
import { useEffect, useState } from "react";
import FormAddAgency from "./FormAddAgency";
import useAgencies from "../../hooks/useAgencies";
import PanelSelector from "./PanelSelector";

export const PanelUser = () => {
  const { tour_op_id } = useParams();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { users, getUsersByTourOperator, deleteUser } = useUsers();
  const { agencies, getAgenciesByTourOperator } = useAgencies();
  const [modalType, setModalType] = useState();
  const { agency_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );

  useEffect(() => {
    getAgenciesByTourOperator();
  }, [tour_op_id]);

  const openModal = (type) => {
    toggleVisibility();
    setModalType(type);
  };

  const closeModalAgency = () => {
    toggleVisibility();
    getAgenciesByTourOperator();
  };

  const closeModalUsers = () => {
    toggleVisibility();
    getUsersByTourOperator();
  };

  return (
    <>
      <SEO title={"Administrador de Usuarios"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <div className="mt-4">
        <PanelSelector type={"users"}/>
        </div>
        <section className="my-8">
          <header className="flex justify-between items-center">
            <div>
              <h2 className="text-darkBlue font-[700] text-3xl">Usuarios</h2>
              {/* <p className="text-darkBlue/70 text-lg bg-red-200">
                Texto genérico
              </p> */}
            </div>
            <div className="flex gap-4">
              {agency_id === 1 && (
                <button
                  className="border border-darkBlue text-darkBlue bg-white font-semibold py-2 px-4 rounded"
                  onClick={() => openModal("agency")}
                >
                  Agregar Agencia
                </button>
              )}
              <button
                className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
                onClick={() => openModal("user")}
              >
                Agregar Usuario
              </button>
            </div>
          </header>

          <UsersTable
            useUsers={{ users, getUsersByTourOperator, deleteUser }}
            agencies={agencies}
          />
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {modalType === "agency" && (
            <FormAddAgency closeModal={closeModalAgency} />
          )}
          {modalType === "user" && (
            <Login justCreateAccount closeModal={closeModalUsers} />
          )}
        </ModalGeneric>

        <section>
          <header className="flex justify-between items-center">
            <div>
              <h2 className="text-darkBlue font-[700] text-3xl">Permisos</h2>
              {/* <p className="text-darkBlue/70 text-lg bg-red-200">
                Texto genérico
              </p> */}
            </div>
          </header>

          <RolesTable />
        </section>

        <section className="mt-8">
          <h2 className="text-darkBlue font-[700] text-3xl">
            Notificaciones automáticas por correo:
          </h2>
          <p> Ante un cambio de estado de reserva</p>
          <label className="block">
            Confirmado
            <input type="checkbox" />
          </label>
          <label className="block">
            Enviar a mi email y al del cliente
            <input type="checkbox" />
          </label>
        </section>
        {/* <Link
          to={`/${tour_op_id}/panel/profile/${
            agency_id === 1 ? "agencies" : "fees"
          }`}
          className="absolute left-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Anterior
        </Link> */}
        {/* <Link
          to={`/${tour_op_id}/panel/profile/design`}
          className="absolute right-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Siguiente
        </Link> */}
      </main>
    </>
  );
};
