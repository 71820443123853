import React, { useState } from "react";

export default function PackageBasicDetail({ packageData }) {
  const {
    title,
    destination_code,
    origin_code,
    description_general,
    include,
    not_included,
    itinerary,
    terms,
  } = packageData;

  // State variables to control visibility
  const [showDescription, setShowDescription] = useState(false);
  const [showInclude, setShowInclude] = useState(false);
  const [showNotIncluded, setShowNotIncluded] = useState(false);
  const [showItinerary, setShowItinerary] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const toggleVisibility = (setter) => () => {
    setter((prev) => !prev);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 shadow-lg rounded-lg bg-white">
      <h1 className="text-4xl font-semibold text-center mb-4">{title}</h1>

      <div className="border-b mb-4">
        <div className="grid grid-cols-2">
          <p className="border-r text-lg p-2 font-semibold">Origen</p>
          <p className="text-lg p-2">{origin_code}</p>
        </div>

        <div className="grid grid-cols-2">
          <p className="border-r text-lg p-2 font-semibold">Destino</p>
          <p className="text-lg p-2">{destination_code}</p>
        </div>
      </div>

      {/* Toggleable sections */}
      <div className="flex flex-col gap-4">
        {[
          { title: "Descripción General", content: description_general, isVisible: showDescription, toggle: toggleVisibility(setShowDescription) },
          { title: "Incluye", content: include, isVisible: showInclude, toggle: toggleVisibility(setShowInclude) },
          { title: "No Incluye", content: not_included, isVisible: showNotIncluded, toggle: toggleVisibility(setShowNotIncluded) },
          { title: "Itinerario", content: itinerary, isVisible: showItinerary, toggle: toggleVisibility(setShowItinerary) },
          { title: "Términos", content: terms, isVisible: showTerms, toggle: toggleVisibility(setShowTerms) },
        ].map(({ title, content, isVisible, toggle }, index) => (
          <div key={index} className="border rounded-lg p-4">
            <button 
              onClick={toggle} 
              className="text-blue-600 font-semibold mb-2 hover:text-blue-800 focus:outline-none"
            >
              {isVisible ? `Ocultar ${title}` : `Mostrar ${title}`}
            </button>
            {isVisible && (
              <div className="mt-2">
                <h2 className="text-lg font-semibold">{title}</h2>
                <p className="text-gray-700">{content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
