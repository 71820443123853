import React from 'react'
import {Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { format } from "date-fns";
import { upperCase } from 'lodash';


export default function TravelersPDFile({ pdfData }) {
  // Global styles
  const globalStyles = StyleSheet.create({
    body: {
      fontSize: 10,
    },
  });

  // PDF-specific styles
  const styles = StyleSheet.create({
    divider: {
      width: "100%",
      borderBottomWidth: 2,
      borderBottomColor: "black",
      marginBottom: 10,
      marginTop: 10,
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      paddingBottom: 60,
      paddingTop: 30,
      alignItems: "center",
    },
    fcType: {
      width: 40,
      height: 40,
      borderWidth: 2,
      borderColor: "black",
      padding: 3,
      textAlign: "center",
      fontSize: 30,
    },
    section1: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 2,
    },
    seller: {
      flexDirection: "column",
    },
    fcInfo: {
      flexDirection: "row",
    },
    fcInfoCol: {
      flexDirection: "column",
      marginHorizontal: 5,
    },
    client: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 2,
    },
    clientColumns: {
      flexDirection: "column",
    },
    table: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 2,
    },
  });

  return (
    <Document>
        <Page size="A4" style={globalStyles.body}>

            <View style={styles.table}>
                <View>
                    <Text>ID</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {item.id}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Nombre</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {item.last_name}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Apellido</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {item.first_name}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Fecha de Nacimiento</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {format(new Date(item.date_of_birth), "dd/MM/yyyy")}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Email.</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {item.email_address}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Genero</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {item.gender === "M" ? "Masculino" : "Femenino"}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Amadeus</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {`${upperCase(item.last_name)}/${upperCase(item.first_name)} ${format(new Date(item.date_of_birth), "ddMMMyy")} P/`}
                        </Text>
                    ))}
                </View>
                <View>
                    <Text>Sabre</Text>
                    {pdfData?.map((item, index) => (
                        <Text key={index} style={styles.nameText}>
                            {`${upperCase(item.last_name)}/${upperCase(item.first_name)}*${format(new Date(item.date_of_birth), "ddMMMyy")}*P/`}
                        </Text>
                    ))}
                </View>
            </View>
        </Page>
    </Document>
);
}
