import { useEffect, useState } from "react";
import { useTravelers } from "../../hooks/useTravelers";
import SEO from "../../utils/SEO";
import TravelersTable from "./TravelersTable";
import { Link, useParams } from "react-router-dom";
import TravelersFilters from "./TravelersFilters";
import useAgencies from "../../hooks/useAgencies";
import { useUsers } from "../../hooks/useUsers";
import { saveTourOperator } from "../../utils/saveTourOperator";

export default function Travelers() {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { travelers, getTravelers } = useTravelers();
  const { agencies, getAgenciesByTourOperator } = useAgencies();
  const { users, getUsersByTourOperator } = useUsers();
  const { tour_op_id } = useParams();
  const [page, setPage] = useState(0);
  const [searcherValue, setSearcherValue] = useState({
    textValue: "",
    monthValue: "",
  });
  const [selectedOperator, setSelectedOperator] = useState(1);
  const [filters, setFilters] = useState({
    operators: false,
    agencies: false,
    users: false,
    currencies: false,
  });
  const lowerSearch = searcherValue.textValue.toLowerCase();
  const normalSearch = searcherValue.textValue;
  const user = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  // const isOperatorUser = user.agency_id === 1;

  const formatArray = (arr) => {
    return arr?.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: false,
      }),
      {}
    );
  };

  useEffect(() => {
    getTravelers();
    getAgenciesByTourOperator();
    getUsersByTourOperator();
  }, [tourOP]);

  const refreshValues = () => {
    getTravelers(); // Refresh travelers
    getAgenciesByTourOperator(); // Refresh agencies if needed
    getUsersByTourOperator(); // Refresh users if needed
  };

  const filteredPaquetes = travelers
    .filter((traveler) => {
      const { first_name, last_name, id, gender, email_address } = traveler;

      return (
        first_name.toLowerCase().includes(lowerSearch) ||
        (first_name.toLowerCase().includes(lowerSearch) &&
          last_name.toLowerCase().includes(lowerSearch)) ||
        last_name.toLowerCase().includes(lowerSearch) ||
        email_address.toLowerCase().includes(lowerSearch) ||
        gender.toLowerCase().includes(normalSearch) ||
        id === normalSearch
        //date_of_birth.includes(normalSearch)||
      );
    })

    .filter((traveler) => {
      if (!searcherValue.monthValue) return true;

      const [packYear, packMonth] = traveler.date_of_birth.split("-");
      const [searchYear, searchMonth] = searcherValue.monthValue.split("-");

      return packYear === searchYear && packMonth === searchMonth;
    });
  //.slice(page * 10, page * 10 + 10);
  const filteredUsers =
    user.agency_id === 1
      ? users
      : users.filter(({ agency_id }) => agency_id === user.agency_id);
  useEffect(() => {
    if (!agencies) return;

    setFilters((curr) => ({
      ...curr,
      agencies: formatArray(agencies),
    }));
  }, [agencies]);

  useEffect(() => {
    if (!users) return;

    setFilters((curr) => ({
      ...curr,
      users: formatArray(filteredUsers),
    }));
  }, [users]);

  return (
    <>
      <SEO title={"Pasajeros"} />
      <main className="flex flex-col w-full mt-8 font-body ">
        {/* <header className="mb-4">
          <h1 className="font-[700] text-darkBlue text-3xl">Pasajeros</h1>
          <p>Gestión de Pasajeros</p>
          <Link
            className="text-center button bg-darkBlue text-white w-44 hover:shadow-md transition-all"
            to={`/${tour_op_id}/panel/packages/add-package`}
          >
            Agregar Paquete
          </Link>
        </header> */}

        <header className="flex justify-between items-center mb-4 mb:flex-col md:flex-row">
          <div>
            <span>
              <h1 className="font-[700] text-darkBlue text-3xl">Pasajeros</h1>
              <p className="text-darkBlue">Gestión de Pasajeros</p>
            </span>
          </div>
          <div className="flex items-center gap-8 mb:flex-col justify-center">
            {/* <TravelersFilters
              dataToUse={[agencies, filteredUsers,]}
              user={user}
              filtersState={[filters, setFilters]}
              tourOperatorState={[selectedOperator, setSelectedOperator]}
              tourOP={tourOP}
            /> */}

            <Link
              className="text-center button bg-darkBlue text-white w-44 hover:shadow-md transition-all"
              to={`/${tour_op_id}/panel/travelers/add-traveler`}
            >
              Agregar Pasajero
            </Link>
          </div>
        </header>

        <TravelersTable
          agencies={agencies}
          filteredUsers={filteredUsers}
          filters={filters}
          setFilters={setFilters}
          selectedOperator={selectedOperator}
          setSelectedOperator={setSelectedOperator}
          user={user}
          tourOP={tourOP}
          travelers={filteredPaquetes}
          searcher={[searcherValue, setSearcherValue]}
          page={page}
          setPage={setPage}
          refreshValues={refreshValues}
        />
      </main>
    </>
  );
}
