import React, { useState, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const removeDuplicates = (options) => {
    return [...new Set(options.filter(option => option != null))];
};

const capitalizeFirstLetterOfEachWord = (string) => {
    if (!string) return '';
    return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export default function AutoCompletePackages({ options, handleChange, name, placeholder, setValue, valueName }) {
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null); // Track the selected value

    // Memoize the uniqueOptions to avoid recalculating on every render
    const uniqueOptions = useMemo(() => removeDuplicates(options), [options]);

    return (
        <Autocomplete
            options={uniqueOptions}
            getOptionLabel={(option) => capitalizeFirstLetterOfEachWord(option)}
            inputValue={inputValue}
            freeSolo={false} // Prevent free text input
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue); // Allow typing to filter options
            }}
            onChange={(event, value) => {
                if (value != null) {
                    // Update selected value
                    setSelectedValue(value);
                    handleChange(value, valueName);

                    // Manually set the form value using React Hook Form's setValue
                    setValue(name, value); // Register the selected value in the form
                } else {
                    // Clear selection
                    setSelectedValue(null);
                    handleChange(null, valueName);
                    setValue(name, null); // Clear form value if no option is selected
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            padding: '4px',
                            borderRadius: '8px',
                            '& fieldset': {
                                border: 'none',
                            },
                        },
                        '& .MuiInputBase-input': {
                            padding: '4px 8px',
                            fontSize: '14px',
                            height: '24px',
                        },
                        width: '100%', // Ensure the TextField fills the space
                    }}
                />
            )}
            sx={{
                width: '100%', // Ensure the Autocomplete component itself is full width
            }}
        />
    );
}
