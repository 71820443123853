import React from 'react'
import { Link } from "react-router-dom";
import edit from "../../iconos/edit_icon.png";

const ModalViewSupplier = ({children}) => {
    const [{selectedSupplier, tour_op_id}] = children;

  return (
    <div className="flex flex-col gap-4 mt-2">
        <div className='flex flex-row gap-1'>
            <h2 className='font-medium text-darkBlue text-2xl'>{selectedSupplier.name}</h2>
            <Link 
                className="relative w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors mt-1"
                to={`/${tour_op_id}/panel/suppliers/edit-supplier/${selectedSupplier.id}`}
            >
                <img 
                    src={edit}
                    alt='edit'
                    className='w-7 h-7'
                />
            </Link>
        </div>
        <div className='grid grid-cols-2 gap-9'>
            <div className='flex flex-col gap-3'>
                <div>
                    <label className='font-medium'>Nombre Legal</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.legal_name}</p>
                </div>
                <div>
                    <label className='font-medium'>Cuenta Fiscal</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.tax_code}</p>
                </div>
                <div>
                    <label className='font-medium'>Dirección</label>
                    {
                        selectedSupplier.adress === undefined ? (
                            <p className='w-full px-4 py-2 border border-gray2 rounded'>Sin infomación</p>
                        ) 
                        :  (
                            <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.adress}</p>
                        )
                    }
                </div>
                <div>
                    <label className='font-medium'>Teléfono Fijo</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.phone_number}</p>
                </div>
            </div>
            <div className='flex flex-col gap-3'>
                <div>
                    <label className='font-medium'>Email</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.email}</p>
                </div>
                <div>
                    <label className='font-medium'>Número de cuenta</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.tax_number}</p>
                </div>
                <div>
                    <label className='font-medium'>Link de cuenta</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.account_link}</p>
                </div>
                <div>
                    <label className='font-medium'>Teléfono Celular</label>
                    <p className='w-full px-4 py-2 border border-gray2 rounded'>{selectedSupplier.cellphone_number}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ModalViewSupplier