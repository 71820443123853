import React from "react";
import { useParams } from "react-router-dom";
import { saveTourOperator } from "../../../../utils/saveTourOperator";
import FooterLogo from "../FooterLogo";
import FooterSocials from "./FooterSocials";
import FooterContact from "./FooterContact";
import FooterLinks from "./FooterLinks";
import FooterFiscal from "./FooterFiscal";

export default function HighFooter() {
  const params = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const {
    license,
    logo,
    file,
    email,
    TourOperatorPhones,
    TourOperatorSocialMedia,
    TourOperatorAddresses,
    TourOperatorContacts,
  } = tourOP;

  return (
    <div className="flex flex-row items-stretch items-center  w-full">
      <div className="flex-grow flex justify-center">
        <FooterLogo params={params} logo={logo} />
      </div>
      <div className="flex-grow flex  justify-center">
        <div>
          <FooterSocials TourOperatorSocialMedia={TourOperatorSocialMedia} />
          <FooterFiscal license={license} file={file} />
        </div>
      </div>
      <div className="flex-grow flex  justify-center">
        <FooterContact
          TourOperatorAddresses={TourOperatorAddresses}
          TourOperatorContacts={TourOperatorContacts}
          TourOperatorPhones={TourOperatorPhones}
          email={email}
        />
      </div>
      <div className="flex-grow flex  justify-center">
        <FooterLinks  TourOperatorSocialMedia={TourOperatorSocialMedia} />
      </div>
    </div>
  );
}
