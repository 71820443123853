import React from 'react'
import { Page, Document, Text, StyleSheet, View } from '@react-pdf/renderer';
import { defaultDateFormat } from '../../utils/commons';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
export default function InvoicesPDFile({ pdfData }) {
    console.log(pdfData)

    // Global styles
    const globalStyles = StyleSheet.create({
        body: {
            fontSize: 10,
        },
    });

    // PDF-specific styles
    const styles = StyleSheet.create({
        divider: {
            width: '100%',
            borderBottomWidth: 2,
            borderBottomColor: 'black',
            marginBottom: 10,
            marginTop: 10,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            paddingBottom: 60,
            paddingTop: 30,
            alignItems: 'center',
        },
        fcType: {
            width: 40,
            height: 40,
            borderWidth: 2,
            borderColor: 'black',
            padding: 3,
            textAlign: 'center',
            fontSize: 30,
        },
        section1: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
        seller: {
            flexDirection: 'column',
        },
        fcInfo: {
            flexDirection: 'row',
        },
        fcInfoCol: {
            flexDirection: 'column',
            marginHorizontal: 5,
        },
        client: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
        clientColumns: {
            flexDirection: 'column',
        },
        table: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
    });

    return (
        <>

            <Document>
                {Object.keys(pdfData)?.map((item, index) => (
                    <Page size="A4" style={globalStyles.body}>
                        <View style={styles.header}>
                            <Text>img</Text>
                            <Text style={styles.fcType}>A</Text>
                            <Text>ORIGINAL</Text>
                        </View>
                        <View style={styles.section1}>
                            <View style={styles.seller}>
                                <Text>Nombre de cliente:
                                    <Text></Text>
                                </Text>
                                <Text>Leg_EVT: 1111</Text>
                                <Text>Dirección: 123123</Text>
                                <Text>Teléfono: 0800 </Text>
                                <Text>Cond. IVA: Responsable Inscripto</Text>
                            </View>
                            <View style={styles.fcInfo}>
                                <View style={styles.fcInfoCol}>
                                    <Text>Ing. Brutos: 12345</Text>
                                    <Text>Inicio de Actividades: 01/12/2021</Text>
                                    <Text>Moneda: ARS</Text>
                                </View>
                                <View style={styles.fcInfoCol}>
                                    <Text>FACTURA ELECTRONICA</Text>
                                    <Text>numero de fc</Text>
                                    <Text>Fecha:</Text>
                                    <Text>CUIT: </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.divider}></View>
                        <View style={styles.client}>
                            <View style={styles.clientColumns}>
                                <Text>Cliente: xxxx</Text>
                                <Text>Direccion: asdasdasd</Text>
                                <Text>Localidad: CBA</Text>
                                <Text>Cond. Iva: RESPONSABLE INSCRIPTO</Text>
                            </View>
                            <View style={styles.clientColumns}>
                                <Text>File: 22233312</Text>
                                <Text>Ing. Brutos:</Text>
                                <Text>CUIT: 30225587458</Text>
                                <Text>Tipo de cambio: 187.55</Text>
                            </View>
                            <View style={styles.clientColumns}>
                                <Text>Vendedor: ALA</Text>
                                <Text>Fecha de Vto: 15/04/21</Text>
                                <Text>Forma de Pago: UATP</Text>
                                <Text>Grupo de Pax: algo</Text>
                            </View>
                        </View>
                        <View style={styles.divider}></View>
                        <View style={styles.table}>
                            <View>
                                <Text>Pasajero</Text>
                                <Text key={index} style={styles.nameText}>
                                    {pdfData[item]?.map((flight, index2) => (
                                        flight.Travelers?.map((traveler, index3) => (
                                            <Text key={index3} style={styles.nameText}>
                                                {traveler.first_name} {traveler.last_name}
                                            </Text>
                                        ))
                                    ))}
                                </Text>
                                {/* {Object.keys(pdfData)?.map((item, index) => (
                                    <Text key={index} style={styles.nameText}>
                                        {pdfData[item]?.map((flight, index2) => (
                                            flight.Travelers?.map((traveler, index3) => (
                                                <Text key={index3} style={styles.nameText}>
                                                    {traveler.first_name} {traveler.last_name} {traveler.id}
                                                </Text>
                                            ))
                                        ))}
                                    </Text>
                                ))} */}
                            </View>
                            <View>
                                <Text>Ruta</Text>
                                <Text>-</Text>
                            </View>
                            <View>
                                <Text>Clase</Text>
                                <Text>-/-/-</Text>
                            </View>
                            <View>
                                <Text>Transp.</Text>
                                <Text>Vuelo</Text>
                            </View>
                            <View>
                                <Text>Nro. Tkt</Text>
                            </View>
                            <View>
                                <Text>F.salida</Text>
                                {pdfData[item]?.map((flight, index2) => (
                                    flight.Flights?.map((flight2, index3) => {
                                        const parsedFlight = JSON.parse(flight2.options);
                                        return (
                                            <Text key={index3} style={styles.nameText}>
                                                {format(new Date(parsedFlight.itineraries[index2].segments[index2].departure.at), defaultDateFormat)}
                                            </Text>
                                        );
                                    })
                                ))}
                            </View>
                            <View>
                                <Text>Tarifa</Text>
                                {pdfData[item]?.map((flight, index2) => (
                                    flight.Flights?.map((flight2, index3) => {
                                        const parsedFlight = JSON.parse(flight2.options);
                                        const { base, grandTotal } = parsedFlight.price;
                                        return (
                                            <Text key={index3} style={styles.nameText}>
                                                {base}
                                            </Text>
                                        );
                                    })
                                ))}
                            </View>
                            <View>
                                <Text>Imp/Vs</Text>
                                {pdfData[item]?.map((flight, index2) => (
                                    flight.Flights?.map((flight2, index3) => {
                                        const parsedFlight = JSON.parse(flight2.options);
                                        const { base, grandTotal } = parsedFlight.price;
                                        return (
                                            <Text key={index3} style={styles.nameText}>
                                                {grandTotal - base}
                                            </Text>
                                        );
                                    })
                                ))}
                            </View>
                            <View>
                                <Text>Total</Text>
                                {pdfData[item]?.map((flight, index2) => (
                                    flight.Flights?.map((flight2, index3) => {
                                        const parsedFlight = JSON.parse(flight2.options);
                                        const { base, grandTotal } = parsedFlight.price;
                                        return (
                                            <Text key={index3} style={styles.nameText}>
                                                {grandTotal}
                                            </Text>
                                        );
                                    })
                                ))}
                            </View>
                        </View>
                    </Page>
                ))}
            </Document>

        </>
    )
}
