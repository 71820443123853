import React from 'react'
import edit from "../../../../../iconos/edit_icon.png";
import { Link, useParams } from "react-router-dom";

export default function EditButton(Supplier_id) {

    const {tour_op_id} = useParams();

  return (
    <Link
        // Agregar link para la ventana de edicion de supplier por numero de ID
          to={`/${tour_op_id}/panel/suppliers/edit-supplier/${Supplier_id}`}
          className="relative h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/edit"
        >
          <img src={edit} alt="edit" className="mt-1 xl:mt-0" />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
            Editar
          </span>
    </Link>
  )
}
