import React from "react";
import down_arrow from "../../../../iconos/down_arrow.png";

export default function PassengerHeader({ icon, title, first, handleDisplay }) {
  return (
    <div
      className="py-3 px-8 flex justify-between items-center rounded-md bg-lightBlue cursor-pointer"
      onClick={() => handleDisplay(title)}
    >
      <span className="flex items-center gap-2">
        <img src={icon} alt="passenger" className="h-6" />
        <h2 className="font-medium text-xl">{title}</h2>
      </span>
      <img
        src={down_arrow}
        alt="arrow"
        className={`h-4 transition-transform ${
          first ? "-rotate-180" : "rotate-0"
        }`}
        id={`${title}-arrow`}
      />
    </div>
  );
}
