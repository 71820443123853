import { useState } from "react";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";
import axios from "axios";
import { saveTourOperator } from "../utils/saveTourOperator";

export const useTravelers = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [travelers, setTravelers] = useState([]);
  const [traveler, setTraveler] = useState([]);
  const [travelerPhone, setTravelerPhone] = useState([]);
  const [travelerDocument, setTravelerDocument] = useState([]);
  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createTraveler = async (traveler) => {
    const response = await axios.post(
      BASE_URL + "v2/travelers",
      traveler,
      headers
    );
    return response;
  };

  const getTravelers = async () => {
    setLoading(true);
    return (
      axios
        .get(BASE_URL + `v2/travelers/tour_op/${tourOP.id}`, headers)
        .then((e) => {
          setTravelers(e.data.response);
        })
        .catch((err) => setTravelers([]))
        .finally(() => setLoading(false))
    );
  };

  const getTraveler = async (id) => {
    return axios
      .get(BASE_URL + `v2/travelers/${id}`, headers)
      .then((e) => {
        setTraveler(e.data.response);
      })
      .catch((err) => console.log(err));
  };
  const getTravelerPhone = async (id) => {
    return axios
      .get(BASE_URL + `v2/travelers/phones/${id}`, headers)
      .then((e) => {
        setTravelerPhone(e.data.response);
      })
      .catch((err) => console.log(err));
  };
  const getTravelerDocument = async (id) => {
    return axios
      .get(BASE_URL + `v2/travelers/documents/${id}`, headers)
      .then((e) => {
        setTravelerDocument(e.data.response);
      })
      .catch((err) => console.log(err));
  };

  const updateTraveler = async (id, data) => {
    try {
      return await axios.patch(
        `v2/travelers/${id}`,
        {
          traveler: { ...data },
        },
        headers
      );
    } catch (error) {
      throw new Error("Ocurrió un error (UpdateUser): ", error);
    }
  };

  return {
    travelers,
    getTravelers,
    loading,
    getTraveler,
    traveler,
    travelerPhone,
    getTravelerPhone,
    getTravelerDocument,
    travelerDocument,
    updateTraveler,
    createTraveler,
  };
};
