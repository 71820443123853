import React from "react";
import TableActionsSuppliers from "./TableActionsSupplirs";
import ModalViewSupplier from "../../../suppliers/ModalViewSupplier";
import { ModalGeneric } from "../../../../utils/ModalGeneric";
import { useParams } from "react-router-dom";
import { useToggleVisibility } from "../../../../hooks/useToggleVisibility";


export default function TableRowPackages({
  supp,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const tour_op_id = useParams();

  // Determine row background color based on the index value
  //const rowColor = index % 2 === 0 ? "bg-white" : "bg-lightGray2";

  return (
    <>

          <tr className={`bg-white text-center border-2 border-lightBlue hover:bg-lightBlue2`}>
          <td
            className="p-2 border-2 border-lightBlue"
            onClick={toggleVisibility}
          >
            {supp.name}
          </td>
          <td
            className="p-2 border-2 border-lightBlue "
            onClick={toggleVisibility}
          >
            {supp.legal_name}
          </td>
          <td
            className="p-2 border-2 border-lightBlue "
            onClick={toggleVisibility}
          >
            {supp.contact_name}
          </td>
          <td
            className="p-2"
            onClick={toggleVisibility}
          >
            <a href={`https://${supp.account_link}`}>{supp.account_link}</a>
          </td>
          
          <td className="p-2 flex justify-center ">
            <TableActionsSuppliers
              supp={supp}
            />
          </td>
        </tr>
    

      <ModalGeneric toggle={toggleVisibility} show={isVisible}>
        <ModalViewSupplier
          children={[
            {
                selectedSupplier: supp,
                tour_op_id,
            },
          ]}
        />
      </ModalGeneric>
    </>
  );
}
