import { format } from "date-fns";
import React from "react";
import missing from "../../../../iconos/missing.jpg";
export default function BookingPackageDetail({ packageSelected, tour }) {

  return (
    <section className="col-span-2 row-span-2 rounded-lg border border-gray2 relative">
      <header className="w-full bg-lightBlue rounded-lg flex justify-between">
        <p className="font-medium text-xl px-8 py-3">
          {packageSelected?.title}
        </p>
        {tour.dateIn && (
          <div className="flex gap-4 items-center">
            <div className="grid items-center bg-white h-full px-4 border-2 border-brightBlue text-brightBlue font-semibold rounded-md">
              {tour.dateIn && format(new Date(tour.dateIn), "dd/MM/yyyy")} -{" "}
              {tour.dateOut && format(new Date(tour.dateOut), "dd/MM/yyyy")}
            </div>
          </div>
        )}
      </header>
      <div className="grid px-8">
        <p>
          <span className="font-semibold">Origen:</span>{" "}
          {packageSelected?.origin_code}
        </p>
        <p>
          <span className="font-semibold">Destino:</span>{" "}
          {packageSelected?.destination_code}
        </p>
        <p>
          <span className="font-semibold">Descripción:</span>
        </p>
        <div>
          {packageSelected?.description_general
            ?.split("<br>")
            .map((item, index) => (
              <p key={index}>{item}</p>
            ))}
        </div>
        <img
          src={packageSelected?.portrait || missing} // Try to load the portrait first, then missing
          alt="" // No alt text
          onError={(e) => {
            if (e.target.src !== missing) {
              // Prevents infinite loop
              e.target.src = missing; // Fallback to the missing image
            }
          }}
          className="h-40 pb-4"
        />
      </div>
    </section>
  );
}
