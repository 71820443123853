import React, { useEffect } from 'react'
import { Page, Document, Text, StyleSheet, View } from '@react-pdf/renderer';
import useGetAirports from '../../hooks/useGetAirports';
import numberWithCommas from '../../utils/numberWithCommas';
import { format } from 'date-fns';
import { defaultDateFormat } from '../../utils/commons';
import { useParams } from 'react-router-dom';
export default function InvoiceFilePDF({ pdfData }) {
    const { getAirports, airports } = useGetAirports();
    const { tour_op_id, invoice_id } = useParams();

    getAirports();

    let formattedCities = {};
    airports &&
        airports.forEach((airport) => {
            formattedCities[airport.iata] = airport.City.name;
        });

    //const { Flights, Travelers } = pdfData;
    //console.log(Flights)
    //if (!Flights) return;
    // const { first_name, last_name } = Travelers?.[0];
    //const parsedFlights = JSON.parse(Flights[0]?.options);
    // const { carrierCode } = parsedFlights.itineraries[0].segments[0];
    // const departureDate = parsedFlights.itineraries[0].segments[0].departure.at;
    // const oricode = parsedFlights.itineraries[0].segments[0].departure.iataCode;
    // const descode = parsedFlights.itineraries[1].segments[0].departure.iataCode;
    // const { base, grandTotal } = parsedFlights.price;



    // Global styles
    const globalStyles = StyleSheet.create({
        body: {
            fontSize: 10,
        },
    });

    // PDF-specific styles
    const styles = StyleSheet.create({
        divider: {
            width: '100%',
            borderBottomWidth: 2,
            borderBottomColor: 'black',
            marginBottom: 10,
            marginTop: 10,
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            paddingBottom: 60,
            paddingTop: 30,
            alignItems: 'center',
        },
        fcType: {
            width: 40,
            height: 40,
            borderWidth: 2,
            borderColor: 'black',
            padding: 3,
            textAlign: 'center',
            fontSize: 30,
        },
        section1: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
        seller: {
            flexDirection: 'column',
        },
        fcInfo: {
            flexDirection: 'row',
        },
        fcInfoCol: {
            flexDirection: 'column',
            marginHorizontal: 5,
        },
        client: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
        clientColumns: {
            flexDirection: 'column',
        },
        table: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
    });
    return (
        <Document>
            <Page size="A4" style={globalStyles.body}>
                <View style={styles.header}>
                    <Text>img</Text>
                    <Text style={styles.fcType}>A</Text>
                    <Text>ORIGINAL</Text>
                </View>
                <View style={styles.section1}>
                    <View style={styles.seller}>
                        <View>
                            <Text>Nombre de cliente:</Text>
                            <Text>{tour_op_id}</Text>
                        </View>

                        <Text>Leg_EVT: 1111</Text>
                        <Text>Dirección: 123123</Text>
                        <Text>Teléfono: 0800 </Text>
                        <Text>Cond. IVA: Responsable Inscripto</Text>
                    </View>
                    <View style={styles.fcInfo}>
                        <View style={styles.fcInfoCol}>
                            <Text>Ing. Brutos: 12345</Text>
                            <Text>Inicio de Actividades: 01/12/2021</Text>
                            <Text>Moneda: ARS</Text>
                        </View>
                        <View style={styles.fcInfoCol}>
                            <Text>FACTURA ELECTRONICA</Text>
                            <Text>numero de fc</Text>
                            <Text>Fecha:</Text>
                            <Text>CUIT: </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.divider}></View>
                <View style={styles.client}>
                    <View style={styles.clientColumns}>
                        <Text>Cliente: xxxx</Text>
                        <Text>Direccion: asdasdasd</Text>
                        <Text>Localidad: CBA</Text>
                        <Text>Cond. Iva: RESPONSABLE INSCRIPTO</Text>
                    </View>
                    <View style={styles.clientColumns}>
                        <Text>File: 22233312</Text>
                        <Text>Ing. Brutos:</Text>
                        <Text>CUIT: 30225587458</Text>
                        <Text>Tipo de cambio: 187.55</Text>
                    </View>
                    <View style={styles.clientColumns}>
                        <Text>Vendedor: ALA</Text>
                        <Text>Fecha de Vto: 15/04/21</Text>
                        <Text>Forma de Pago: UATP</Text>
                        <Text>Grupo de Pax: algo</Text>
                    </View>
                </View>
                <View style={styles.divider}></View>
                <View style={styles.table}>
                    <View>
                        <Text>Pasajero</Text>
                        {/* {Object.keys(pdfData)?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {pdfData[item]?.map((flight, index2) => (
                                    flight.Travelers?.map((traveler, index3) => (
                                        <Text key={index3} style={styles.nameText}>
                                            {traveler.first_name} {traveler.last_name} {traveler.id}
                                        </Text>
                                    ))
                                ))}
                            </Text>
                        ))} */}
                        {pdfData.Travelers?.map((traveler, index) => (
                            <Text key={index} style={styles.nameText}>
                                {traveler.first_name} {traveler.last_name}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>Nro. Tkt</Text>
                        <Text> </Text>
                    </View>
                    <View>
                        <Text>Ruta</Text>
                        {/* <Text>{formattedCities?.[oricode]} - {formattedCities?.[descode]} </Text> */}
                        {/* {Object.keys(pdfData)?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                <p>{item.id}</p>
                            </Text>
                        ))} */}
                    </View>
                    <View>
                        <Text>Clase</Text>
                        {/* {pdfData.Flights?.map((item, index) => {
                            const parsedFlight = JSON.parse(item.options);
                            return (
                                <Text key={index} style={styles.nameText}>
                                    {format(parsedFlight.itineraries[index].segments[index])}
                                </Text>
                            );
                        })} */}
                    </View>
                    <View>
                        <Text>Transp.</Text>
                        <Text>Vuelo</Text>
                    </View>

                    <View>
                        <Text>F.salida</Text>
                        {/* <Text>{format(new Date(departureDate), defaultDateFormat)}</Text> */}
                        {/* {Object.keys(pdfData)?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                <p>{item.id}</p>
                            </Text>
                        ))} */}
                        {pdfData.Flights?.map((item, index) => {
                            const parsedFlight = JSON.parse(item.options);
                            return (
                                <Text key={index} style={styles.nameText}>
                                    {format(new Date(parsedFlight.itineraries[index].segments[index].departure.at), defaultDateFormat)}
                                </Text>
                            );
                        })}
                    </View>
                    <View>
                        <Text>Tarifa</Text>
                        {/* <Text>{numberWithCommas(Number(base), 2)}</Text> */}
                        {pdfData.Flights?.map((item, index) => {
                            const parsedFlight = JSON.parse(item.options);
                            const { base, grandTotal } = parsedFlight.price;
                            return (
                                <Text key={index} style={styles.nameText}>
                                    {base}
                                </Text>
                            );
                        })}
                    </View>
                    <View>
                        <Text>Imp/Vs</Text>
                        {/* <Text>{numberWithCommas(Number(grandTotal - base), 2)}</Text> */}
                        {pdfData.Flights?.map((item, index) => {
                            const parsedFlight = JSON.parse(item.options);
                            const { base, grandTotal } = parsedFlight.price;
                            return (
                                <Text key={index} style={styles.nameText}>
                                    {grandTotal - base}
                                </Text>
                            );
                        })}
                    </View>
                    <View>
                        <Text>Total</Text>
                        {/* <Text>{numberWithCommas(Number(grandTotal), 2)}</Text> */}
                        {pdfData.Flights?.map((item, index) => {
                            const parsedFlight = JSON.parse(item.options);
                            const { base, grandTotal } = parsedFlight.price;
                            return (
                                <Text key={index} style={styles.nameText}>
                                    {grandTotal}
                                </Text>
                            );
                        })}
                    </View>
                </View>
            </Page>
        </Document>

    )
}
