import React from "react";
import { FaWhatsapp } from "react-icons/fa";

export default function WhatsappButton({size,number}) {
  return (

      <a target="_blank" href={`https://wa.me/${number}`} rel="noreferrer">
        <FaWhatsapp fontSize={size} />
      </a>
  
  );
}
