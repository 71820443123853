import React, { useState } from "react";
import PackageDetailFeeSection from "./feeSection/PackageDetailFeeSection";
import { format } from "date-fns";

export default function PackageDetailTourSection({
  packageData,
  setSelectedTour,
  setSelectedFee,
}) {
  const [selectedFeeId, setSelectedFeeId] = useState("");
  return (
    <div className="overflow-x-auto p-6 shadow-lg rounded-lg bg-white mt-4">
  <table
    className={`w-full bg-white text-center hover:bg-lightBlue2 rounded-lg border-none`}
  >
    <thead>
      <tr className="bg-lightBlue">
        <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">Salida</th>         
        <th className="border-2 border-lightBlue font-medium text-brightBlue text-center">Regreso</th>
        <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">Hotel</th>
        <th className=" border-2 border-lightBlue font-medium text-brightBlue text-center">Categoria</th>
      </tr>
    </thead>
    <tbody >
      {packageData.tours.map((tour, tourKey) => (
        <React.Fragment key={tourKey}>
          {/* Tour Row */}
          <tr className="bg-white text-center border-2 border-lightBlue">
            <td className="p-2 border-2 border-lightBlue">
              {tour.dateIn ? format(new Date(tour.dateIn), "dd/MM/yy") : "fecha salida"}
            </td>
            <td className="p-2 border-2 border-lightBlue">
              {tour.dateIn ? format(new Date(tour.dateOut), "dd/MM/yy") : "fecha salida"}
            </td>
            <td className="p-2 border-2 border-lightBlue">
              {tour.hotel ? tour.hotel : "-"}
            </td>
            <td className="p-2 border-2 border-lightBlue">
              {tour.catergory ? tour.catergory : "-"}
            </td>
          </tr>

          {/* Fees Row (filtered by corresponding tour id) */}
          {tour.fees
            .filter((fee) => fee.id_tours === tour.id) // Match fee to the corresponding tour
            .map((filteredFee, feeKey) => (
              <PackageDetailFeeSection
                tour={tour}
                key={feeKey}
                filteredFee={filteredFee}
                selectedFeeId={selectedFeeId}
                setSelectedFeeId={setSelectedFeeId}
                setSelectedTour={setSelectedTour}
                setSelectedFee={setSelectedFee}
              />
            ))}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>
  );
}
