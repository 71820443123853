import React from "react";
export default function MisTravelersBusqueda({ searcher, pdfData }) {
  const [{ textValue }, setSearcherValue] = searcher;
  return (
    <section className="flex gap-4 pb-4 mb-4 justify-between border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-80 rounded"
        placeholder="Buscar por Nombre, Apellido, ó Email..."
        value={textValue}
        onChange={(e) =>
          setSearcherValue((curr) => ({ ...curr, textValue: e.target.value }))
        }
      />
      {/* <input
                type="month"
                className="py-1 px-4 border border-gray2 w-80 rounded"
                value={monthValue}
                onChange={(e) =>
                    setSearcherValue((curr) => ({ ...curr, monthValue: e.target.value }))
                }
            /> */}
      {/* <a
                href="https://api.whatsapp.com/send/?phone=543512051784&text&type=phone_number&app_absent=0"
                rel="noreferrer"
                target="_blank"
                className="border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
            >
                <p>Exportar</p>
                <img src={download} alt="download" className="h-8 py-2.5" />
            </a> */}
      <div className="grid grid-cols-2 gap-2"></div>
    </section>
  );
}
