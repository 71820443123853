import download from "../../iconos/download.png";

export default function BookingTableSearcher({
  filters,
  setFilters,
  searchInput,
  setSearchInput,
  bookingStatus,
}) {
  const handleFilters = (e) => {
    const { name, value } = e.target;

    setFilters((curr) => {
      return {
        ...curr,
        [name]: value,
      };
    });
  };

  const handleRadioButtons = (e) => {
    setFilters((curr) => {
      return {
        ...curr,
        radioDate: e.target.name,
      };
    });
  };

  return (
    <section className="relative flex pb-4 mb-4 justify-between border-b border-gray2 mb:flex-col lg:flex-row">
      <input
        placeholder="Buscar por localizador, pasajero o saldo..."
        name="inputText"
        value={searchInput}
        onChange={(evt) => setSearchInput(evt.target.value)}
        className="py-1 px-2 border border-gray2 w-80 rounded"
      />

      <div className="flex items-center gap-4 mb:flex-col lg:flex-row">
        <div className="flex gap-2 mb:flex-col lg:flex-row mb:mt-4 lg:mt-0">
          <div className="inline-flex gap-6">
            <label
              htmlFor="creation"
              className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
            >
              <input
                type="radio"
                checked={filters.radioDate === "creation"}
                name="creation"
                id="creation"
                onChange={handleRadioButtons}
              />
              <p>de Creación</p>
            </label>
            <label
              htmlFor="departure"
              className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
            >
              <input
                type="radio"
                checked={filters.radioDate === "departure"}
                name="departure"
                id="departure"
                onChange={handleRadioButtons}
              />
              <p>de Salida</p>
            </label>
            <label
              htmlFor="emition"
              className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
            >
              <input
                type="radio"
                checked={filters.radioDate === "emition"}
                name="emition"
                id="emition"
                onChange={handleRadioButtons}
              />
              <p>Lím. Emisión</p>
            </label>
          </div>

          <input
            type="date"
            name="inputDate"
            value={filters.inputDate}
            onChange={handleFilters}
            placeholder="Elegir Fecha"
            className="px-3 py-1 border-2 border-gray2 rounded-md h-10"
          />
        </div>

        <span className="flex items-center ">
          <select
            name="inputStatus"
            value={filters.inputStatus}
            onChange={handleFilters}
            className="px-2 py-1 h-9 bg-white border border-gray2 rounded"
          >
            {bookingStatus.map(({ key, label }) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
        </span>

        <a
          href="https://api.whatsapp.com/send/?phone=543512051784&text&type=phone_number&app_absent=0"
          rel="noreferrer"
          target="_blank"
          className="border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
        >
          <p>Exportar</p>
          <img src={download} alt="download" className="h-8 py-2.5" />
        </a>
      </div>
    </section>
  );
}
