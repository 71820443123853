import React from "react";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";

export default function FooterSocials({ TourOperatorSocialMedia }) {
  const ig = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Instagram"
  )?.url;
  const fb = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Facebook"
  )?.url;
  const wpp = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Whatsapp"
  )?.url;
  return (
    <div>
      <h3 className="text-xl font-medium ">Sociales</h3>
      <ul className="flex gap-4 mt-4">
        {ig && (
          <li>
            <a target="_blank" href={ig} rel="noreferrer">
              <FiInstagram fontSize={24} />
            </a>
          </li>
        )}
        {wpp && (
          <li>
            <a target="_blank" href={wpp} rel="noreferrer">
              <FaWhatsapp fontSize={24} />
            </a>
          </li>
        )}
        {fb && (
          <li className="relative right-1.5">
            <a target="_blank" href={fb} rel="noreferrer">
              <FaFacebook fontSize={24} />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}
