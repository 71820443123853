import React from "react";
import HighFooter from "./highFooter/HighFooter";
import MidFooter from "./midFooter/MidFooter";
import LowFooter from "./lowFooter/LowFooter";
import "../../../styles/footer.css";
export default function Footer() {
  return (
    <div className="footerBackground">
      
      <div className="relative z-10 flex flex-col p-8">
        <div>
          <HighFooter />
        </div>
        <div>
          <hr className="text-footerGray border-2 shadow-lg mt-4 mb-2" />
          <MidFooter />
        </div>
      </div>
      <div className="mt-4 mb-8 flex justify-center ">
        <LowFooter />
      </div>
    </div>
  );
}
