/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useBookings } from "../../hooks/useBookings";
import FlightBookingTableRow from "./BookingTableRow-Flight";
import PackageBookingTableRow from "./BookingTableRow-Package";
import Pagination from "../../utils/Pagination";
import BookingTableHeader from "./BookingTableHeader";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import BookingTableSearcher from "./BookingTableSearcher";
import { saveTourOperator } from "../../utils/saveTourOperator";
import clsx from "clsx";
// import useSendEmail from "../../hooks/useSendEmail";
import { Link, useParams } from "react-router-dom";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { useStoreSearchInput } from "../../componentes/booking/useStoreSearchInput";
import useSendEmail from "../../hooks/useSendEmail";
// import { useTourOperators } from "../../hooks/useTourOperators";

const headers = (type) => [
  "Localizador",
  type === "flight" ? "Iata" : "Origen",
  type === "flight" ? "Lim. Emisión" : "Destino",
  "Primer Pasajero",
  "Neto Agencia",
  "F. Creación",
  "Estado",
  "Acción",
];

const generateEmailBodyForOp = (reserva, tour_op_id, value) => {
  // Mapping of booking statuses to labels
  const bookingStatusLabels = {
    allBookings: "Todas las Reservas",
    pending: "Pendiente",
    
    "for-ticketing": "Ok p Emisión",
    "to-ticket": "Emitir",
    confirmed: "Confirmado",
  };

  // Get the label for the current value
  const statusLabel = bookingStatusLabels[value] || "Estado desconocido"; // Fallback if value is not in the mapping

  const currentHREF = window.location.href.split("/").slice(0, -4).join("/");

  return `
    <h3>Actualización en estado de reserva!</h3>
    <h2>Estado de reserva: ${statusLabel}</h2>
    <h3>Nº de Reserva: ${reserva.id}</h3>
  `;
};
export default function BookingTable({ bookingStatus, path }) {
  const { type } = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { currencies } = useGetCurrencies();
  // const { sendEmail } = useSendEmail();
  const {
    bookings,
    errorPNR,
    updateBooking,
    getFlightBookings,
    getPackageBookings,
    deleteBooking,
    postAmadeusBooking,
  } = useBookings();

  const searchInput = useStoreSearchInput((st) => st.searchInput);
  const setSearchInput = useStoreSearchInput((st) => st.setSearchInput);
  const [filters, setFilters] = useState({
    radioDate: "",
    inputDate: "",
    inputStatus: "",
  });

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedBooking, setSelectedBooking] = useState();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { sendEmail } = useSendEmail();
  const getBookingsByType = () => {
    if (type === "flight") {
      return getFlightBookings();
    } else {
      return getPackageBookings();
    }
  };


  useEffect(() => {
    setShowError(false);
  }, [isVisible]);

  useEffect(() => {
    getBookingsByType();
  }, [type, tourOP]);

  const bookingStatusKeys = bookingStatus.map(({ key }) => key);
  // FILTRAR TODOS LOS BOOKINGS CON STATUS DE "QUOTE" O "RESERVA"
  const allBookings = useMemo(() => {
    return bookings?.filter(({ status }) => bookingStatusKeys.includes(status));
  }, [bookings, type]);

  const filteredBookings =
    allBookings &&
    allBookings
      .filter((booking) => {
        const { id, Travelers, travelers_packages } = booking;
        const loweredValue = searchInput?.toLowerCase();
        const travelersToUse =
          type === "flight" ? Travelers : travelers_packages;

        return (
          id?.toString()?.includes(loweredValue) ||
          travelersToUse?.[0]?.first_name
            ?.toLowerCase()
            ?.includes(loweredValue) ||
          travelersToUse?.[0]?.last_name?.toLowerCase()?.includes(loweredValue)
        );
      })
      .filter((booking) => {
        const { creation_date, Flights } = booking;
        if (!filters.inputDate || !filters.radioDate || !Flights || !Flights[0])
          return true;

        const { lastTicketingDate, itineraries } =
          JSON.parse(Flights[0]?.options) ?? {};
        const { at } = itineraries?.[0]?.segments?.[0]?.departure;

        if (filters.radioDate === "creation")
          return creation_date.split("T")[0] === filters.inputDate;
        if (filters.radioDate === "departure")
          return at.split("T")[0] === filters.inputDate;
        return lastTicketingDate === filters.inputDate;
      })
      .filter((booking) => {
        if (filters.inputStatus === "allBookings" || filters.inputStatus === "")
          return true;

        return booking.status === filters.inputStatus;
      });

  const openModal = (booking, modal) => {
    setSelectedBooking(booking);
    toggleVisibility();
    setSelectedModal(modal);
  };

  const mutateEstadoReserva = ({ reserva, e }) => {
    if (e.target.value === "confirmed" && type === "flight") {
      toggleVisibility();
      setSelectedBooking(reserva);
      setSelectedModal("confirm_PNR");
    } else {
      updateBookingState(reserva, e.target.value).finally(() =>
        getBookingsByType()
      );
    }
  };

  const updateBookingState = (reserva, value) => {
    return updateBooking({
      booking: reserva,
      update: {
        booking: {
          status: value,
          comment: `This booking is ${value}`,
        },
      },
    })
      .then((e) => {
        // Send the email after the booking is successfully updated
        sendEmail({
          type:  "Gmail",
          tour_op_id: 1,
          options: {
            from: "wearewalltrip@gmail.com",
            bcc: tourOP.email,
            subject: "Envío Nueva Reserva",
            html: generateEmailBodyForOp(reserva, tourOP.id, value), // Ensure correct arguments
          },
        });
      })
      .catch(() => {
        throw new Error("Error updating the booking and sending the email.");
      });

    // .then(() =>
    //   sendEmail({
    //     tour_op_id: tourOP.id,
    //     options: {
    //       from: "Test envío de emails",
    //       to: reserva?.Travelers?.[0]?.email_address,
    //       cc: [reserva?.Travelers?.[0]?.email_address],
    //       subject: "Actualización de Reserva",
    //       text: `Su reserva ${reserva.id} ha sido actualizada a '${value}'`,
    //       html: `<section><p>Su reserva ${reserva.id} ha sido actualizada a '${value}'</p></section>`,
    //       attachments: [],
    //     },
    //   })
    // );
  };

  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <>
      <section className="p-4 border-2 border-gray2 bg-white rounded-lg mb:-ml-16 lg:ml-0">
        <BookingTableSearcher
          filters={filters}
          setFilters={setFilters}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          bookingStatus={bookingStatus}
          page={page}
        />

        <section>
          <BookingTableHeader headers={headers(type)} />

          {filteredBookings?.length > 0 ? (
            filteredBookings
              ?.slice(page * 10, page * 10 + 10)
              ?.map((v, index) =>
                type === "flight" ? (
                  <FlightBookingTableRow
                    key={v.id}
                    index={index}
                    v={v}
                    type={type}
                    bookingStatus={bookingStatus}
                    bookingStatusKeys={bookingStatusKeys}
                    mutateEstadoReserva={mutateEstadoReserva}
                    openModal={openModal}
                  />
                ) : (
                  <PackageBookingTableRow
                    key={v.id}
                    index={index}
                    v={v}
                    type={type}
                    bookingStatus={bookingStatus}
                    bookingStatusKeys={bookingStatusKeys}
                    mutateEstadoReserva={mutateEstadoReserva}
                    openModal={openModal}
                    currency={translateCurrency(
                      v?.Packages?.[0]?.currency_type_id
                    )}
                  />
                )
              )
          ) : (
            <p className="my-3 ml-8 text-red-500">
              No se encontraron reservas en "
              {type === "flight" ? "Vuelos" : "Paquetes"}".
              <Link
                to={`${path}${type === "flight" ? "package" : "flight"}`}
                className="underline pl-4 text-black"
              >
                Ver en "{type === "flight" ? "Paquetes" : "Vuelos"}"
              </Link>
            </p>
          )}
        </section>
      </section>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        {selectedBooking && selectedModal === "delete" && (
          <div className="flex flex-col items-center gap-2">
            <h2>
              ¿Estás seguro de eliminar la reserva <b>{selectedBooking.id}</b>?
            </h2>
            <div className="flex justify-center gap-2">
              <button
                className="py-1 px-2 rounded font-medium border border-gray2"
                onClick={toggleVisibility}
              >
                Cancelar
              </button>
              <button
                className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                onClick={() => {
                  deleteBooking({ type, id: selectedBooking.id })
                    .then(() => {
                      getBookingsByType();
                    })
                    .finally(() => {
                      toggleVisibility();
                      setSelectedBooking();
                    });
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        )}
        {selectedBooking && selectedModal === "confirm_PNR" && (
          <div className="flex flex-col items-center gap-2">
            {!showError ? (
              <>
                <h2>
                  ¿Estás seguro de confirmar la reserva{" "}
                  <b>{selectedBooking.id}</b>?
                </h2>
                <div className="flex justify-center gap-2">
                  <button
                    className="py-1 px-2 rounded font-medium border border-gray2"
                    onClick={toggleVisibility}
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => {
                      setShowError(false);
                      setLoading(true);
                      postAmadeusBooking({ booking: selectedBooking })
                        .then((e) => {
                          const isError = e?.response?.result?.errors || false;

                          if (isError) {
                            setShowError(true);
                            return;
                          }

                          updateBookingState(
                            selectedBooking,
                            "Confirmado"
                          ).finally(() => {
                            toggleVisibility();
                            setSelectedBooking();
                            getBookingsByType();
                          });
                        })
                        .finally(() => setLoading(false));
                    }}
                    className={clsx(
                      {
                        "opacity-75 text-white cursor-not-allowed pointer-events-none":
                          loading,
                      },
                      "py-1 px-2 rounded font-medium border border-brightBlue bg-brightBlue text-white"
                    )}
                  >
                    Confirmar Reserva
                    {loading ? (
                      <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          stroke="currentColor"
                          stroke-width="4"
                          cx="12"
                          cy="12"
                          r="10"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                  </button>
                </div>
              </>
            ) : (
              <h1>
                {errorPNR?.[0]?.title}: {errorPNR?.[0]?.detail}
              </h1>
            )}
          </div>
        )}
      </ModalGeneric>

      <Pagination
        items={filteredBookings}
        itemsPerPage={10}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
