import React, { useEffect, useState } from "react";
import TableSuppliersHeader from "./TableSuppliersHeader";
import { useSupplier } from "../../../hooks/useSupplier";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import TableSuppliersBody from "./TableSuppliersBody";
import SuppliersFilter from "./SupplierFilter";
import Pagination from "../../../utils/Pagination";


export default function TableSuppliers() {
    const tourOP = saveTourOperator((st) => st.tourOP);
    const {suppliers, getSuppliers} =useSupplier();
    const [searcherValue, setSearcherValue] = useState("");
    const [sortOrder, setSortOrder] = useState("asc");
    const [page, setPage] = useState(0);

    const lowerSearch = searcherValue.toLowerCase();

    const sortedSuppliers = suppliers.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return sortOrder === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    });

    const FilteredSuppliers = sortedSuppliers
      .filter((supp) => {
        if (!lowerSearch) return true; 

        const { name, legal_name, email, contact_name } = supp;

        return (
            name.toLowerCase().includes(lowerSearch) ||
            legal_name.toLowerCase().includes(lowerSearch) ||
            email.toLowerCase().includes(lowerSearch) ||
            contact_name.toLowerCase().includes(lowerSearch)
          );
      })
      .slice(page * 10, page * 10 + 10);

    const [tableHeaders] = useState([
        "Nombre",
        "Legal",
        "Contacto",
        "Link",
        "Acciones",
      ]);
      useEffect(() => {
        getSuppliers();
      }, [tourOP]);

      

      return (
        <>
          <div className="p-4 border-2 border-gray2 bg-white rounded-lg">
            <SuppliersFilter 
              searcher={[searcherValue, setSearcherValue]} 
              orderChange={setSortOrder}
            />
            <table className="w-full border-collapse  rounded-lg overflow-hidden bg-lightBlue2 ">
              <TableSuppliersHeader cols={tableHeaders} />
              <TableSuppliersBody suppliers={FilteredSuppliers} />
            </table>
          </div>
          <Pagination
            items={suppliers}
            itemsPerPage={10}
            page={page}
            setPage={setPage}
          />
        </>
      );
}