import { format } from "date-fns";
import React from "react";

export default function ModalViewQuote({ selectedQuote }) {
  const creationDateFormatted = selectedQuote.creation_date
    ? format(new Date(selectedQuote.creation_date), "dd/MM/yyyy")
    : "N/A";

  const datesFormatted = selectedQuote.dates ? selectedQuote.dates : "N/A";

  const currency = selectedQuote.quote?.currency || "N/A"; // Default to a message if currency is null

  const totalAmountFormatted = selectedQuote.quote?.totalAmount
    ? `${currency} ${selectedQuote.quote.totalAmount}`
    : "N/A";

  const breakdown = selectedQuote.quote?.breakdown || {
    taxes: 0,
    flights: 0,
    services: 0,
  };

  return (
    <div className="">
      <h2 className="text-xl font-semibold">Detalle de Cotizacion</h2>

      <div className="mt-4">
        <h3 className="font-medium">Pasajeros</h3>
        <p>{selectedQuote.passengers}</p>
      </div>

      <div className="mt-4">
        <h3 className="font-medium">Detalles del Viaje</h3>
        <p>
          <strong>Origen:</strong> {selectedQuote.origin || "No especificado"}
        </p>
        <p>
          <strong>Destino:</strong>{" "}
          {selectedQuote.destination || "No especificado"}
        </p>
        <p>
          <strong>Fechas:</strong> {datesFormatted}
        </p>
      </div>

      <div className="mt-4">
        <h3 className="font-medium">Pedido</h3>
        <p>
          <strong></strong> {selectedQuote.ask || "No especificado"}
        </p>
      </div>
      {/* <div className="mt-4">
        <h3 className="font-medium">Cotizacion</h3>
        <p><strong>Vuelos:</strong> {`${currency} ${breakdown.flights}`}</p>
        <p><strong>Impuesto:</strong> {`${currency} ${breakdown.taxes}`}</p>
        <p><strong>Servicio:</strong> {`${currency} ${breakdown.services}`}</p>
        <p><strong>Total:</strong> {totalAmountFormatted}</p>
      </div> */}

      <div className="mt-4">
        <h3 className="font-medium">Informacion de Contacto</h3>
        <p>
          <strong>Nombre:</strong> {selectedQuote.name || "No Disponible"}
        </p>
        <p>
          <strong>Email:</strong> {selectedQuote.email || "No Disponible"}
        </p>
        <p>
          <strong>WhatsApp:</strong> {selectedQuote.whatsapp || "No Disponible"}
        </p>
      </div>

      <div className="mt-4">
        <h3 className="font-medium">Informacion Adicional</h3>
        <p>
          <strong>Estado de Cotizacion:</strong> {selectedQuote.status}
        </p>
      </div>

      <div className="mt- flex flex-inline items-center gap-2">
        <strong >Fecha de Creacion: </strong>
        <p>
          
            {selectedQuote.creation_date
              ? format(
                  new Date(selectedQuote.creation_date),
                  "yyyy-MM-dd  HH:mm:ss"
                )
              : "No especificado"}
        
        </p>
      </div>
    </div>
  );
}
