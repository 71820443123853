import React from "react";
import PackageCardDetails from "./packageDetails/PackageCardDetails";

export default function PackageCard({ packageData, currencies, passengers }) {
  //const [isVisible, toggleVisibility] = useToggleVisibility();
  return (
    <>
      <div className="contents " >
        <PackageCardDetails
          packageData={packageData}
          passengers={passengers}
          currencies={currencies}
        />
      </div>

      {/* <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <PackageView />
      </ModalGeneric> */}
    </>
  );
}
