import userIcon from "../../../iconos/fi-rr-user.png";
import _ from "lodash";
import { Toaster, toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../hooks/useUserStore";
import { useLocationQuery } from "../../../hooks/useLocationQuery";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import { useSelectFlights } from "../../flights/useSelectFlights";
import { useSelectPaquetes } from "../useSelectPaquetes";
import useGetCurrencies from "../../../hooks/useGetCurrencies";
import { useBookings } from "../../../hooks/useBookings";
import useCommissionsAndTaxes from "../../../hooks/useCommissionsAndTaxes";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import useSendEmail from "../../../hooks/useSendEmail";
import SEO from "../../../utils/SEO";
import { FormUserData } from "../../detalle/FormUserData";
import { passengerTypes } from "../../../utils/commons";
import { PurchaseDetail } from "../../detalle/PurchaseDetail";
import { ModalGeneric } from "../../../utils/ModalGeneric";
import { Login } from "@mui/icons-material";
import Head from "../../head";
import PassengerHeader from "./bookingSegments/PassengerHeader";
import { generateEmailBodyForOp } from "../../../utils/bookingPackages/generateEmailBodyForOp";
import { checkErrors } from "../../../utils/bookingPackages/checkErrors";
import { handleDisplay } from "../../../utils/bookingPackages/bookingFunctions";
import BookingPackageDetail from "./bookingSegments/BookingPackageDetail";
import { useLocation } from "react-router-dom";

export default function PackagesBooking() {
  const { user } = useUserStore();
  const { parsedHash } = useLocationQuery();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const type = parsedHash?.type;
  const original = useSelectFlights((st) => st.original);
  const location = useLocation();
  const { packageData, fee, tour } = location.state || {};
  const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  const numberOfPassengers = useSelectPaquetes((st) => st.numberOfPassengers);
  const { travelerPricings, selectedAmenities } = original;
  const { currencies } = useGetCurrencies();
  const { createBooking, newBookingId } = useBookings();
  const { commissions, getCommissions } = useCommissionsAndTaxes();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { sendEmail } = useSendEmail();
  const [passengerData, setPassengerData] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    getCommissions();
  }, []);

  const book = async () => {
    // VALIDAR TODOS LOS CAMPOS DE TODOS LOS PASAJEROS
    const separatedErrors = Object.values(
      errors?.["1"] ? errors : { 1: { name: { correct: false, value: "" } } }
    );

    if (
      separatedErrors.some(
        (errors, index) => !checkErrors(errors, index === 0, type)
      )
    )
      return;

    const currency_type_id = packageSelected.currency_type_id;

    toast.promise(
      createBooking({
        selectedItem: packageData?.id,
        passenger: _.values(passengerData),
        type: "package",
        currency_type_id,
        fee:fee,
        tour:tour
      })
        .then((e) => {
          sendEmail({
            type: "Gmail",
            tour_op_id: 1,
            options: {
              from: "wearewalltrip@gmail.com",
              bcc: tourOP.email,
              subject: "Envío Nueva Reserva",
              html: generateEmailBodyForOp(
                packageSelected,
                tourOP.id,
                newBookingId
              ),
            },

            // options: {
            //   ...setMailOptions(
            //     tourOP?.email,
            //     user?.email,
            //     type,
            //     e?.data?.detail
            //   ),
            // },
            // });
          });
        })
        .catch(() => {
          !user?.id && toggleVisibility(true);
          throw new Error();
        }),
      {
        loading: "Cargando...",
        success: <b>Reserva con éxito.</b>,
        error: (
          <b>
            {!user?.id ? "Inicia sesión." : "Hubo un error, intenta de nuevo."}
          </b>
        ),
      }
    );
  };

  const formattedNumberOfPassengers = () => {
    const { ADT, CHD, INF } = numberOfPassengers;
    const totalPassangers = ADT + CHD + INF;

    return new Array(totalPassangers).fill(0).map((_, index) => {
      const travelerType =
        index < ADT ? "ADULT" : index < ADT + CHD ? "CHILD" : "HELD_INFANT";

      return {
        travelerType,
        travelerId: index + 1,
      };
    });
  };

  return (
    <section className="h-full">
      <SEO title={"Detalle"} />
      <div>
        <Toaster />
      </div>
      <Head />
      <main className="max-w-[1100px] mx-auto my-8">
        <div className="grid justify-center grid-cols-12 gap-1 grid-rows-12">
          <div className="col-span-12 px-4 pt-2 pb-1 rounded-lg ">
            <h1 className="text-xl text-[#242423] font-[700]">
              ¡Ya casi estás! Completá tus datos y nos comunicaremos con vos.
            </h1>
          </div>
          <div className="relative col-span-6 flex flex-col gap-4 mr-2">
            {/* Pasajeros */}
            {(travelerPricings || formattedNumberOfPassengers() || []).map(
              (v, index) => {
                const title = `Pasajero ${index + 1} (${
                  passengerTypes?.[v?.travelerType] || "Adulto"
                })`;

                return (
                  <div
                    key={v?.travelerId || index}
                    className="border border-gray2 rounded-lg"
                  >
                    <PassengerHeader
                      first={index === 0}
                      key={v?.travelerId || index}
                      row={6}
                      title={title}
                      icon={userIcon}
                      handleDisplay={handleDisplay}
                    />
                    <FormUserData
                      id={v?.travelerId}
                      index={index}
                      idBody={`${title}-body`}
                      isFlight={type === "flights"}
                      passengerState={[passengerData, setPassengerData]}
                      error={[errors, setErrors]}
                    />
                  </div>
                );
              }
            )}
          </div>

          <div className="col-span-6 ml-2 row-span-12 ">
            <div className="sticky top-2 flex flex-col gap-4">
              <section
                className={`col-span-2 row-span-2 rounded-lg border border-gray2 overflow-hidden relative`}
              >
                <PurchaseDetail
                  fee={fee}
                  type={type}
                  selectedAmenities={selectedAmenities}
                  commissions={commissions}
                  currencies={currencies}
                  numberOfPassengers={formattedNumberOfPassengers()}
                />
              </section>

              <BookingPackageDetail packageSelected={packageData} tour={tour} />
              <button
                id="reserve_btn"
                className={`relative col-span-2 row-span-2 text-darkBlue text-center py-4 border-2 border-darkBlue text-2xl font-semibold w-full rounded-md mb-2 cursor-pointer hover:bg-darkBlue hover:text-white active:opacity-75 transition-all`}
                onClick={book}
              >
                RESERVAR
              </button>
            </div>
          </div>
        </div>
      </main>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <Login keepRoute closeModal={toggleVisibility} />
      </ModalGeneric>
    </section>
  );
}
