import React from 'react'

import fallback from "./../../../../../iconos/fallback.png";
import gym from "./../../../../../iconos/gym.png";
import swim from "./../../../../../iconos/swim.png";
import dinner from "./../../../../../iconos/dinner.png";
import shop from "./../../../../../iconos/shop.png";
import wifi from "./../../../../../iconos/wifi.png";

export default function PackageCardModal({title,portrait,description,stock}) {
  return (
    <section className="py-3 px-4">
    <h2 className="font-medium text-2xl mb-4 capitalize">
      {title.toLowerCase()}
    </h2>
    <img
      src={portrait ?? fallback}
      alt="bg"
      className="max-h-64 mx-auto rounded-md"
    />
    <h2 className="font-medium text-2xl mb-4 mt-4">Descripción</h2>
    <div>
      {description?.split("<br>").map((item) => (
        <p>{item}</p>
      ))}
    </div>
    <p className="font-medium mt-4">
      Cupos Disponibles: <span>{stock}</span>
    </p>

    <div className="flex justify-between mt-4">
      <div>
        <p className="font-medium mb-2">Incluye</p>
        <span className="flex items-center gap-4">
          <img src={gym} alt="gym" className="w-4" />
          <img src={swim} alt="swim" className="w-4" />
          <img src={dinner} alt="dinner" className="w-4" />
          <img src={shop} alt="shop" className="w-4" />
          <img src={wifi} alt="wifi" className="w-4" />
        </span>
      </div>
      <div>
        <p className="font-medium mb-2">Fechas</p>
        <span className="flex items-center gap-4">
          {/* <p>{format(new Date(departure_date), "dd/MM/yyyy")}</p>-
          <p>{format(new Date(return_date), "dd/MM/yyyy")}</p> */}
        </span>
      </div>
    </div>
  </section>
  )
}
