import React from "react";
import { MdModeEditOutline } from 'react-icons/md';

export default function ActivateEditingButton({ setEdit, edit }) {
  return (
    <button
    className={`relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link ${
      edit ? "" : "text-red-500"
    }`}
      onClick={() => setEdit(!edit)} // Ensure it's a function toggling the `edit` state
    >
      <MdModeEditOutline scale={23} />
      <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/link:scale-100">
        Editar
      </span>
    </button>
  );
}