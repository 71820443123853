import React from "react";

export default function TravelerHeader({
  handleSelectAll,
  travelers,
  page,
  selectedRows,
}) {
  return (
    <tr>
      <th className="py-2 px-4 text-left font-medium text-brightBlue">
        <input
          className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
          type="checkbox"
          onChange={handleSelectAll}
          checked={
            travelers.slice(page * 10, (page + 1) * 10).length > 0 &&
            travelers
              .slice(page * 10, (page + 1) * 10)
              .every((traveler) =>
                selectedRows.some((selected) => selected.id === traveler.id)
              )
          }
        />
      </th>

      <th className="py-2 px-4 text-left font-medium text-brightBlue ">
        Apellido
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        Nombre
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        Género
      </th>
      {/* <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
                Próx Viaje
              </th> */}
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        Tipo de Documento
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        Documento
      </th>

      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        Fecha de nacimiento
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue mb:hidden lg:table-cell">
        Email
      </th>
      <th className="py-2 px-4 text-left font-medium text-brightBlue">
        Acción
      </th>
    </tr>
  );
}
