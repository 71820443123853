import React from "react";
import { MdShieldMoon, MdStar, MdSunny } from "react-icons/md";

export default function TourRowTableHead() {
  return (
    <tr className=" bg-lightBlue  py-2 px-8 rounded-lg   ">
      <th className="font-medium text-brightBlue text-center px-4">Salida #</th>
      <th className="font-medium text-brightBlue text-center px-4">
        Fecha Salida
      </th>
      <th className="font-medium text-brightBlue text-center px-4">Hoteles</th>
      <th className="font-medium text-brightBlue flex items-center justify-center h-full ">
        <MdStar scale={23} />
      </th>
      <th className="font-medium text-brightBlue text-center px-4">Comida</th>
      <th className="font-medium text-brightBlue text-center px-4">
        <MdSunny scale={23} />
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        <MdShieldMoon scale={23} />
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        Disponibilidad
      </th>
      <th className="font-medium text-brightBlue text-center px-4">
        Stock
      </th>
      <th className="font-medium text-brightBlue text-center px-4">Acciones</th>
    </tr>
  );
}
