import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function PanelSelector({ type }) {
  const { tour_op_id } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="relative flex items-center border-2 border-lightBlue rounded-3xl h-12">
          <button
            className={`px-8 rounded-3xl w-32 z-10 text-lg ${
              type === "users"
                ? " h-12 w-32 bg-lightBlue transition-all rounded-3xl"
                : ""
            }`}
            onClick={() =>
              navigate(`/${tour_op_id}/panel/profile/users`)
            }
          >
            Usuarios
          </button>
          <button
            className={`px-8 rounded-3xl w-32 z-10 text-lg justify-center  ${
              type === "agencies"
                ? " h-12 w-32 bg-lightBlue transition-all rounded-3xl"
                : ""
            }`}
            onClick={() =>
              navigate(`/${tour_op_id}/panel/profile/agencies`)
            }
          >
            Agencias
          </button>
          <button
            className={`px-8 rounded-3xl w-32 z-10 text-lg justify-center ${
              type === "rules"
                ? " h-12 w-32 bg-lightBlue transition-all rounded-3xl"
                : ""
            }`}
            onClick={() =>
              navigate(`/${tour_op_id}/panel/profile/fees`)
            }
          >
            Reglas
          </button>

          <button
            className={`px-8 rounded-3xl w-32 z-10 text-lg justify-center ${
              type === "operator"
                ? "h-12 w-32 bg-lightBlue transition-all "
                : ""
            } `}
            onClick={() =>
              navigate(`/${tour_op_id}/panel/profile/my-tour-operator`)
            }
          >
            Operadora
          </button>
          
          {tour_op_id === "walltrip" ?
         
          <button
            className={`px-8 rounded-3xl w-32 z-10 text-lg justify-center  ${
              type === "operators"
                ? "h-12 w-32 bg-lightBlue transition-all "
                : ""
            } `}
            onClick={() =>
              navigate(`/${tour_op_id}/panel/profile/admin`)
            }
          >
            Operadoras
          </button>
           :<></>}
        </div>
      </div>
    </>
  );
}
