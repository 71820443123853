import React from "react";
import TableRowSuppliers from "./tableRow/TableRowSuppliers";

export default function TablePackagesBody({ suppliers }) {

  return (
    <>
      <tbody className="text-sm xl:text-base  items-center text-gray">
        {suppliers.map((supp, index) => (
          <React.Fragment key={index}>
            <TableRowSuppliers
              index={index}
              supp={supp}
            />
          </React.Fragment>
        ))}
      </tbody>
    </>
  );
}
