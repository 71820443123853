import React from "react";
import ministerio from "../../../../iconos/ministerio.png";
import fiscal from "../../../../iconos/fiscal.png";
export default function FooterFiscal({ license, file }) {
  return (
    <div className="grid gap-1">
      {license && <p className="m-0 text-sm">{license}</p>}
      <span className="flex gap-1 pt-1.5">
        <a href={file} target="_blank" rel="noreferrer">
          <img className="w-10" src={fiscal} alt="fiscal" />
        </a>
        <img className="w-32 h-12" src={ministerio} alt="ministerio" />
      </span>
    </div>
  );
}
