import React from 'react'

export default function TableQuoteHeader({ cols,setSelectedRows,selectedRows,quotes,page }) {

    
  const handleSelectAll = (event) => {

    if (event.target.checked) {
      // Select all travelers for the current page

      setSelectedRows(quotes);
    } else {
      // Deselect all travelers
      setSelectedRows([]);
    }
  };
    return (
        <thead className="rounded-lg">
          <tr className="bg-lightBlue">
            {/* Default header with checkbox */}
            <th className="text-center px-4">
              <input
                className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                type="checkbox"
                onChange={handleSelectAll}
                checked={
                    quotes.slice(page * 10, (page + 1) * 10).length > 0 &&
                    quotes.slice(page * 10, (page + 1) * 10).every(traveler =>
                    selectedRows.some(selected => selected.id === traveler.id)
                  )
                }
              />
            </th>
            {cols.map((header, index) => (
              <th
                className="font-medium text-brightBlue text-center px-4"
                key={index}
                scope="col"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
      );
    }