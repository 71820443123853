import React from "react";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../auth/useAuth";
import { useUserStore } from "../../../hooks/useUserStore";
import logo_walltrip from "../../../iconos/logos/walltrip.png";
import NavBarSocials from "./NavBarSocials";

export default function Navbar({ firstNavigableRoute }) {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id } = useParams();
  const { logOut } = useAuth();
  const { user } = useUserStore();
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  return (
    <>
      <div className="flex justify-between items-center w-full py-4 mb:block md:inline-flex z-custom ">
        <div className="mb:mb-4  ">
          <Link
            className="cursor-pointer"
            to={`/${tour_op_id || "walltrip"}/${firstNavigableRoute()}`}
          >
            <img
              className="max-w-[128px] max-h-16 text-black  "
              src={tourOP?.logo !== "-" ? tourOP?.logo : logo_walltrip}
              alt={tourOP?.name}
            />
          </Link>
        </div>
        <div className="flex md:gap-3 mb:gap-1 mb:justify-center ">
          <NavBarSocials />
          <Link
            to={`/${tour_op_id || "walltrip"}/history`}
            className="border max-h-8 flex items-center md:px-6 mb:px-2 py-1 border-[#8D99AE] rounded-md font-medium text-sm cursor-pointer"
          >
            Mis Viajes
          </Link>
          {!token ? (
            <Link
              to={`/${tour_op_id}/login`}
              className=" max-h-8 flex items-center px-6 py-1 bg-[#CEDEF1] rounded-md font-medium text-sm cursor-pointer"
            >
              Acceso Agencias
            </Link>
          ) : (
            <button
              onClick={logOut}
              className=" max-h-8 flex items-center px-6 py-1 bg-[#CEDEF1] rounded-md font-medium text-sm cursor-pointer"
            >
              Cerrar sesión
            </button>
          )}

          {token && user?.role_id !== 3 ? (
            <Link
              to={`/${tour_op_id || "walltrip"}/panel/sales/bookings/flight`}
              className="border max-h-8 flex items-center px-6 py-1 border-[#8D99AE] rounded-md font-medium text-sm cursor-pointer"
            >
              Panel
            </Link>
          ) : null}
        </div>
      </div>
    </>
  );
}
