// useReloadStore.js
import { create } from "zustand";

const useReloadStore = create((set) => ({
  reloadFees: false,
  reloadTours: false,
  reloadPackages: false,
  setReloadFees: () => set((state) => ({ reloadFees: !state.reloadFees })),
  setReloadTours: () => set((state) => ({ reloadTours: !state.reloadTours })),
  setReloadPackages: () => set((state) => ({ reloadPackages: !state.reloadPackages })),
}));

export default useReloadStore;
