import React from 'react'
import {Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

export default function AgenciesTablePDFile({pdfData}) {

    // Global styles
    const globalStyles = StyleSheet.create({
        body: {
            fontSize: 10,
        },
    });

    // PDF-specific styles
    const styles = StyleSheet.create({

        table: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 2,
        },
    });

    return (
        <Document>
            <Page size="A4" style={globalStyles.body}>

                <View style={styles.table}>
                    
                    <View>
                        <Text>Direccion</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.address}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>Catergoria</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.category}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>Ciudad</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.city}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>Apellido</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.contact_lastname}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>Nombre</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.contact_name}
                            </Text>
                        ))}
                    </View>
                    {/* <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.contact_position}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.country}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.email}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.license_number}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.logo}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.name}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.phone}
                            </Text>
                        ))}
                    </View>
                    <View>
                        <Text>ID</Text>
                        {pdfData?.map((item, index) => (
                            <Text key={index} style={styles.nameText}>
                                {item.state}
                            </Text>
                        ))}
                    </View> */}
                </View>
            </Page>
        </Document>
    );
};

