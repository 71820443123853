import React from "react";
import TravelersPDFile from "../../TravelersPDFile";
import { BlobProvider } from "@react-pdf/renderer";
import download from "../../../../iconos/download.png";
import { upperCase } from "lodash";
import * as XLSX from "xlsx";
import { format } from "date-fns";
export default function ExportButtonsTraveler({ pdfData }) {
  const handleExport = (dataExcel) => {
    console.log(dataExcel);

    // Sample data to be exported

    // Map dataExcel to new keys corresponding to the custom headers
    const mappedData = dataExcel.map((item) => ({
      Nombre: item.first_name,
      Apellido: item.last_name,
      DOB: format(new Date(item.date_of_birth), "dd/MM/yyyy"),
      Pasaporte: "",
      Email: item.email_address,
      Genero: item.gender === "M" ? "Masculino" : "Femenino",
      Amadeus: `${upperCase(item.last_name)}/${upperCase(
        item.first_name
      )} ${format(new Date(item.date_of_birth), "ddMMMyy")} P/`,
      Sabre: `${upperCase(item.last_name)}/${upperCase(
        item.first_name
      )}*${format(new Date(item.date_of_birth), "ddMMMyy")}*P/`,
    }));
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(mappedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the .xlsx file and trigger the download
    XLSX.writeFile(workbook, "pasajeros.xlsx");
  };

  return (
    <div className="flex flex-row gap-4">
      {pdfData && pdfData.length > 0 && (
        <>
          <BlobProvider document={<TravelersPDFile pdfData={pdfData} />}>
            {({ url, loading }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={url}
                  download="pasajeros.pdf"
                  className=" border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
                >
                    <p>
                PDF

                    </p>
                  <img src={download} alt="download" className="h-8 py-2.5" />
                </a>
              )
            }
          </BlobProvider>

          <button
            onClick={(e) => handleExport(pdfData)}
            className="border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors"
          >
         Excel
            <img src={download} alt="download" className="h-8 py-2.5" />
          </button>
        </>
      )}
    </div>
  );
}
