import { useState } from "react";
import axios from "axios";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";


export const useFees = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [loading, setLoading] = useState(false);

  const [fees, setFees] = useState([]);


  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };


  const createFee = async (pack) => {
    const response = await axios.post(BASE_URL + "v2/fee", pack, headers);
    return response;
  };

  const updateFee = async (fee) => {
    const { id, ...restOfFee } = fee;

    return await axios.patch(
      BASE_URL + `v2/fee/${id}`,
      {
        data: fee
      },
      headers
    );
  };

  const deleteFee = async (id) => {
    return await axios.delete(BASE_URL + `v2/fee/${id}`, headers);
  };

  const getFees = async (id) => {
    setLoading(true);
    axios
      .get(BASE_URL +`v2/fee/tour/${id}`, headers)
      .then((e) => {
        setFees(e.data.response);
      })
      .catch(() => setFees([]) )
      .finally(() => setLoading(false));
  };

  const refreshFees = (id) => {
    getFees(id);
  };

  return {
    fees,
    loading,
    getFees,
    createFee,
    updateFee,
    deleteFee,
    refreshFees
  };
};
