import React from 'react';
import useGetCurrencies from '../../../hooks/useGetCurrencies';

export default function CurrencyFilter({ capitalizeFirstLetter, handleDisplay, arrow, filters, handleChecks }) {
    const { currencies } = useGetCurrencies();

    return (
        <div>
            <section
                onClick={() => handleDisplay("currency")}
                id="currency-header"
                className="cursor-pointer"
            >
                <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
                    <h2 className="font-low">Moneda</h2>
                    <img
                        src={arrow}
                        alt="arrow"
                        className="w-4 h-2 -rotate-180 transition-all"
                        id="currency-arrow"
                    />
                </header>
            </section>
            <section
                className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
                id="currency-body"
            >
                {currencies.map((el) => {
                    const capitalizedCurrencyName = capitalizeFirstLetter(el.name);

                    return (
                        <div className="flex items-center" key={el.id}>
                            <input
                                className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                type="checkbox"
                                id={el.id}
                                name="currency"
                                checked={filters.currency.includes(el.id)} // Updated to check the currency selection
                                onChange={(evt) => handleChecks(evt, el.id, 'currency')}
                            />
                            <label  className="flex gap-2 items-center">
                                <p className="max-w-[15ch] ml-2">{capitalizedCurrencyName}</p>
                            </label>
                        </div>
                    );
                })}
            </section>
        </div>
    );
}