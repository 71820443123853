export default function BookingTableHeader({ headers }) {
  return (
    <header className="bg-lightBlue grid grid-cols-3 lg:grid-cols-10 flex-row gap-4 py-2 px-8 rounded-lg">
      {headers.map((header, index) => {
        const isFirst = index === 0;
        const isLastTwo = index >= headers.length - 2;

        return (
          <h4
            key={`${header}-${index}`}
            className={`font-medium text-brightBlue ${
              !isFirst && !isLastTwo ? 'mb:hidden lg:table-cell' : ''
            } ${
              header === "Primer Pasajero" && "col-span-2"
            } ${
              (header === "F. Creación" || header === "Estado") && "mx-auto"
            } ${header === "Acción" && "ml-auto"} text-center md:text-left`}
          >
            {header}
          </h4>
        );
      })}
    </header>
  );
}