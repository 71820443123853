import {useState} from 'react';
import axios from 'axios';
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";


export const useSupplier = () => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);
    const [suppliers, setSuppliers] = useState([])

    const token =
        getLocalStorageValue("token") || getSessionStorageValue("token");

    const headers = {
        headers: { Authorization: `${token}` },
    };

    const createSupplier = async (supplier) => {
        const response = await axios.post( BASE_URL + "v2/suppliers/", supplier, headers);
        return response;
    };
    
    // Es un ejemplo, falta endpoint de Patch Suppliers
    // const updateSupplier = async (pack) => {
    //     const { id, fechaCreacion, ...restOfPackage } = pack;
    
    //     return await axios.patch(
    //       BASE_URL + `v2/suppliers/${id}`,
    //       {
    //         package: { ...restOfPackage },
    //       },
    //       headers
    //     );
    //   };
    
    // Comentado, funcionalidad no pedida
    // const deleteSupplier = async (id) => {
    //   return await axios.delete(BASE_URL + `v2/suppliers/${id}`, headers);
    // };

      const getSuppliers = async () => {
        setLoading(true);
        axios
          .get(BASE_URL  + "v2/suppliers", headers)
          .then((e) => {
            setSuppliers(e.data.response);
          })
          .finally(() => setLoading(false));
      };

  return {
    suppliers,
    loading,
    getSuppliers,
    createSupplier
  };
};
