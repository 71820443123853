import React from "react";
import useMobileDetector from "../../hooks/useMobileDetector";
import SidebarPanelMobile from "./SidebarPanelMobile";
import { SidebarPanel } from "./SidebarPanel";

function Sidebar({ hoverState }) {
  const isMobile = useMobileDetector();
  return <>{isMobile ? <SidebarPanelMobile /> : <SidebarPanel hoverState={hoverState}/>}</>;
}

export default Sidebar;
