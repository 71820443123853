import React, { useState } from "react";
import { Box, Button, Card, CardMedia, Typography } from "@mui/material";
import {  FaAngleLeft, FaAngleRight } from "react-icons/fa";
import missing from "../../../../iconos/missing.jpg";
export default function PackageImageSection({ pictures, portrait, title }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % pictures.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + pictures.length) % pictures.length
    );
  };
  console.log(pictures)
  return (
    <Box>
      {pictures?.length > 0 ? (
        <Box display="flex" alignItems="center">
          <Button onClick={handlePrev} disabled={currentIndex === 0}>
            <FaAngleLeft size={23} />
          </Button>
          <Card>
            <CardMedia
              component="img"
              src={pictures[currentIndex].url}
              alt={`Foto ${currentIndex + 1}`}
              style={{
                maxWidth: "400px",
                maxHeight: "300px",
                objectFit: "cover",
              }} // Adjust dimensions as needed
            />

            {/* <Typography variant="caption" align="center">
              {` ${currentIndex + 1} of ${pictures.length}`}
            </Typography> */}
          </Card>
          <Button
            onClick={handleNext}
            disabled={currentIndex === pictures.length - 1}
          >
            <FaAngleRight size={23} />
          </Button>
        </Box>
      ) : (
        <Box>
           <img
            className="w-full h-full object-cover rounded-md"
            src={portrait || missing} // Try to load the portrait first, then missing
            alt="" // No alt text
            onError={(e) => {
              if (e.target.src !== missing) {
                // Prevents infinite loop
                e.target.src = missing; // Fallback to the missing image
              }
            }}
          />
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
