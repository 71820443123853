import SEO from "../../utils/SEO";
import usePayments from "../../hooks/usePayments";
import { Link, useParams } from "react-router-dom";
import { useBookings } from "../../hooks/useBookings";
import { useEffect, useState } from "react";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import InvoiceFileBooking from "./InvoiceFileBooking";
import InvoiceFilePayment from "./InvoiceFilePayment";
import { BlobProvider } from "@react-pdf/renderer";
import InvoiceFilePDF from "./InvoiceFilePDF";
import download from "../../iconos/download.png";
export default function InvoiceFile() {
  const { tour_op_id, invoice_id } = useParams();
  const { getBooking, booking } = useBookings();
  const { getPayments, payments, deletePayment } = usePayments();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [selectedPayment, setSelectedPayment] = useState(null);

  useEffect(() => {
    if (invoice_id) {
      getBooking({
        bookingID: invoice_id,
      });
      getPayments();
    }
  }, [invoice_id]);

  const paymentsByFile = payments.filter(
    (payment) => payment.booking_id === Number(invoice_id)
  );
  const openModal = (pack) => {
    setSelectedPayment(pack);
    toggleVisibility();
  };

  const handleDeletePackage = () => {
    deletePayment(selectedPayment.id).finally(() => {
      getPayments();
      toggleVisibility();
    });
  };

  return (
    <main className="flex flex-col mt-10 font-body text-darkBlue">
      <SEO title={`Factura ${invoice_id}`} />
      <header className="relative">
        <Link
          to={`/${tour_op_id}/panel/invoicing/my-invoices`}
          className="absolute right-0 top-0 button text-center bg-darkBlue font-medium text-white hover:bg-darkBlue/80 transition-colors"
        >
          Volver a Facturación
        </Link>
        <div className="flex justify-between items-center mt-20">
          <p className="border border-brightBlue bg-lightBlue2 rounded-md px-12 py-2 text-brightBlue">
            file:{" "}
            <span className="text-brightBlue font-body font-semibold">
              {invoice_id}
            </span>
          </p>
          {/* <button className="border border-brightBlue bg-lightBlue2 rounded-md py-2 px-4 transition-shadow hover:shadow-lg">
            Imprimir para agencia
          </button> */}
          <BlobProvider document={<InvoiceFilePDF pdfData={booking} />} >
            {({ url, loading }) =>
              loading ? (
                'Loading document...'
              ) : (
                <a href={url} download="detalle_de_facturacion.pdf" className="inline-flex m-2 border border-brightBlue bg-lightBlue2 rounded-md py-2 px-4 transition-shadow hover:shadow-lg">
                  Imprimir para agencia
                  <img src={download} alt="download" className="ml-2 h-8 py-2.5" />
                </a>
              )
            }
          </BlobProvider>
        </div>
      </header>

      <section className="mt-8 mb-12 flex justify-between py-4 px-24 bg-white rounded-md">
        <p>
          Comprobante:{" "}
          <span className="font-body font-semibold ml-1 uppercase">
            {tour_op_id}
          </span>
        </p>
        <p>
          Tipo:
          <span className="font-body font-semibold ml-1 text-red-500">
            CONSOLIDADORA A PESOS
          </span>
        </p>
        <p>
          Estado:
          <span className="font-body font-semibold ml-1 text-red-500">
            FACTURADO
          </span>
        </p>
        <p>
          Moneda:
          <span className="font-body font-semibold ml-1 text-red-500">ARS</span>
        </p>
      </section>

      <InvoiceFileBooking booking={booking} />

      <section className="mt-8 mb-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="font-semibold">COMPROBANTES</h2>
          <Link
            to={`/${tour_op_id}/panel/invoicing/add-payment`}
            className="button text-md text-center bg-darkBlue text-white hover:shadow-lg transition-all"
          >
            Agregar Pago
          </Link>
        </div>
        <div className="grid grid-cols-10 p-2 bg-white rounded-md">
          <span className="col-span-2" />
          <p className="border-l border-gray text-center font-medium">
            Cliente
          </p>
          <p className="border-l border-gray text-center font-medium col-span-2">
            Comprobante
          </p>
          <p className="border-l border-gray text-center font-medium">
            Emisión
          </p>
          <p className="border-l border-gray text-center font-medium">Debe</p>
          <p className="border-l border-gray text-center font-medium">Haber</p>
          <p className="border-l border-gray text-center font-medium">Saldo</p>
          <p className="border-l border-gray text-center font-medium">
            Detalles
          </p>
        </div>
        <p className="my-2">
          Moneda:
          <span className="font-body font-semibold ml-1 text-red-500">
            PESOS
          </span>
        </p>

        {paymentsByFile.length > 0 &&
          paymentsByFile.map((payment) => {
            return (
              <InvoiceFilePayment
                key={payment.id}
                payment={payment}
                openModal={openModal}
              />
            );
          })}

        <div className="grid grid-cols-10 p-2 mt-2 rounded-md bg-lightBlue">
          <div className="col-span-4" />
          <p className="col-span-2 text-right font-semibold text-red-500">
            Total moneda PESOS
          </p>
          <p className="text-center my-auto text-red-500">00,00.00</p>
          <p className="text-center my-auto text-red-500">00,00.00</p>
          <p className="text-center my-auto text-red-500">00,00.00</p>
        </div>
      </section>
      {/* )} */}

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        {selectedPayment && (
          <div className="flex flex-col items-center gap-2">
            <h2>
              ¿Estás seguro de eliminar <b>{selectedPayment.code}</b>?
            </h2>
            <div className="flex justify-center gap-2">
              <button
                className="py-1 px-2 rounded font-medium border border-gray2"
                onClick={toggleVisibility}
              >
                Cancelar
              </button>
              <button
                className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                onClick={handleDeletePackage}
              >
                Eliminar
              </button>
            </div>
          </div>
        )}
      </ModalGeneric>
    </main>
  );
}
