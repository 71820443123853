import React from 'react'
import KeyIcon from '@mui/icons-material/Key';

export default function CredentialsButton() {
  return (
    <button
          className="relative w-7 h-7 flex items-center justify-center cursor-pointer hover:bg-[#EEE] rounded-full transition-colors group/delete"
        //   onClick={() => {
        //     openModal(supplier);
        //     setSelectedModal("credentials");
        //   }}
        >
          <KeyIcon
            sx={{
              color: "#A0AFCD",
              height: "20px",
              width: "20px",
            }}
            alt="Credentials"
          />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/delete:scale-100">
            Credenciales
          </span>
        </button>
  )
}
