import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import useRulesFares from "../../../hooks/useRulesFares";
import useGetCurrencies from "../../../hooks/useGetCurrencies";





export default function UpdateFeesModal({ datas, closeModal }) {
    let [dataRulesFare, setDataRulesFare] = useState({});
    const { currencies } = useGetCurrencies();
    const { updateRulesFares } = useRulesFares();
    const specificCurrencyId = datas.currency_type_id;
    const monto = datas.rate_type;
    const specificCurrency = currencies.find(currency => currency.id === specificCurrencyId);
    
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resetOptions: {
            keepDirtyValues: true,
        },
        defaultValues: datas,
    });


    const onSubmit = async () => {
        updateRulesFares(datas.id, dataRulesFare).then(() => closeModal());
    };

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
    
        // Determine the value based on input type
        const newValue = type === 'checkbox' ? checked : value;
    
        setDataRulesFare(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)}
            onChange={handleChange}>
           <section className="flex flex-col gap-8">
                <header className="relative w-60 mx-auto flex items-center bg-lightBlue  justify-center border-0 rounded-3xl h-10">
                    <button
                        type="button"
                        className="px-4 rounded-3xl w-60 z-10"
                        onClick={() => {
                            reset();
                        }}
                    >
                        Regla de Negocio
                    </button>


                </header>

                <div className='grid grid-cols-2 gap-12'>
                    <div className='col-span-1' >
                        <div className="flex flex-col gap-2 relative mb-8">
                            <label className="font-medium w-[300px]">
                                Nombre
                            </label>
                            <input
                                placeholder={
                                    "Comisión de Regla, ..."
                                }
                                className="outline-none border-b border-[#CEDEF1]"
                                name="name"
                                {...register("name")}
                            />
                            <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                                <ErrorMessage errors={errors} name="name" as="p" />
                            </div>
                        </div>
                        
                        <div className='mb-8'>
                            <label className="font-medium w-[300px]">Monto</label>
                            <div className="flex items-center  gap-2 relative">
                                <div>
                                    <select
                                        className="addPackInput w-30"
                                        name="rate_type"
                                        {...register("rate_type")}
                                    >
                                        <option hidden  disabled value={monto}>{monto}</option>
                                        <option value={"porcentaje"}>porcentaje </option>
                                        <option value={"fijo "}>fijo </option>
                                    </select>
                                </div>
                                <div className=''>
                                    <input
                                     type="number"
                                        placeholder={
                                            "Valor, ..."
                                        }
                                        className="outline-none border-b border-[#CEDEF1]"
                                        name="number"
                                        {...register("number")}
                                    />
                                    <div className="flex flex-col gap-2 relative">


                                        <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                                            <ErrorMessage errors={errors} name="name" as="p" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-8'>
                            <label className="font-medium w-[300px]">Moneda</label>

                            <select
                                name="currency_type_id"    {...register("currency_type_id", { valueAsNumber: true })}
                                id="currency_type_id"
                                className="addPackInput w-full"
                            >
                                <option hidden  disabled value={specificCurrencyId}>{specificCurrency?.name} ({specificCurrency?.symbol}) </option>
                                {currencies?.map(({ name, symbol, id }) => {
                                    return (
                                        <option key={`${id}`} value={id} >
                                            {name} ({symbol})
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                      
                        <div className="flex flex-col gap-2 relative mb-8">
                            <label className="font-medium w-[300px]">Tipo</label>
                            <select
                                className="addPackInput w-full"
                                name="percentage"
                                {...register("operation_fiscal")}
                            >
                                <option value={"impuesto"}>impuesto</option>
                                <option value={"comisión"}>comisión </option>
                                <option value={"descuento"}>descuento </option>
                            </select>
                            <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                                <ErrorMessage errors={errors} name="operation_fiscal" as="p" />
                            </div>
                        </div>

                    </div>
                    <div className='col-span-1'>
                        <div className="flex flex-col gap-2 relative mb-8">
                            <label className="font-medium w-[300px]">Operación</label>
                            <select
                                className="addPackInput w-full"
                                name="operation"
                                {...register("operation")}
                            >
                                <option value={"suma"}>suma</option>
                                <option value={"resta"}>resta </option>
                            </select>

                            <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                                <ErrorMessage errors={errors} name="operation" as="p" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 relative mb-8">
                            <label className="font-medium w-[300px]">Sobre el</label>
                            <select
                                className="addPackInput w-full"
                                name="variable"
                                {...register("variable")}


                            >
                                <option value={"neto"}>neto </option>
                                <option value={"bruto"}>bruto </option>
                            </select>

                            <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                                <ErrorMessage errors={errors} name="variable" as="p" />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 relative mb-8">
                            <label className="font-medium w-[300px]">Producto</label>
                            <select
                                className="addPackInput w-full"
                                name="product"
                                {...register("product")}
                            >
                                <option value={"vuelos"}>vuelos  </option>
                                <option value={"hoteles"}>hoteles  </option>
                                <option value={"paquetes"}>paquetes   </option>
                                <option value={"asistencia"}>asistencia    </option>
                                <option value={"autos"}>autos   </option>
                                <option value={"crucero"}>crucero   </option>
                            </select>
                            <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                                <ErrorMessage errors={errors} name="product" as="p" />
                            </div>
                        </div>
                        <div className='grid grid-cols-2'>
                            <div className="flex flex-col gap-2 ">
                                <label className="font-medium w-[300px]">Activo</label>
                                <input
                                    className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                    type="checkbox"
                                    {...register("active", { valueAsBoolean: true })}
                                    id="checkbox-active"
                                />
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <label className="font-medium w-[300px]">Única Regla</label>
                                <input
                                    className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                    type="checkbox"
                                    {...register("running", { valueAsBoolean: true })}
                                    id="checkbox-running"
                                />
                            </div>
                        </div>
                        
                    </div>

                </div>










            </section>

            <section className="flex items-center justify-end mt-4">
                <button
                    type="submit"
                    className="px-6 py-4 rounded bg-brightBlue text-white"
                >
                    Cargar
                </button>
            </section>
        </form>
    )
}
