const translateCabin = (cabin) => {
    if (cabin === "ECONOMY") return "ECO";
    return "EJE";
  };
  
  const handleDisplay = (title) => {
    const body = document.getElementById(`${title}-body`);
    const arrow = document.getElementById(`${title}-arrow`);
  
    body.classList.toggle("scale-y-0");
    body.classList.toggle("h-0");
    body.classList.toggle("p-4");
    arrow.classList.toggle("-rotate-180");
    arrow.classList.toggle("rotate-0");
  };

  export { translateCabin, handleDisplay}