import Head from "./head";
import Opcionvuelo from "./opcionvuelo";
import { useGetFlights } from "../hooks/useGetFlights";
import SearchFilters from "./SearchFilters";
import { useEffect, useState } from "react";
import SEO from "../utils/SEO";
import { format } from "date-fns";
import "../styles/vuelos.css";
import useGetAirports from "../hooks/useGetAirports";
import { useGetAirlines } from "../hooks/useGetAirlines";
import useCommissionsAndTaxes from "../hooks/useCommissionsAndTaxes";
import { saveExchangeRate } from "../utils/saveExchangeRate";
import { useLocationQuery } from "../hooks/useLocationQuery";
import SkeletonsFlightSearch from "../utils/SkeletonsFlightSearch";
import Footer from "./home/footer/Footer";

export const translatedDuration = (time) => {
  const splitTime =
    time &&
    time
      .split("H")
      .map((el) => {
        const regex = /\d+/g;
        const numbers = el.match(regex);

        return numbers ? numbers.map(Number) : null;
      })
      .flat();

  return splitTime?.[0] + splitTime?.[1] / 100;
};

export const translateToDec = (time) => {
  const inter = Math.floor(time);
  const float = time % 1;

  return inter + float * 1.66666666;
};

export default function Vuelos() {
  const { flights, original, loading } = useGetFlights();
  const { commissions, getCommissions } = useCommissionsAndTaxes();
  const { getAirports, airports } = useGetAirports();
  const { getAirlines, airlines } = useGetAirlines();
  const [ciudadesFormatted, setCiudadesFormatted] = useState({});
  const [airlinesFormatted, setAirlinesFormatted] = useState({});
  const exchangeRate = saveExchangeRate((st) => st.exchangeRate);
  const { parsedHash } = useLocationQuery();
  const hasReturnFlight = parsedHash?.hasOwnProperty("returnDate");

  useEffect(() => {
    getAirlines();
    getAirports();
    getCommissions();
  }, []);

  useEffect(() => {
    if (airports) {
      setCiudadesFormatted(() => {
        let formattedCities = {};
        airports.forEach((airport) => {
          formattedCities[airport.iata] = {
            city: airport.City.name,
            airport: airport.name,
          };
        });
        return formattedCities;
      });
    }
  }, [airports]);

  useEffect(() => {
    if (airlines.length > 0) {
      setAirlinesFormatted(() => {
        let formattedAirlines = {};
        airlines.forEach((airline) => {
          formattedAirlines[airline.code] = airline;
        });
        return formattedAirlines;
      });
    }
  }, [airlines]);

  let originalOptions = flights && Object.entries(flights || {});

  const getIncludedBags = () => {
    const includedBags = {};
    original.forEach((pack) => {
      return pack?.travelerPricings?.[0]?.fareDetailsBySegment?.forEach(
        (fl) => {
          const { segmentId, includedCheckedBags } = fl;

          includedBags[segmentId] = includedCheckedBags?.quantity;
        }
      );
    });

    return includedBags;
  };

  const includedBagsByFlight = original && getIncludedBags();

  let minFlightDuration = Infinity;
  let maxFlightDuration = 0;
  const getMaxDurationFlight = () => {
    originalOptions?.forEach((flight) => {
      const origen = translatedDuration(flight?.[1]?.origin?.[0]?.duration);
      const destino = translatedDuration(
        flight?.[1]?.destination?.[0]?.duration
      );
      if (origen > maxFlightDuration) maxFlightDuration = origen;
      if (destino > maxFlightDuration) maxFlightDuration = destino;
      if (origen < minFlightDuration) minFlightDuration = origen;
      if (destino < minFlightDuration) minFlightDuration = destino;
    });
  };

  let allAirlines = {};
  const getCarrierCode = (flights) => {
    flights.map((flight) => {
      return flight.segments.forEach((el) => {
        if (!allAirlines.hasOwnProperty(el.carrierCode)) {
          allAirlines[el.carrierCode] = false;
        }
      });
    });
  };

  originalOptions &&
    originalOptions.forEach((opt) => {
      getCarrierCode(opt[1].origin);
      hasReturnFlight && getCarrierCode(opt[1].destination);
    });

  const [filters, setFilters] = useState({
    airlines: { AA: false },
    scales: {
      0: false,
      1: false,
      2: false,
    },
    maxDuration: 0,
    includedBags: {
      notIncluded: false,
      included: false,
    },
    departureTime: {
      from: "",
      to: "",
    },
    arrivalTime: {
      from: "",
      to: "",
    },
  });

  getMaxDurationFlight();
  useEffect(() => {
    setFilters((curr) => {
      return {
        ...curr,
        airlines: allAirlines,
        maxDuration: translateToDec(maxFlightDuration),
      };
    });
  }, [loading]);

  const filterOptions = (options) => {
    if (loading) return options;
    if (flights === undefined || original === undefined) return options;

    const {
      airlines,
      scales,
      maxDuration,
      includedBags,
      departureTime,
      arrivalTime,
    } = filters;

    const checkedAirlines = Object.keys(airlines).filter((el) => airlines[el]);
    const checkedScales = Object.keys(scales).filter((el) => scales[el]);
    const checkedBags = Object.keys(includedBags)
      .filter((bag) => includedBags[bag])
      .map((el) => (el === "notIncluded" ? 0 : 1));

    const filterSegments = (data, timeType) => {
      return data
        .filter((el) => {
          // AIRLINES
          if (checkedAirlines.length === 0) return el;

          const arrCarrierCodes = el.segments.filter((x) =>
            checkedAirlines.includes(x.carrierCode)
          );
          return arrCarrierCodes.length > 0;
        })
        .filter((el) => {
          // SCALES
          if (checkedScales.length === 0) return el;
          return Number(checkedScales.at(-1)) >= el.segments.length - 1;
        })
        .filter((el) => {
          // BAGS
          if (checkedBags.length === 0) return el;
          const arrBags = el.segments.filter((x) => {
            const thisFlightBags = includedBagsByFlight[x.id] === 0 ? 0 : 1;
            return checkedBags.includes(thisFlightBags);
          });
          return arrBags.length > 0;
        })
        .filter((el) => {
          // DEPARTURE TIMES
          if (!timeType.from || !timeType.to) return el;
          const time = format(
            new Date(el?.segments?.[0]?.departure?.at),
            "HH:mm"
          );

          return time >= timeType.from && time <= timeType.to;
        })
        .filter((el, index) => {
          // FLIGHT DURATION
          return index !== 0
            ? true
            : translatedDuration(el?.duration) <= maxDuration;
        });
    };

    return (
      options &&
      options
        .map((opt) => {
          const { origin, destination } = opt[1];

          const filteredOrigen = filterSegments(origin, departureTime);
          const filteredDestino =
            hasReturnFlight && filterSegments(destination, arrivalTime);

          if (hasReturnFlight) {
            if (filteredOrigen.length > 0 && filteredDestino.length > 0) {
              return [
                opt[0],
                { origin: filteredOrigen, destination: filteredDestino },
              ];
            }
          } else if (filteredOrigen.length > 0) {
            return [opt[0], { origin: filteredOrigen }];
          }

          return null;
        })
        .filter((el) => el)
    );
  };

  const options = filterOptions(originalOptions);

  if (loading) {
    return (
      <div>
        <Head />
        <div className="space-y-4 p-4">
          <SkeletonsFlightSearch/>
        </div>
      </div>
    );
  }

  if (
    flights !== undefined &&
    original !== undefined &&
    options !== undefined
  ) {
    return (
      <div>
        <SEO title={"Vuelos"} />
        <Head />
        <div className="mx-auto flex gap-10 my-10 max-w-[1338.46px]">
          <div className="flex flex-col gap-4">
            {/* <BuscadorPaquetes /> */}
            <SearchFilters
              filters={filters}
              setFilters={setFilters}
              minmaxDuration={[
                translateToDec(minFlightDuration),
                translateToDec(maxFlightDuration),
              ]}
            />
          </div>
          <div className="w-full flex flex-col gap-8">
            {options.length === 0 ? (
              <p>
                No se encontraron vuelos acordes a los filtros seleccionados
              </p>
            ) : (
              options?.map(([grouped, { origin, destination }], index) => (
                <Opcionvuelo
                  key={index}
                  airlinesFormatted={airlinesFormatted}
                  ciudadesFormatted={ciudadesFormatted}
                  commissions={commissions}
                  destino={destination || false}
                  exchangeRate={exchangeRate}
                  origen={origin}
                  original={original}
                  totalPrice={grouped}
                />
              ))
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Head />
        <div className="mx-auto flex gap-10 my-10 max-w-[1338.46px] w-full">
          {/* <BuscadorPaquetes /> */}
          <h1>No se encontraron vuelos</h1>
        </div>
        <Footer />
      </div>
    );
  }
}
