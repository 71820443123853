import React, { useState, useMemo } from "react";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

// Removes duplicates from an array
const removeDuplicates = (array) => [...new Set(array)];

// Capitalizes the first letter of each word
const capitalizeFirstLetterOfEachWord = (string) => {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Converts a formatted date back to an ISO format for sorting
const convertFormattedDateToISO = (formattedDate, dateMapping) => {
  const originalDate = dateMapping[formattedDate];
  return originalDate ? parseISO(originalDate) : null;
};

// Formats the date
const formatTheDate = (dateString) => {
  const date = parseISO(dateString); // Convert the ISO date string to a Date object
  let formattedDate = format(date, "MMMM yyyy", { locale: es }); // Format date to 'MMMM yyyy'

  formattedDate = capitalizeFirstLetterOfEachWord(formattedDate); // Capitalize the first letter
  return formattedDate;
};

// Sorts dates from closest to furthest
const sortDates = (dates, dateMapping) => {
  return dates.sort((a, b) => {
    const dateA = convertFormattedDateToISO(a, dateMapping);
    const dateB = convertFormattedDateToISO(b, dateMapping);

    return dateA && dateB ? dateA - dateB : 0; // Compare the parsed Date objects directly
  });
};

// Custom Paper component for dropdown
const CustomPaper = (props) => {
  return (
    <Paper
      {...props}
      sx={{
        ...props.sx,
        width: "200px", // Set your desired width here
        position: "absolute", // Positioning for centering
        left: "50%", // Centering horizontally
        transform: "translateX(-50%)", // Centering offset
        zIndex: 1, // Ensure it appears above other elements
      }}
    />
  );
};

export default function AutoCompleteDate({
  options,
  handleChange,
  name,
  setValue, // Use setValue from React Hook Form
  placeholder,
  valueName,
  bgColor,
}) {
  const [inputValue, setInputValue] = useState(""); // Input value for search
  const [selectedValue, setSelectedValue] = useState(null); // Store the selected value to avoid changes during typing

  // Map to store the formatted date as the key and the original date string as the value
  const dateMapping = {};

  // Format the options and remove duplicates
  const formattedOptions = options.map((dateString) => {
    const formattedDate = formatTheDate(dateString);
    dateMapping[formattedDate] = dateString; // Map the formatted date to the original
    return formattedDate;
  });

  // Sort the unique formatted dates
  const uniqueFormattedOptions = useMemo(
    () => sortDates(removeDuplicates(formattedOptions), dateMapping),
    [formattedOptions]
  );

  const handleSelect = (event, value) => {
    if (value) {
      const originalValue = dateMapping[value] || value;
      setSelectedValue(originalValue); // Store the original value
      setInputValue(value); // Set the input value to the formatted option
      handleChange(originalValue, valueName); // Pass the original value to handleChange
      setValue(name, value); // Register the value with React Hook Form
    } else {
      setSelectedValue(null); // Clear the selection if nothing is selected
      setValue(name, null); // Clear form value if no option is selected
    }
  };

  return (
    <Autocomplete
      options={uniqueFormattedOptions}
      getOptionLabel={(option) => option}
      inputValue={inputValue}
      freeSolo={false} // Prevent free text input
      onInputChange={(event, newInputValue) => {
        // Only update inputValue if the user hasn't already selected an option
        if (!selectedValue) {
          setInputValue(newInputValue); // Update inputValue without changing the registered value
        }
      }}
      onChange={handleSelect} // Handle the selection of the option
      PaperComponent={CustomPaper} // Use the custom Paper component for dropdown
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "4px",
              borderRadius: "8px",
              backgroundColor: bgColor,
              "& fieldset": {
                border: "none",
              },
            },
            "& .MuiInputBase-input": {
              padding: "4px 8px",
              fontSize: "14px",
              height: "24px",
            },
          }}
        />
      )}
    />
  );
}
