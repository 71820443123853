import React from "react";
import SEO from "../../../utils/SEO";
import Head from "../../head";
import PackageDetailSection from "./detailSections/PackageDetailSection";

export default function PackageDetail() {
  return (
    <div>
      <SEO title={"Paquetes"} />
      <Head />
      <div className="flex justify-center">
        <PackageDetailSection />
      </div>
    </div>
  );
}
