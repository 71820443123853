import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";

export default function RecommendationCardFlight({ image, title, descode }) {
  const { tour_op_id } = useParams();

  const getFormattedDate = (offset) => {
    const today = new Date();
    today.setDate(today.getDate() + offset);

    return format(today, "yyyy-MM-dd");
  };

  const fsalida = getFormattedDate(14);
  const fvuelta = getFormattedDate(21);

  const href = `/${tour_op_id}/search-flight?originLocationCode=COR&destinationLocationCode=${descode}&departureDate=${fsalida}&returnDate=${fvuelta}&adults=1`;

  return (
    <Link
      to={href}
      className="rounded-2xl bg-[#EFEFEF] px-2 pt-2 pb-14 relative shadow-[0px_8.111881256103516px_12.16782283782959px_4.055940628051758px_#00000026] hover:scale-105 transition-transform block"
    >
      <div className="w-[220px]">
        <img className="w-full rounded-xl h-[180px]" src={image} alt={title} />
      </div>
      <div className="bg-white flex items-center py-2 justify-center rounded-b-2xl absolute bottom-0 w-full left-0">
        <p className="font-medium text-[#242423]">{title}</p>
      </div>
    </Link>
  );
}
