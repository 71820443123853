import React from "react";
import { Link } from "react-router-dom";
import MenuLink from "./MenuLink";
import help from "../../iconos/help.svg";
import test_user from "../../iconos/test_user.png";

export default function UserPanel({
  isHovered,
  tourOP,
  userData,
  location,
  tour_op_id,
}) {
  return (
    <div className=" flex flex-col justify-between ">
      <ul className="flex flex-col gap-8">
        {tourOP?.logo?.length > 2 && (
          <li>
            <img
              src={tourOP?.logo}
              alt={tourOP?.name}
              className="max-h-12 mx-auto"
            />
          </li>
        )}
        <li className="flex flex-col gap-8">
          <MenuLink
            link={"config/faq"}
            label={"Ayuda"}
            icon={help}
            isHovered={isHovered}
          />
          {/* <MenuLink link={"config"} label={"Perfil"} icon={config} /> */}
        </li>
        <li className="flex flex-col gap-1">
          <p className="text-xs text-[#333533] ml-5">V1.1</p>
          <div className="w-full h-[1px] my-1 mt-3 mb-6 bg-[#CECECE]" />
        </li>
      </ul>
      {/* user */}
      <div className="flex h-[52px] gap-3 mt-4 group/options ">
        <img
          src={test_user}
          alt="user_image"
          className="w-[56px] h-[52px] -mt-6  left-4 bottom-8"
        />
        <p
          className={`grid items-center text-xl font-medium transition-[width] duration-300 ease-in-out origin-left -mt-14  ${
            isHovered ? "scale-x-100" : "scale-x-0"
          }`}
        >
          {userData?.username || "Usuario"}
        </p>
        {/* <div className=" absolute rounded-lg p-4 pl-3 bg-white shadow-md scale-0 left-[68px] bottom-4 grid transition-transform group-hover/options:scale-100 z-panel">
          {tour_op_id === "walltrip" && (
            <Link
              to={"profile/admin"}
              className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                location.pathname.includes("profile/admin")
                  ? "border-l border-black"
                  : "border-l border-transparent hover:border-gray/50"
              }`}
            >
              Operadoras
            </Link>
          )}
          <Link
            to={"profile/my-tour-operator"}
            className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
              location.pathname.includes("my-tour-operator")
                ? "border-l border-black"
                : "border-l border-transparent hover:border-gray/50"
            }`}
          >
            Operadora
          </Link>
          {userData?.agency_id !== 1 && (
            <Link
              to={"profile/my-agency"}
              className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                location.pathname.includes("my-agency")
                  ? "border-l border-black"
                  : "border-l border-transparent hover:border-gray/50"
              }`}
            >
              Agencia
            </Link>
          )}
          <Link
            to={"profile/fees"}
            className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
              location.pathname.includes("fees")
                ? "border-l border-black"
                : "border-l border-transparent hover:border-gray/50"
            }`}
          >
            Fees & Comisiones
          </Link>
          {userData?.agency_id === 1 && (
            <Link
              to={"profile/agencies"}
              className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                location.pathname.includes("agencies")
                  ? "border-l border-black"
                  : "border-l border-transparent hover:border-gray/50"
              }`}
            >
              Agencias
            </Link>
          )}
          <Link
            to={"profile/users"}
            className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
              location.pathname.includes("users")
                ? "border-l border-black"
                : "border-l border-transparent hover:border-gray/50"
            }`}
          >
            Usuarios
          </Link>
          {/* <Link
          to={"profile/design"}
          className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
            location.pathname.includes("design")
              ? "border-l border-black"
              : "border-l border-transparent hover:border-gray/50"
          }`}
        >
          Diseño Web
        </Link> 
        </div> */}
      </div>
    </div>
  );
}
