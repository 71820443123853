import { Link, useParams } from "react-router-dom";
import sun from "../../iconos/sun.png";
import missing from "../../iconos/missing.jpg";
// import moon from "../../iconos/moon.png";
import iconoLuna from "../../iconos/moon_.svg";
import IconInfo from "../../iconos/info.png";
import calendar from "../../iconos/icono_calendar.png";
import { format } from "date-fns";

export default function RecommendationCardPackages({ pack, currencies }) {
  const { tour_op_id } = useParams();
  const currentDate = new Date();
  const { title, portrait, tours, currency_type_id } = pack;

  // Finc closest tour
  const closestTour =
    tours.length > 0
      ? tours.reduce((closest, tour) => {
          const tourDate = new Date(tour.dateIn); // Convert "YYYY-MM-DD" string to a Date object
          const closestDate = new Date(closest.dateIn);

          return Math.abs(tourDate - currentDate) <
            Math.abs(closestDate - currentDate)
            ? tour
            : closest;
        }, tours[0])
      : null;
  const fees = closestTour?.fees || [];
  const minFee =
    fees.length > 0
      ? fees.reduce((min, fee) => (fee.price < min.price ? fee : min), fees[0])
      : null;

  const href = `/${tour_op_id}/package-detail/`;
  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <Link
      state={{ packageData: pack }}
      to={href}
      className=" rounded-[20px] bg-[#EFEFEF] max-w-[250px] md:p-2 md:shadow-[0px_8.111881256103516px_12.16782283782959px_4.055940628051758px_#00000026] relative hover:scale-105 transition-transform border-[#B8B8B8] hover:border-px hover:border-black"
    >
      <section className="mb:border-2 mb:border-lightGray3 md:border-0 bg-[#EFEFEF] rounded-[20px] w-full text-center flex flex-col h-full">
        <picture className="w-[220px]">
          <img
            className="w-full rounded-[20px] h-[190px]"
            src={portrait || missing} // Try to load the portrait first, then missing
            alt="" // No alt text
            onError={(e) => {
              if (e.target.src !== missing) {
                // Prevents infinite loop
                e.target.src = missing; // Fallback to the missing image
              }
            }}
          />
        </picture>
        <section className="p-2 flex flex-col gap-2 justify-between mt-2 flex-grow">
          <section className="bg-[#EFEFEF] flex flex-col items-center justify-center w-full pb-2">
            <h2 className="font-bold text-xl text-[#242423]">
              {title?.toUpperCase()}
            </h2>
          </section>
          <section className="relative flex grid grid-cols-2 items-center justify-between">
            <div className="flex gap-4 flex-col items-center ">
              <div>
                <div className="flex-inline flex items-center gap-1">
                  <img
                    src={calendar}
                    alt="icono_calendar"
                    className="w-[12px] h-[12px] p-0 flex-inline flex"
                  />
                  <p className="flex text-ms">Salida:</p>
                </div>
                <p className="flex flex-row font-bold text-sm">
                  {format(new Date(closestTour.dateIn), "dd-MM-yyyy")}
                </p>
              </div>

              <div>
                {closestTour.dateOut && (
                  <>
                    <div className="flex-inline flex items-center gap-1">
                      <img
                        src={calendar}
                        alt="icono_calendar"
                        className="w-[12px] h-[12px] p-0 flex-inline flex"
                      />
                      <p className="flex text-ms">Regreso:</p>
                    </div>

                    <p className="font-bold text-sm">
                      {format(new Date(closestTour.dateOut), "dd-MM-yyyy")}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8 items-center">
              {closestTour.days > 0 && (
                <span className="relative bg-[#FAF2AE] h-7 flex justify-end rounded-full py-1 px-4 w-[60px]">
                  <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-2 bg-[#EAD40B]">
                    <img src={sun} alt="sun" className="w-4" />
                  </span>
                  <p className="bottom-1.5 w-2 font-bold text-ms">
                    {closestTour.days}
                  </p>
                </span>
              )}
              {closestTour.nights > 0 && (
                <span className="relative bg-[#1E0F5033] h-7 flex justify-end rounded-full py-1 px-4 w-[60px]">
                  <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-2 bg-[#091832]">
                    <img src={iconoLuna} alt="moon" className="w-4" />
                  </span>
                  <p className="bottom-1.5 w-2 font-bold text-ms">
                    {closestTour.nights}
                  </p>
                </span>
              )}
            </div>
          </section>
        </section>
        <section className="bg-white hover:bg-[#091832] text-center rounded-[15px] w-full p-2 mt-2 border-[#B8B8B8] hover:border-px hover:border-solid hover:text-white mt-auto">
          <p className="font-light text-base">Precio por persona:</p>
          <div className="flex flex-row items-center justify-center">
            <img
              className="h-[10px] w-[10px] mr-[5px] justify-center"
              src={IconInfo}
              alt="img-info"
            />
            <p className="text-[14px] font-bold">
              {`Desde 
              ${translateCurrency(currency_type_id) ?? "USD"}${" "}
              ${Number(minFee.price) + Number(minFee.tax)}`}
            </p>
          </div>
        </section>
      </section>
    </Link>
  );
}
