import { useCallback, useState } from "react";
import axios from "axios";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export const useQuotes = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [loading, setLoading] = useState(false);
  const [individualQuote, setQuote] = useState([]);
  const [quotes, setQuotes] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");
  const headers = {
    headers: { Authorization: `${token}` },
  };

  // const querys = QueryString.stringify(
  //   _.omitBy({ ...parsedHash }, (a) => a !== 0 && !a)
  // ).replace(/\s/g, "");

  const createQuote = async (quote) => {
    const response = await axios.post(BASE_URL + "v2/quotes", quote, headers);
    return response;
  };

  const updateQuote = async (quote) => {
    const { id, fechaCreacion, ...restOfQuote } = quote;
    return await axios.patch(
      BASE_URL + `v2/quotes/${id}`,
      {
        quote: { ...restOfQuote },
      },
      headers
    );
  };

  const deleteQuote = async (id) => {
    return await axios.delete(BASE_URL + `v2/quotes/${id}`, headers);
  };

  const getQuotes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}v2/quotes/${tourOP.id}`);
      setQuotes(response.data.response);
    } catch (error) {
      console.error(error); // Handle error
    } finally {
      setLoading(false);
    }
  }, [tourOP.id, BASE_URL]);

  const getQuote = async (quote_id) => {
    setLoading(true);

    axios
      .get(BASE_URL + "v2/quotes/detail/" + quote_id)
      .then((e) => {
        setQuote(e.data.response);
      })
      // .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return {
    quotes,
    individualQuote,
    loading,
    getQuote,
    getQuotes,
    deleteQuote,
    createQuote,
    updateQuote,
  };
};
