import React from "react";

export default function TableDateFiltering({ setFilters, filters }) {
  const handleFilters = (e) => {
    const { name, value } = e.target;

    setFilters((curr) => {
      return {
        ...curr,
        [name]: value,
      };
    });
  };

  const handleRadioButtons = (e) => {
    setFilters((curr) => {
      return {
        ...curr,
        radioDate: e.target.name,
      };
    });
  };
  return (
    <div className="flex items-center gap-4 flex-col lg:flex-row">
      <div className="flex gap-2 flex-col lg:flex-row mt-4 lg:mt-0">
        <div className="inline-flex gap-6">
          <label
            htmlFor="creation"
            className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
          >
            <input
              type="radio"
              checked={filters.radioDate === "creation"}
              name="creation"
              id="creation"
              onChange={handleRadioButtons}
            />
            <p>de Creación</p>
          </label>
          <label
            htmlFor="departure"
            className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
          >
            <input
              type="radio"
              checked={filters.radioDate === "departure"}
              name="departure"
              id="departure"
              onChange={handleRadioButtons}
            />
            <p>de Salida</p>
          </label>
          {/* <label
            htmlFor="emition"
            className="text-sm flex gap-1 items-center mb:flex-col lg:flex-row"
          >
            <input
              type="radio"
              checked={filters.radioDate === "emition"}
              name="emition"
              id="emition"
              onChange={handleRadioButtons}
            />
            <p>Lím. Emisión</p>
          </label> */}
        </div>

        <input
          type="date"
          name="inputDate"
          value={filters.inputDate}
          onChange={handleFilters}
          placeholder="Elegir Fecha"
          className="px-3 py-1 border-2 border-gray2 rounded-md h-10"
        />
      </div>
    </div>
  );
}
