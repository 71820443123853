import { format } from "date-fns";
import moon from "../../iconos/moon2.png";
import sun from "../../iconos/sun2.png";
import { useTours } from "../../hooks/useTours";
import { useEffect } from "react";
export default function PackageRow({ pack }) {
  const {
    origin_code,
    destination_code,
    departure_date,
    return_date,
    portrait,
    fallback,
    description,
    id,
  } = pack ?? {};
  const { getTours, tours } = useTours();
  const nights =
    (new Date(return_date) - new Date(departure_date)) / 1000 / 60 / 60 / 24;
  useEffect(() => {
    getTours(id);
  }, []);

  return (
    <>
      <div className="w-full flex items-start border-r-8 border-lightGray h-full">
        <div className="flex-none w-1/5 flex flex-col items-start border-r-2 border-lightGray">
          <img
            className="w-full h-1/2 object-cover rounded-sm"
            src={portrait ?? fallback}
            alt="paquete_imagen"
          />
          {/* <div className="flex gap-4 mt-2">
            <span className="relative bg-[#FAF2AE] h-7 flex justify-end rounded-full py-1 px-4 w-[80px]">
              <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#EAD40B]">
                <img src={sun} alt="sun" className="w-4" />
              </span>
              <p className="bottom-1.5 w-2">{nights + 1}</p>
            </span>
            <span className="relative bg-[#1E0F5033] h-7 flex justify-end rounded-full py-1 px-4 w-[80px]">
              <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#091832]">
                <img src={moon} alt="moon" className="w-4" />
              </span>
              <p className="bottom-1.5 w-2">{nights}</p>
            </span>
          </div> */}
        </div>
        <div className="h-full flex justify-center items-center p-2">
          <p>{description}</p>
        </div>
        <div className="flex-1 flex-col">
          <table className="table-auto w-full rounded">
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-r-2 border-l-2  p-2 ">{origin_code}</td>
                <td className="border-r-2  p-2 ">{destination_code}</td>
              </tr>
            </tbody>
          </table>

          {/* <table className="table-auto w-full rounded">
            <thead>
              <tr>
                <th>Fecha de Salida</th>
                <th>Fecha de Regreso</th>
                <th>Dias</th> {/* Add more columns as necessary 
              </tr>
            </thead>
            <tbody>
              {tours.map((tour) => (
                <tr key={tour.id}>
                 
               
                  <td className="border-r-2 border-l-2  p-2 ">
                    {tour.dateIn
                      ? format(new Date(tour.dateIn), "dd/MM/yyyy")
                      : "-"}
                  </td>
                  <td className="border-r-2 border-l-2  p-2 ">
                    {tour.dateOut
                      ? format(new Date(tour.dateOut), "dd/MM/yyyy")
                      : "-"}
                  </td>
                  <td>
                    <div className="flex gap-4 mt-2">
                      <span className="relative bg-[#FAF2AE] h-7 flex justify-end rounded-full py-1 px-4 w-[80px]">
                        <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#EAD40B]">
                          <img src={sun} alt="sun" className="w-4" />
                        </span>
                        <p className="bottom-1.5 w-2">{tour.nights + 1}</p>
                      </span>
                      <span className="relative bg-[#1E0F5033] h-7 flex justify-end rounded-full py-1 px-4 w-[80px]">
                        <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#091832]">
                          <img src={moon} alt="moon" className="w-4" />
                        </span>
                        <p className="bottom-1.5 w-2">{tour.nights}</p>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>
    </>
  );
}
