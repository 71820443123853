import { useState, useEffect } from 'react';

const useMobileDetector = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const mobileQuery = window.matchMedia("(max-width: 768px)");
      setIsMobile(mobileQuery.matches);
    };

    checkMobile();  //the first check
    window.addEventListener("resize", checkMobile);  //For when the screen rezises 

    return () => window.removeEventListener("resize", checkMobile); 
  }, []);

  return isMobile;
};

export default useMobileDetector;