import React from "react";
import TourRowAccionButtons from "./TourRowAccionButtons";
import FeeRow from "../feeRow/FeeRow";
import { ModalGeneric } from "../../../../../../utils/ModalGeneric";
import TourView from "./TourView";
import { useToggleVisibility } from "../../../../../../hooks/useToggleVisibility";
import { format } from "date-fns";

export default function TourRowTableBody({
  isFeeVisible,
  setIsFeeVisible,
  tour,
  index,
  package_id

}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  //const rowColor = index % 2 === 0 ? "bg-white" : "bg-lightGray2";
  return (
    <>
      <tr
        index={index}
        className={` text-center bg-white hover:bg-lightGray border-2 border-lightBlue`}
      >
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue  p-2"
        >
          {index + 1}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {format(new Date(tour.dateOut), "dd/MM/yyyy ")}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.hotels}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.category}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.meal_plan}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.days}
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.nights}
        </td>
        <td onClick={toggleVisibility} className=" p-2">
          <div className="inline-flex">
            {tour.stock}/{tour.original_stock}
            {tour.availability === "YES" ? (
              <div className="bg-red-500 rounded-full p-2 ml-4"></div>
            ) : (
              <>
                <div className="bg-green-500 rounded-full p-2 ml-4"></div>
              </>
            )}
          </div>
        </td>
        <td
          onClick={toggleVisibility}
          className="border-2 border-lightBlue p-2"
        >
          {tour.stock}
        </td>
        <td className=" p-2 flex justify-end">
          <TourRowAccionButtons
            setVisible={setIsFeeVisible}
            isVisible={isFeeVisible}
            data={tour}
          />
        </td>
      </tr>

      {isFeeVisible && (
        <tr className="rounded-lg">
          <td colSpan="11">
            <FeeRow tour={tour} package_id={package_id} />
          </td>
        </tr>
      )}

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <TourView data={tour} />
      </ModalGeneric>
    </>
  );
}
