import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ErrorMessage } from "@hookform/error-message";

import useGetCurrencies from "../../hooks/useGetCurrencies";

const commissionSchema = z.object({
  name: z.string().min(1, { message: "Campo Requerido" }),
  operation_fiscal: z.string().min(1, { message: "Campo Requerido" }),
  rate_type: z.string().min(1, { message: "Campo Requerido" }),
  number: z.string().min(1, { message: "Campo Requerido" }),
  operation: z.string().min(1, { message: "Campo Requerido" }),
  variable: z.string().min(1, { message: "Campo Requerido" }),
  product: z.string().min(1, { message: "Campo Requerido" }),
  active: z.boolean(),
  running: z.boolean(),
  currency_type_id: z.number().min(1, { message: "Campo Requerido" }),
  // percentage: z
  //     .string()
  //     .min(1, { message: "Campo Requerido" })
  //     .regex(/^[+]?\d+([.]\d+)?$/, { message: "El valor debe ser numérico" }),
  // service: z.string().min(1, { message: "Campo Requerido" }),
});

export default function FormAddRulesFares({ onSubmit }) {
  const { currencies } = useGetCurrencies();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(commissionSchema),
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <section className="flex flex-col gap-8">
        <header className="relative w-60 mx-auto flex items-center bg-lightBlue  justify-center border-0 rounded-3xl h-10">
          <button
            type="button"
            className="px-4 rounded-3xl w-60 z-10"
            onClick={() => {
              reset();
            }}
          >
            Regla de Negocio
          </button>
        </header>

        <div className="grid grid-cols-2 gap-12">
          <div className="col-span-1">
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">Nombre</label>
              <input
                placeholder={"Comisión de Regla, ..."}
                className="outline-none border-b border-[#CEDEF1]"
                name="name"
                {...register("name")}
              />
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="name" as="p" />
              </div>
            </div>
            <div className="mb-8">
              <label className="font-medium w-[300px]">Monto</label>
              <div className="flex items-center  gap-2 relative">
                <div>
                  <select
                    className="addPackInput w-30"
                    name="rate_type"
                    {...register("rate_type")}
                  >
                    <option value={"porcentaje"}>porcentaje </option>
                    <option value={"fijo "}>fijo </option>
                  </select>
                </div>
                <div className="">
                  <input
                    type="number"
                    placeholder={"Valor, ..."}
                    className="outline-none border-b border-[#CEDEF1]"
                    name="number"
                    {...register("number")}
                  />
                  <div className="flex flex-col gap-2 relative">
                    <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                      <ErrorMessage errors={errors} name="name" as="p" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <label className="font-medium w-[300px]">Moneda</label>

              <select
                name="currency_type_id"
                {...register("currency_type_id", { valueAsNumber: true })}
                id="currency_type_id"
                className="addPackInput w-full"
              >
                {currencies?.map(({ name, symbol, id }) => {
                  return (
                    <option key={`${id}`} value={id}>
                      {name} ({symbol})
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">Tipo</label>
              <select
                className="addPackInput w-full"
                name="percentage"
                {...register("operation_fiscal")}
              >
                <option value={"impuesto"}>impuesto</option>
                <option value={"comisión"}>comisión </option>
                <option value={"descuento"}>descuento </option>
              </select>
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="operation_fiscal" as="p" />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">Operación</label>
              <select
                className="addPackInput w-full"
                name="operation"
                {...register("operation")}
              >
                <option value={"suma"}>suma</option>
                <option value={"resta"}>resta </option>
              </select>

              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="operation" as="p" />
              </div>
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">Sobre el</label>
              <select
                className="addPackInput w-full"
                name="variable"
                {...register("variable")}
              >
                <option value={"neto"}>neto </option>
                <option value={"bruto"}>bruto </option>
              </select>

              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="variable" as="p" />
              </div>
            </div>
            <div className="flex flex-col gap-2 relative mb-8">
              <label className="font-medium w-[300px]">Producto</label>
              <select
                className="addPackInput w-full"
                name="product"
                {...register("product")}
              >
                <option value={"vuelos"}>vuelos </option>
                <option value={"hoteles"}>hoteles </option>
                <option value={"paquetes"}>paquetes </option>
                <option value={"asistencia"}>asistencia </option>
                <option value={"autos"}>autos </option>
                <option value={"crucero"}>crucero </option>
              </select>
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="product" as="p" />
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="flex flex-col gap-2 ">
                <label className="font-medium w-[300px]">Activo</label>
                <input
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  {...register("active", { valueAsBoolean: true })}
                  id="checkbox-active"
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label className="font-medium w-[300px]">Única Regla</label>
                <input
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  {...register("running", { valueAsBoolean: true })}
                  id="checkbox-running"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex items-center justify-end mt-4">
        <button
          type="submit"
          className="px-6 py-4 rounded bg-brightBlue text-white"
        >
          Cargar
        </button>
      </section>
    </form>
  );
}
