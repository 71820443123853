import { Outlet } from "react-router-dom";
import { SidebarPanel } from "../../admin/panel/SidebarPanel";
import { useState } from "react";
import clsx from "clsx";
import Sidebar from "../../admin/panel/Sidebar";

export default function PanelPage() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex bg-[#F2F6FD]">
      <Sidebar hoverState={[isHovered, setIsHovered]} />
      <div
        className={clsx(
          "transition-all duration-300 h-[100vh] mr-3 rounded-r-[25px] py-8 px-4 bg-transparent",
          {
            "w-[92px]": !isHovered,
          },
          {
            "w-[237px]": isHovered,
          }
        )}
      />
      <div id="orders" className="mb:w-full  md:w-[80%] mx-auto mb:-ml-10 md:ml-0 h-fit">
        <Outlet />
      </div>
    </div>
  );
}
