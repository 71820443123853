import React, { useRef } from "react";
import EditButton from "../../../../../../utils/tableButtons/EditButton";
import DeleteButton from "../../../../../../utils/tableButtons/DeleteButton";
import { MoreHorizRounded } from "@mui/icons-material";
import { useToggleVisibility } from "../../../../../../hooks/useToggleVisibility";
import useOnClickOutside from "../../../../../../hooks/useClickOutside";
import { useTours } from "../../../../../../hooks/useTours";
// import DuplicateTour from "../tourRow/DuplicateTour";
import { useParams } from "react-router-dom";

export default function SubMenuTourRow({ pkg,  }) {
  const {tour_op_id} = useParams()
  const { deleteTour, getTours } = useTours();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const ref = useRef(null);

  // Call useClickOutside hook to detect outside clicks and toggle visibility off
  useOnClickOutside(ref, () => isVisible && toggleVisibility());

  return (
    <>
      <div className="flex " ref={ref}>
        <button
          onClick={toggleVisibility}
          className="relative mx-0.5 h-7 w-7 flex items-center justify-center hover:bg-[#EEE] rounded-full transition-colors group/link"
        >
          <MoreHorizRounded scale={23} />
        </button>

        {isVisible && (
          <div className="absolute   ml-8 bg-white p-4 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 ease-in-out   z-custom">
            {/* <DuplicateTour tour={pkg} /> */}
            <EditButton
              data={pkg}
              tour_op_id={tour_op_id}
              route={"panel/packages/update-tour"}
            />

            <DeleteButton data={pkg} delete={deleteTour} get={getTours} type="tour" />
          </div>
        )}
      </div>
    </>
  );
}
