import React from "react";

export default function FooterLinks({ TourOperatorSocialMedia }) {
  const wpp = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Whatsapp"
  )?.url;
  return (
    <div className="flex flex-col ">
      <div>
        <h3 className="text-xl font-medium ">
          Suscribite a nuestro Newsletter
        </h3>
        <input
          type="tel"
          placeholder="Nroº de telefono"
          className="mt-4 rounded-lg border border-footerGray  text-center placeholder:text-gray-500 block"
          pattern="[0-9]*"
          inputMode="numeric"
        />
        <div className="mt-4">
          <a
            className="mt-4 hover:bg-darkGray bg-footerGray rounded-lg p-2"
            target="_blank"
            href={wpp}
            rel="noreferrer"
          >
            Suscribirme
          </a>
        </div>
      </div>
    </div>
  );
}
