import React, { useEffect, useState } from "react";
import SEO from "../../../utils/SEO";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import { usePackages } from "../../../hooks/usePackages";
import NewCalendar from "../../../utils/NewCalendar";
import { useForm } from "react-hook-form";
import { NightsStay, WbSunny } from "@mui/icons-material";
import { format } from "date-fns";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {Toaster, toast} from "react-hot-toast";
import { useTours } from "../../../hooks/useTours";

const packagesCategoriesFromScratch = [
  { id: 3, title: "Aventura" },
  { id: 6, title: "Cultural" },
  { id: 1, title: "Deportivo" },
  { id: 2, title: "Educativo" },
  { id: 5, title: "Quinceañera" },
  { id: 4, title: "Religioso" },
];

export default function FormEditTour() {
  const location = useLocation();
  const { data } = location.state || {};
  const [selectedTour, setSelectedTour] = useState();
  const { tour_op_id } = useParams();
  const { updateTour } = useTours();
  const [rangeDate, setRangeDate] = useState();
  const [opendateIn, toggleSalida] = useToggleVisibility();
  const { packagesCategories } = usePackages();
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  console.log(data)

  useEffect(() => {
    if (data) {
      setSelectedTour(data);
      setRangeDate({
        from: data.dateIn,
        to: data.dateOut,
      });
    }
  }, [data]);

  const onSubmitEdit = async (data) => {
    toast.promise(
      updateTour({
        ...selectedTour,
        dateIn: rangeDate.from,
        category: selectedTour.category,
        dateOut: rangeDate.to,
        comments: selectedTour.comments,
        days: Number(selectedTour.days),
        nights: Number(selectedTour.nights),
        hotels: selectedTour.hotels,
        meal_plan: selectedTour.meal_plan,
        original_stock: Number(selectedTour.original_stock),
      }).then(() =>
        navigate(`/${tour_op_id}/panel/packages/my-packages`, { replace: true })
      ),
      {
        loading: "Cargando...",
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const handleEdit = (e) => {
    setSelectedTour((curr) => {
      return {
        ...curr,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      <SEO title={"Editar Paquete"} />
      <div className="w-full">
        <header className="flex justify-between my-16 max-w-[1200px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">
            Editar Salida
          </h1>
          <Link
            to={`/${tour_op_id}/panel/packages/my-packages`}
            className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Paquetes
          </Link>
        </header>
        <form onSubmit={handleSubmit(onSubmitEdit)}>
          <div>
            <Toaster />
          </div>
          <div className=" my-10 mt-16 mx-auto w-auto bg-white rounded-lg p-8 ">
            <section className="flex flex-col gap-2">
              <h2 className="text-darkBlue font-medium mb-4">FECHAS</h2>
              <div className="flex flex-row items-start gap-2">
                <label className="relative w-3/5">
                  <input
                    autoComplete="off"
                    name="dateIn"
                    placeholder="Ida"
                    onClick={() => {
                      toggleSalida();
                      setRangeDate({
                        from: "",
                        to: "",
                      });
                    }}
                    value={
                      rangeDate?.from
                        ? format(new Date(rangeDate?.from), "dd/MM/yyyy")
                        : ""
                    }
                    className="addPackInput w-full"
                  />
                  {opendateIn ? (
                    <NewCalendar
                      handlers={[
                        rangeDate,
                        (date) => {
                          setRangeDate(date);
                        },
                      ]}
                      mode={"range"}
                      numberOfMonths={2}
                      close={toggleSalida}
                    />
                  ) : null}
                </label>
              </div>
              <div className="flex flex-col items-start gap-2">
                <label className="relative w-3/5">
                  <input
                    autoComplete="off"
                    name="dateOut"
                    placeholder="Vuelta"
                    onClick={() => toggleSalida()}
                    value={
                      rangeDate?.to
                        ? format(new Date(rangeDate?.to), "dd/MM/yyyy")
                        : ""
                    }
                    className="addPackInput w-full"
                  />
                </label>
              </div>
              <div>
                <h2 className="text-darkBlue font-medium mb-4">CATEGORÍA</h2>
                <select
                  className="addPackInput w-3/5"
                  name="category"
                  defaultValue={data.category}
                  onChange={(e) =>
                    setSelectedTour({
                      ...selectedTour,
                      category: e.target.value,
                    })
                  }
                >
                  {packagesCategories &&
                  packagesCategories?.data?.response?.length > 0
                    ? packagesCategories?.data?.response?.map((element) => (
                        <option key={element.id} value={element.title}>
                          {element.title}
                        </option>
                      ))
                    : packagesCategoriesFromScratch?.map((element) => (
                        <option key={element.id} value={element.title}>
                          {element.title}
                        </option>
                      ))}
                </select>
              </div>
              <div>
                <h2 className="text-darkBlue font-medium mb-4">ESTADÍA</h2>
                <div className="flex flex-row gap-1">
                  <div className="addPackInput border-[1px] rounded-md p-1 ">
                    <WbSunny />
                    <input
                      className="ml-2 outline-none"
                      type="number"
                      defaultValue={data.days}
                      name="days"
                      placeholder="Días"
                      onChange={handleEdit}
                    />
                  </div>
                  <div className="addPackInput border-[1px] rounded-md p-1 ">
                    <NightsStay />
                    <input
                      className="ml-2 outline-none"
                      type="number"
                      defaultValue={data.nights}
                      name="nights"
                      placeholder="Noches"
                      onChange={handleEdit}
                    />
                  </div>
                </div>
                <div className="flex flex-col col-span-2">
                  <h2 className="text-darkBlue font-medium my-4">HOTEL</h2>
                  <textarea
                    id="hotels"
                    name="hotels"
                    defaultValue={data.hotels}
                    value={selectedTour?.hotels}
                    onChange={handleEdit}
                    className="addPackInput"
                  />
                </div>
              </div>
            </section>
            <section className="relative w-auto h-full flex flex-col gap-4">
              <div className="flex flex-col">
                <h2 className="text-darkBlue font-medium my-4">REGIMEN COMIDA</h2>
                <textarea
                  id="meal_plan"
                  name="meal_plan"
                  defaultValue={data.meal_plan}
                  value={selectedTour?.meal_plan}
                  onChange={handleEdit}
                  className="addPackInput"
                />
              </div>
              <div className="flex flex-col col-span-2">
                <h2 className="text-darkBlue font-medium mb-4">COMENTARIOS</h2>
                <textarea
                  id="comments"
                  name="comments"
                  defaultValue={data.comments}
                  value={selectedTour?.comments}
                  onChange={handleEdit}
                  className="addPackInput"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-darkBlue font-medium mb-4">STOCK</label>
                <div className="addPackInput w-1/5 border-[1px] rounded-md">
                  <input
                    className="w-4/5 ml-2 outline-none"
                    type="number"
                    defaultValue={data.original_stock}
                    name="original_stock"
                    onChange={handleEdit}
                  />
                </div>
              </div>
              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Actualizar Salida
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
}
