import { z } from "zod";

export const supplierSchema = z.object({
  name: z.string().min(1, { message: "Campo Requerido" }),
  legal_name: z.string().min(1, { message: "Campo Requerido" }),
  email: z.string().min(1, { message: "Campo Requerido" }).email(),
  tax_code: z.string().min(1, { message: "Campo Requerido" }),
  tax_number: z
    .string()
    .min(1, { message: "Campo Requerido" })
    .refine((val) => !isNaN(Number(val)), {
      message: "Debe ser un número",
    })
    .transform((val) => Number(val)),
  address: z.string().min(1, { message: "Campo Requerido" }),
  account_link: z.string().min(1, { message: "Campo Requerido" }),
  phone_number: z
    .string()
    .regex(/^[+]?\d+$/, { message: "El valor debe ser numérico" })
    .min(1, { message: "Campo Requerido" }),
  contact_name: z.string().min(1, { message: "Campo Requerido" }),
  cellphone_number: z
    .string()
    .regex(/^[+]?\d+$/, { message: "El valor debe ser numérico" })
    .min(1, { message: "Campo Requerido" }),
});
