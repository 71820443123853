import React, { useEffect } from "react";

export default function FeeInput({ feeEdit, fee, edit, setFeeEdit, type }) {
  useEffect(() => {
    if (!edit) {
      setFeeEdit((prev) => ({
        ...prev,
        [type]: fee[type],
      }));
    }
  }, [edit, fee, type, setFeeEdit]);

  return (
    <input
      className={
        edit
          ? "background-none"
          : "w-auto border-2 rounded-lg border-darkGray3 text-center"
      }
      value={edit ? fee[type] : feeEdit[type]}
      onChange={(e) =>
        setFeeEdit({
          ...feeEdit,
          [type]: e.target.value,
        })
      }
      disabled={edit}
      size={edit ? String(fee[type] || '').length : String(feeEdit[type] || '').length}
    />
  );
}
