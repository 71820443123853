import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import useAgencies from "../../hooks/useAgencies";
import { useEffect } from "react";
import PanelSelector from "./PanelSelector";

export const PanelGeneralAgency = () => {
  const { tour_op_id } = useParams();
  const { agency_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  const { agencies, getAgenciesByTourOperator } = useAgencies();

  useEffect(() => {
    getAgenciesByTourOperator();
  }, []);

  const agency = agencies && agencies.find(({ id }) => id === agency_id);

  return (
    <>
      <SEO title={"Mi Perfil"} />
      <main className="relative font-body max-w-7xl mx-auto">
      
        <header className="my-8 flex justify-between items-center">
          <div>
            <h1 className="text-darkBlue font-[700] text-3xl">Mi Perfil</h1>
            <p className="text-darkBlue/70 text-lg">
              Controlá, personalizá y gestioná todos tus datos.
            </p>
          </div>
        </header>

        <form className="bg-white p-16 pb-20 rounded-3xl">
          <h2 className="text-darkBlue font-[700] text-2xl">
            Nombre de Agencia
          </h2>

          <section className="mt-4">
            <h3 className="font-medium text-xl mb-2">Información General</h3>
            <div className="grid grid-cols-3 gap-4">
              <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                {agency?.name}
              </p>
              <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                {agency?.email}
              </p>
              <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                {agency?.address}
              </p>
              <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                {agency?.phone}
              </p>
              <p className="bg-lightBlue/50 rounded-lg p-4 font-body">
                IVA: {agency?.iva}%
              </p>
            </div>
          </section>
        </form>

        {/* <Link
          to={`/${tour_op_id}/panel/profile/my-tour-operator`}
          className="absolute left-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Anterior
        </Link>
        <Link
          to={`/${tour_op_id}/panel/profile/fees`}
          className="absolute right-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Siguiente
        </Link> */}
      </main>
    </>
  );
};
