import React from 'react'
import download from "../../iconos/download.png";
import { BlobProvider, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import AgenciesTablePDFile from './AgenciesTablePDFile';


export default function AgenciesTableSearcher({ pdfData, }) {
    return (
        <section className="flex gap-4 pb-4 mb-4 justify-between border-b border-gray2">

            <div className='grid grid-cols-2 gap-2'>


                {pdfData && pdfData.length > 0 && (
                    <BlobProvider document={<AgenciesTablePDFile pdfData={pdfData} />} >
                        {({ url, loading }) =>
                            loading ? (
                                'Loading document...'
                            ) : (
                                <a href={url} download="Agencias.pdf" className=" border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors">
                                    Exportar PDF
                                    <img src={download} alt="download" className="h-8 py-2.5" />
                                </a>
                            )
                        }
                    </BlobProvider>
                )}

                {/* {pdfData && pdfData.length > 0 && (
                    <button className="border border-gray2 rounded h-9 flex items-center gap-2 px-2 py-1 hover:bg-lightGray transition-colors">
                        Exportar Excel
                        <img src={download} alt="download" className="h-8 py-2.5" />
                    </button>

                )} */}
            </div>
        </section>
    )
}
