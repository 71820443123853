import React from "react";

export default function YearFilter({
  handleDisplay,
  arrow,
  possibleCitiesAndMonth,
  displayedOricode,
  filters,
  handleChecks,
  capitalizeFirstLetter,
}) {
  const uniqueYears = new Set(); // Set to track unique years
  const dateInArray = possibleCitiesAndMonth.dateIn || []; // Fallback to empty array if not defined

  const uniqueYearsArray = dateInArray
    .filter((date) => {
      return typeof date === "string" && date.includes("-");
    })
    .map((date) => {
      const year = date.split("-")[0];
      return year;
    })
    .filter((year) => {
      if (year && !uniqueYears.has(year)) {
        uniqueYears.add(year);
        return true;
      }
      return false;
    });
  return (
    <div>
      <section
        onClick={() => handleDisplay("year")}
        id="year-header"
        className="cursor-pointer"
      >
        <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
          <h2 className="font-low">Año de Salida</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="year-arrow"
          />
        </header>
      </section>
      <section
        className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 year-top"
        id="year-body"
      >
        {uniqueYearsArray.length > 0 ? (
          uniqueYearsArray.map((year) => {
            const formattedYear = capitalizeFirstLetter(year || "");
            const isChecked = filters.year.includes(year);

            return (
              <div className="flex items-center" key={year}>
                <input
                  className="form-checkbox h-4 w-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                  type="checkbox"
                  id={year}
                  name="year"
                  checked={isChecked}
                  onChange={(evt) => handleChecks(evt, year, "year")}
                />
                <label htmlFor={year} className="flex gap-2 items-center">
                  <p className="max-w-[15ch] ml-2">{formattedYear}</p>
                </label>
              </div>
            );
          })
        ) : (
          <p>No hay años disponibles</p> // Fallback message if no years are found
        )}
      </section>
    </div>
  );
}
