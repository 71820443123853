import axios from "axios";
import { useEffect, useState } from "react";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export default function useCommissionsAndTaxes() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [rulesFare, setRulesFares] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createRulesFares = async ({ ruleFare }) => {

    return axios.post(
      BASE_URL + "v2/rules-fares",
      {
        ruleFare: { ...ruleFare, tour_op_id: tourOP.id },
      },
      headers
    );
  };

  const getRulesFares = async () => {
    return await axios
      .get(BASE_URL + "v2/rules-fares", headers)
      .then((e) => {
        const filteredRulesFares = e.data.response
        setRulesFares(filteredRulesFares);
        //console.log('Fetched Rules Fares:', filteredRulesFares);
      })
      .catch((e) => console.log(e));

  };


  const updateRulesFares = async (id, ruleFare) => {
    const { ...restOfRuleFare } = ruleFare;
    return await axios.patch(
      BASE_URL + `v2/rules-fares/${id}`,
      {
        ruleFare: { ...restOfRuleFare },
      },
      headers
    );
 
  };

  

  useEffect(() => {
    getRulesFares();
    
  }, []);

  return {
    rulesFare,
    createRulesFares,
    getRulesFares,
    updateRulesFares
  };
}
