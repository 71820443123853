import React, { useEffect } from "react";
import { useTravelers } from "../../hooks/useTravelers";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { format } from "date-fns";

export default function UpdateTravelerModal({ traveler, closeModal }) {
  const { updateTraveler } = useTravelers();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: traveler,
  });
  // function formatDateToInput(dateString) {
  //   if (!dateString) return "";
  //   return new Date(dateString).toISOString().split("T")[0];
  // }

  const onSubmit = async (data) => {
    await updateTraveler(traveler.id, data);
    closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setValue(name, newValue, { shouldValidate: true });
  };

  // const checkInput = (inputString, pattern) => {
  //   const regex = new RegExp(pattern);
  //   return regex.test(inputString);
  // };
  useEffect(() => {
    if (!traveler?.category) {
      setValue("category", "ninguno");
    }
    if (traveler?.date_of_birth) {
      const formattedDate = format(
        new Date(traveler?.date_of_birth),
        "yyyy-MM-dd"
      );
      setValue("date_of_birth", formattedDate);
    }
  }, [traveler, setValue]);

  return (
    <>
      <h1 className="text-darkBlue font-semibold text-xl text-center">
        Editar Pasajero
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[80vw]">
        <section className="grid  gap-2 my-2 p-4">
          <div className="col">
            <div className="grid grid-cols-2 mb-4">
              <div className="w-full relative flex flex-col">
                <label>
                  Nombre <span className="text-red-400">*</span>
                </label>
                <input
                  className="addPackInput"
                  name="first_name"
                  {...register("first_name", {
                    required: "Nombre es requerido",
                  })}
                  onChange={handleInputChange}
                />
                <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="first_name" as="p" />
                </span>
              </div>

              <div className="w-full relative flex flex-col">
                <label>
                  Apellido <span className="text-red-400">*</span>
                </label>
                <input
                  className="addPackInput"
                  name="last_name"
                  {...register("last_name", {
                    required: "Apellido es requerido",
                  })}
                  onChange={handleInputChange}
                />
                <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="last_name" as="p" />
                </span>
              </div>
            </div>

            <div className="mb-4">
              <label
                className="mb-1 font-low text-sm text-[#242423]"
                htmlFor="birthday"
              >
                Fecha de nacimiento
              </label>
              <div className="col-span-2 relative">
                <input
                  name="date_of_birth"
                  {...register("date_of_birth")}
                  type="date"
                  className="addPackInput"
                  id="date_of_birth"
                  onChange={handleInputChange}
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
              <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="date_of_birth" as="p" />
              </span>
            </div>

            <div className="mb-4">
              <label className="mb-1 font-low text-sm text-[#242423]">
                Sexo
              </label>
              <span className="flex items-center gap-4 col-span-2">
                <fieldset>
                  <div className="flex items-center gap-3">
                    <input
                      {...register("gender")}
                      type="radio"
                      id="gender_F"
                      name="gender"
                      value="F"
                      checked={watch("gender") === "F"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="gender_F">Femenino</label>

                    <input
                      {...register("gender")}
                      type="radio"
                      id="gender_M"
                      name="gender"
                      value="M"
                      checked={watch("gender") === "M"}
                      onChange={handleInputChange}
                    />
                    <label htmlFor="gender_M">Masculino</label>
                  </div>
                </fieldset>
              </span>
            </div>

            <div className="w-full relative flex flex-col mb-8">
              <label>Categoria</label>
              <select
                className="addPackInput"
                name="category"
                {...register("category")}
                onChange={handleInputChange}
              >
                <option>ninguno</option>
                <option>directivos</option>
                <option>cuerpo tecnico</option>
                <option>jugadores</option>
                <option>plantel</option>
                <option>reserva</option>
                <option>juveniles</option>
                <option>inferiores</option>
                <option>otro1</option>
                <option>otro2</option>
                <option>otro3</option>
              </select>
            </div>

            <div className="w-full relative flex flex-col mb-8">
              <label>Email</label>
              <input
                className="addPackInput"
                name="email_address"
                {...register("email_address", {
                  required: "Email es requerido",
                })}
                onChange={handleInputChange}
              />
              <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                <ErrorMessage errors={errors} name="email_address" as="p" />
              </span>
            </div>

            {traveler?.TravelerDocument?.doc_type && (
              <div>
                <label className="mb-1 font-low text-sm text-[#242423]">
                  Tipo y número de documento
                </label>
                <span className="flex col-span-2 gap-4">
                  <input
                    defaultValue={
                      traveler.TravelerDocument.doc_type === "IDENTITY_CARD"
                        ? "Documento"
                        : "Pasaporte"
                    }
                    name="doc_type"
                    className="addPackInput"
                    disabled
                  ></input>
                  <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full">
                    <div className="relative w-full">
                      <input
                        className="addPackInput"
                        name="number"
                        placeholder={`Número de ${
                          watch("doc_type") === "IDENTITY_CARD"
                            ? "documento"
                            : "pasaporte"
                        }`}
                        defaultValue={traveler.TravelerDocument.number}
                        disabled
                      />
                    </div>
                  </label>
                </span>
              </div>
            )}

            {traveler?.TravelerPhones?.device_type && (
              <div>
                <label className="mb-1 font-low text-sm text-[#242423]">
                  Teléfono
                </label>
                <span className="flex col-span-2 gap-4">
                  <input
                    defaultValue={traveler.TravelerPhones.device_type}
                    name="doc_type"
                    className="addPackInput"
                    disabled
                  ></input>
                  <label className="mb-1 flex flex-col gap-1 text-xs text-[#242423] w-full">
                    <div className="relative w-full">
                      <input
                        className="addPackInput"
                        name="number"
                        defaultValue={traveler.TravelerPhones.number}
                        disabled
                      />
                    </div>
                  </label>
                </span>
              </div>
            )}
          </div>

          {/* <div className="w-full relative flex flex-col">
            <label>Teléfono</label>
            <input
              className="addPackInput"
              name="phone"
              {...register('phone', { required: 'Teléfono es requerido' })}
              onChange={handleInputChange}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="phone" as="p" />
            </span>
          </div> */}
        </section>

        <button className="font-semibold text-lg ml-auto mt-4 button w-1/4 bg-brightBlue text-white hover:shadow-lg transition-all">
          Guardar
        </button>
      </form>
    </>
  );
}
