import React from "react";
import EditButton from "../../../../../utils/tableButtons/EditButton";
import EmailButton from "../../../../suppliers/tableSupplier/tableRow/tableButtons/EmailButton";
import CallButton from "../../../../../utils/tableButtons/CallButton";
import WhatsappButton from "../../../../../utils/tableButtons/WhatsappButton";

export default function TableActionsQuotes({ qto }) {
  return (
    <div className="flex flex-row items-center gap-2">
      <EmailButton suppEmail={qto.email} />
      <WhatsappButton number={qto.whatsapp} size={18} />
      <CallButton number={qto.whatsapp} />
    </div>
  );
}
