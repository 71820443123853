import React from "react";
import logo_walltrip from "../../../../iconos/logos/walltrip.png";
export default function LowFooter() {
  return (
    <div className="w-1/3 bg-white rounded-xl  ">
      <div className="flex flex-row gap-4 w-full leading-0 items-center ">
        <p className="text-[.9rem] m-0 flex-grow ml-2">
          ©️ 2024 · All Rights Reserved
        </p>
        <p className="text-[.9rem] m-0 flex-grow"></p>
        <button className=" rounded-lg">
          <div className=" flex flex-row items-center gap-2">
            <p className="text-xl font-medium">Made with </p>
            <a
              target="_blank"
              href={"https://thewalltrip.com"}
              rel="noreferrer"
            >
              <img
                className="text-[.9rem] m-0 flex-grow  max-w-[108px] max-h-10"
                alt="404 not found"
                src={logo_walltrip}
              />
            </a>
          </div>
        </button>
      </div>
    </div>
  );
}
