import React from "react";
import useGetCurrencies from "../../../../../../../hooks/useGetCurrencies";

export default function SelectFeeCurrency({ feeEdit, fee, setFeeEdit }) {
  const { currencies } = useGetCurrencies();

  return (
    <select
      name="currency_type_id"
      id="currency_type_id"
      className="addPackInput w-full"
      value={feeEdit.currency_type_id || fee.currency_type_id || ""}
      onChange={(e) =>
        setFeeEdit({
          ...feeEdit,
          currency_type_id: e.target.value,
        })
      }
    >
      <option value="">- Elegir Moneda -</option>
      {currencies?.map(({ name, symbol, id }) => {
        return (
          <option key={id} value={id}>
            {name} ({symbol})
          </option>
        );
      })}
    </select>
  );
}
