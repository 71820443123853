
import React from "react";
import TableActionsPackages from "./TableActionsPackages";
import ModalViewPackage from "../../../ModalViewPackage";
import { ModalGeneric } from "../../../../../utils/ModalGeneric";
import { useParams } from "react-router-dom";
import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import useGetCurrencies from "../../../../../hooks/useGetCurrencies";

export default function TableRowPackages({
  pkg,
  isTourVisible,
  toggleTourVisibility,
  index,
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const tour_op_id = useParams();
  const { currencies } = useGetCurrencies();
  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  // Determine row background color based on the index value
  //const rowColor = index % 2 === 0 ? "bg-white" : "bg-lightGray2";

  return (
    <>
    
          <tr className={`bg-white text-center border-2 border-lightBlue hover:bg-lightBlue2`}>
          <td
            className="p-2 border-2 border-lightBlue"
            onClick={toggleVisibility}
          >
            {index + 1}
          </td>
          <td
            className="p-2 border-2 border-lightBlue"
            onClick={toggleVisibility}
          >
            {pkg?.title}
          </td>
         
          <td
            className="p-2 border-2 border-lightBlue "
            onClick={toggleVisibility}
          >
            {pkg.origin_code}
          </td>
          <td
            className="p-2 border-2 border-lightBlue "
            onClick={toggleVisibility}
          >
            {pkg?.destination_code}
          </td>
  
          
          <td className="p-2 flex justify-end ">
            <TableActionsPackages
              pkg={pkg}
              isTourVisible={isTourVisible}
              setIsTourVisible={toggleTourVisibility}
            />
          </td>
        </tr>
    

      <ModalGeneric toggle={toggleVisibility} show={isVisible}>
        <ModalViewPackage
          children={[
            {
              selectedPackage: pkg,
              translateCurrency: translateCurrency,
              tour_op_id,
            },
          ]}
        />
      </ModalGeneric>
    </>
  );
}
