export const packageData = [
  {
    id: 1,
    title: "EUROPA DEL OESTE",
    origin_code: "MAD", // Origin code: Madrid
    destination_code: "PAR", // Destination code: Paris
    description_general:
      "Un increíble viaje por las principales ciudades del oeste de Europa. Disfruta de monumentos históricos, paisajes inolvidables y una rica experiencia cultural.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 4 estrellas, desayuno diario, traslados y visitas guiadas.",
    not_included: "Comidas no mencionadas, gastos personales, seguro de viaje.",
    itinerary:
      "Día 1: Llegada a París. Día 2: Tour por la ciudad de París. Día 3: Excursión a Versalles. Día 4: Viaje a Ámsterdam. Día 5: Tour por Ámsterdam. Día 6: Viaje a Bruselas. Día 7: Regreso a Madrid.",
    terms:
      "Cancelación gratuita hasta 30 días antes del viaje. Pago completo requerido 15 días antes de la salida.",
    flyer: "https://example.com/flyer-europa-oeste.pdf",
    portrait: "https://ecosistemas.win/wp-content/uploads/europa-este-1.jpg",
    pictures: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQItJWOkbj84kGNqwXjZYhby5SsexeTbmn4iOQZ9EBBrFXjrn4cHqXdC2UMTiiAQ-jywW8&usqp=CAU",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfEmoqDDVqHQRyAOSOtM3kPOW8WuotGWaHSUnbjwHhReXEo1EoTpR_L3uhXwnNZjmESzE&usqp=CAU",
      "https://lh3.googleusercontent.com/proxy/rgx-nSdpVKw4DMHwJC7S-DUje3InbhKfOP3MgVf6ZN32pSmrNuXyJ04v0iSZJ6fHGRygwSDCXnPE_1Sl8Y8zbut7P53phpB1mzq5_FWFN2c_KeFp2CrhHc2LGa6hdE8uRQRHpy5h6372EIr-HeuekVsXNjG5z96jReU-OVg511BdVY5Qyf7a26A",
    ],
    country_id: 1,
    tour_op_id: 1,
    user_id: 1001,
    supplier_id: 1,
    package_category_id: 1,
    show: true,
    tours: [
      {
        id: 1,
        dateIn: "2024-06-24",
        dateOut: "2024-06-30",
        hoteles: "Hotel Paris Élysées, Hotel Amsterdam Central",
        categoría: "4 estrellas",
        meal_plan: "Desayuno incluido",
        days: 7,
        nights: 6,
        stock: 99,
        original_stock: 100,
        availability: "Y",
        comments:
          "El tour incluye visitas a monumentos históricos como la Torre Eiffel y los molinos de Ámsterdam.",
        id_package: 1,
      },
    ],
    fee: [
      {
        id: 1,
        base: "DBL",
        fare: 1200,
        tax: 200,
        price: 1400,
        currency_type_id: 1,
        comments: "El precio incluye impuestos y tasas de aeropuerto.",
        id_tours: 1,
      },
    ],
  },
  {
    id: 2,
    title: "TOUR POR EL MEDITERRÁNEO",
    origin_code: "BCN", // Origin code: Barcelona
    destination_code: "ROM", // Destination code: Rome
    description_general:
      "Descubre la belleza y la historia del Mediterráneo. Viaja por ciudades icónicas llenas de cultura, gastronomía y paisajes deslumbrantes.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 5 estrellas, todas las comidas, traslados privados, y entradas a museos y sitios históricos.",
    not_included: "Gastos personales, seguro de viaje, propinas.",
    itinerary:
      "Día 1: Llegada a Roma. Día 2: Visita al Coliseo y el Vaticano. Día 3: Excursión a Florencia. Día 4: Viaje a Venecia. Día 5: Paseo en góndola por Venecia. Día 6: Viaje a Nápoles y Pompeya. Día 7: Regreso a Barcelona.",
    terms:
      "Cancelación gratuita hasta 45 días antes del viaje. Pago completo requerido 30 días antes de la salida.",
    flyer: "https://example.com/flyer-tour-mediterraneo.pdf",
    portrait: "https://assets.dm.rccl.com/is/image/RoyalCaribbeanCruises/royal/content/destinations/mediterranean/mediterranean-beautiful-coastal-town-cliff.jpg?$880x1428$",
    pictures: [
      "https://es.ncl.com/sites/default/files/1000x667-ncl-cruise-santorini-couple_0.jpg",
      "https://estaticos-cdn.prensaiberica.es/clip/3dcc9ab7-1b67-445e-be0c-5ef187242376_source-aspect-ratio_default_0.jpg",
      "https://www.solocruceros.com/blog/wp-content/uploads/2020/12/blog-solocruceros-islas-griegas-mediterraneo.jpg",
    ],
    country_id: 2,
    tour_op_id: 2,
    user_id: 1002,
    supplier_id: 2,
    package_category_id: 2,
    show: true,
    tours: [
      {
        id: 2,
        dateIn: "2024-07-15",
        dateOut: "2024-07-21",
        hoteles: "Hotel Roma Luxury, Hotel Venecia Palace",
        categoría: "5 estrellas",
        meal_plan: "Pensión completa",
        days: 7,
        nights: 6,
        stock: 80,
        original_stock: 80,
        availability: "Y",
        comments:
          "El tour incluye entradas al Coliseo, Museos Vaticanos, y paseo en góndola por Venecia.",
        id_package: 2,
      },
    ],
    fee: [
      {
        id: 2,
        base: "DBL",
        fare: 1800,
        tax: 300,
        price: 2100,
        currency_type_id: 1,
        comments: "El precio incluye todas las tasas e impuestos.",
        id_tours: 2,
      },
    ],
  },
  {
    id: 3,
    title: "MARAVILLAS DE ASIA",
    origin_code: "BKK", // Origin code: Bangkok
    destination_code: "TYO", // Destination code: Tokyo
    description_general:
      "Explora los destinos más impresionantes de Asia. Desde templos antiguos hasta modernas metrópolis, disfruta de una experiencia inolvidable.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 4 estrellas, desayuno y cena incluidos, traslados y tours locales.",
    not_included: "Almuerzos, gastos personales, actividades opcionales.",
    itinerary:
      "Día 1: Llegada a Bangkok. Día 2: Visita a los templos de Bangkok. Día 3: Vuelo a Tokio. Día 4: Tour por Tokio. Día 5: Excursión a Monte Fuji. Día 6: Día libre en Tokio. Día 7: Regreso a Bangkok.",
    terms:
      "Cancelación gratuita hasta 60 días antes del viaje. Pago completo requerido 30 días antes de la salida.",
    flyer: "https://example.com/flyer-asia-maravillas.pdf",
    portrait: "https://media.traveler.es/photos/613776fa7ad90bc43bae05b4/4:3/w_1244,h_933,c_limit/35147.jpg",
    pictures: [
      "https://tnews.com.pe/wp-content/uploads/2024/01/170124_f002.jpg",
      "https://static.nationalgeographic.es/files/styles/image_3200/public/viernam.JPG?w=1600&h=900",
      "https://imagenes.20minutos.es/files/image_640_auto/uploads/imagenes/2023/10/31/borobudur-indonesia.jpeg",
    ],
    country_id: 3,
    tour_op_id: 3,
    user_id: 1003,
    supplier_id: 3,
    package_category_id: 3,
    show: true,
    tours: [
      {
        id: 3,
        dateIn: "2024-09-10",
        dateOut: "2024-09-16",
        hoteles: "Hotel Bangkok Palace, Hotel Tokyo Grand",
        categoría: "4 estrellas",
        meal_plan: "Desayuno y cena incluidos",
        days: 7,
        nights: 6,
        stock: 50,
        original_stock: 50,
        availability: "Y",
        comments:
          "El tour incluye visitas a los templos de Bangkok y una excursión al Monte Fuji.",
        id_package: 3,
      },
    ],
    fee: [
      {
        id: 3,
        base: "DBL",
        fare: 1600,
        tax: 250,
        price: 1850,
        currency_type_id: 1,
        comments: "El precio incluye impuestos y tasas de aeropuerto.",
        id_tours: 3,
      },
    ],
  },
  {
    id: 4,
    title: "AVENTURA EN AFRICA",
    origin_code: "CPT", // Origin code: Cape Town
    destination_code: "JNB", // Destination code: Johannesburg
    description_general:
      "Embárcate en una aventura única a través de la belleza salvaje de África. Disfruta de safaris emocionantes, paisajes impresionantes y la cultura vibrante del continente.",
    include:
      "Vuelos de ida y vuelta, alojamiento en lodges de lujo, todas las comidas, safaris guiados, y excursiones culturales.",
    not_included: "Gastos personales, bebidas alcohólicas, propinas.",
    itinerary:
      "Día 1: Llegada a Ciudad del Cabo. Día 2: Tour por Ciudad del Cabo y Table Mountain. Día 3: Viaje a un lodge de safari. Día 4: Safari en el parque nacional. Día 5: Excursión cultural en una aldea local. Día 6: Regreso a Johannesburgo. Día 7: Vuelo de regreso.",
    terms:
      "Cancelación gratuita hasta 30 días antes del viaje. Pago completo requerido 20 días antes de la salida.",
    flyer: "https://example.com/flyer-aventura-africa.pdf",
    portrait: "https://africangorillatour.com/wp-content/uploads/2024/07/Las-mejores-vacaciones-en-Africa-en-julio.jpg",
    pictures: [
   
    ],
    country_id: 4,
    tour_op_id: 4,
    user_id: 1004,
    supplier_id: 4,
    package_category_id: 4,
    show: true,
    tours: [
      {
        id: 4,
        dateIn: "2024-10-05",
        dateOut: "2024-10-11",
        hoteles: "Luxury Safari Lodge, Hotel Cape Town",
        categoría: "Lujo",
        meal_plan: "Todo incluido",
        days: 7,
        nights: 6,
        stock: 30,
        original_stock: 30,
        availability: "Y",
        comments:
          "Incluye safaris al amanecer y al atardecer, así como actividades culturales en una comunidad local.",
        id_package: 4,
      },
    ],
    fee: [
      {
        id: 4,
        base: "DBL",
        fare: 2500,
        tax: 400,
        price: 2900,
        currency_type_id: 1,
        comments: "El precio incluye todos los impuestos y tasas.",
        id_tours: 4,
      },
    ],
  },
  {
    id: 5,
    title: "EXPLORACIÓN EN AMÉRICA DEL SUR",
    origin_code: "LIM", // Origin code: Lima
    destination_code: "RIO", // Destination code: Rio de Janeiro
    description_general:
      "Vive la diversidad cultural y natural de América del Sur. Desde las montañas de Perú hasta las playas de Brasil, este tour es una aventura completa.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 3 y 4 estrellas, desayuno diario, traslados, y tours guiados.",
    not_included: "Comidas no mencionadas, gastos personales, seguros de viaje.",
    itinerary:
      "Día 1: Llegada a Lima. Día 2: Tour por Lima. Día 3: Vuelo a Cusco. Día 4: Visita a Machu Picchu. Día 5: Viaje a Río de Janeiro. Día 6: Tour por el Cristo Redentor y Pan de Azúcar. Día 7: Día libre en Río. Día 8: Regreso a Lima.",
    terms:
      "Cancelación gratuita hasta 45 días antes del viaje. Pago completo requerido 30 días antes de la salida.",
    flyer: "https://example.com/flyer-exploracion-america-sur.pdf",
    portrait: "https://viajes.nationalgeographic.com.es/medio/2019/05/03/istock-157475972_6bf16ef2_1254x836.jpg",
    pictures: [
      "https://viajes.nationalgeographic.com.es/medio/2017/05/30/fitz-roy_8fcf216f.jpg",
      "https://uvn-brightspot.s3.amazonaws.com/assets/vixes/g/glaciar-perito-moreno-argentina.jpg",
      "https://static.nationalgeographic.es/files/styles/image_3200/public/grey-glacier-torres-del-paine-chile.jpg?w=760&h=507",
    ],
    country_id: 5,
    tour_op_id: 5,
    user_id: 1005,
    supplier_id: 5,
    package_category_id: 5,
    show: true,
    tours: [
      {
        id: 5,
        dateIn: "2024-11-01",
        dateOut: "2024-11-08",
        hoteles: "Hotel Lima Central, Hotel Cusco Plaza, Hotel Rio Beach",
        categoría: "3 y 4 estrellas",
        meal_plan: "Desayuno incluido",
        days: 8,
        nights: 7,
        stock: 100,
        original_stock: 100,
        availability: "Y",
        comments:
          "Incluye entrada a Machu Picchu, y tours guiados en Lima y Río de Janeiro.",
        id_package: 5,
      },
      {
        id: 6,
        dateIn: "2024-12-05",
        dateOut: "2024-12-12",
        hoteles: "Hotel Lima Luxury, Hotel Cusco Heritage, Hotel Rio Mar",
        categoría: "4 estrellas",
        meal_plan: "Desayuno y cena incluidos",
        days: 8,
        nights: 7,
        stock: 50,
        original_stock: 50,
        availability: "Y",
        comments:
          "Incluye tour por la gastronomía peruana y visita a las playas de Río.",
        id_package: 5,
      },
    ],
    fee: [
      {
        id: 5,
        base: "DBL",
        fare: 1600,
        tax: 250,
        price: 1850,
        currency_type_id: 1,
        comments: "El precio incluye impuestos y tasas de aeropuerto.",
        id_tours: 5,
      },
      {
        id: 6,
        base: "DBL",
        fare: 1900,
        tax: 300,
        price: 2200,
        currency_type_id: 1,
        comments: "El precio incluye todas las tasas e impuestos, con cena incluida.",
        id_tours: 5,
      },
    ],
  },
  {
    id: 6,
    title: "CULTURAS Y PAISAJES DE ASIA",
    origin_code: "DEL", // Origin code: Delhi
    destination_code: "KTM", // Destination code: Kathmandu
    description_general:
      "Descubre las ricas culturas y los impresionantes paisajes de Asia. Desde los vibrantes mercados de India hasta las majestuosas montañas del Himalaya.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 3, 4 y 5 estrellas, desayunos diarios, traslados y tours guiados.",
    not_included: "Comidas no mencionadas, gastos personales, seguro de viaje.",
    itinerary:
      "Día 1: Llegada a Nueva Delhi. Día 2: Tour por Delhi. Día 3: Viaje a Agra y visita al Taj Mahal. Día 4: Vuelo a Katmandú. Día 5: Tour por Katmandú y Bhaktapur. Día 6: Excursión al Monte Everest. Día 7: Regreso a Delhi. Día 8: Vuelo de regreso.",
    terms:
      "Cancelación gratuita hasta 30 días antes del viaje. Pago completo requerido 15 días antes de la salida.",
    flyer: "https://example.com/flyer-culturas-paisajes-asia.pdf",
    portrait: "https://miro.medium.com/v2/resize:fit:1200/1*uejgmYPdi7vBo0OHGsGbwg.jpeg",
    pictures: [
      "https://miro.medium.com/v2/resize:fit:1358/1*0DLP1bcAkc7LFq-PO--8kg.jpeg",
    
    ],
    country_id: 6,
    tour_op_id: 6,
    user_id: 1006,
    supplier_id: 6,
    package_category_id: 6,
    show: true,
    tours: [
      {
        id: null,
        dateIn: "2024-11-10",
        dateOut: "2024-11-17",
        hoteles: "Hotel Delhi Heritage, Hotel Agra Palace, Hotel Kathmandu Boutique",
        categoría: "3 y 4 estrellas",
        meal_plan: "Desayuno incluido",
        days: 8,
        nights: 7,
        stock: 60,
        original_stock: 60,
        availability: "Y",
        comments:
          "Incluye entrada al Taj Mahal, y visitas guiadas en Delhi y Katmandú.",
        id_package: null,
      },
      {
        id: null,
        dateIn: "2025-02-05",
        dateOut: "2025-02-12",
        hoteles: "Luxury Delhi Hotel, Hotel Agra Grand, Hotel Everest View",
        categoría: "4 y 5 estrellas",
        meal_plan: "Desayuno y cena incluidos",
        days: 8,
        nights: 7,
        stock: 40,
        original_stock: 40,
        availability: "Y",
        comments:
          "Incluye una cena en un restaurante típico de Katmandú y un tour de un día al Monte Everest.",
        id_package: null,
      },
      {
        id: null,
        dateIn: "2025-03-15",
        dateOut: "2025-03-22",
        hoteles: "Hotel Delhi Royal, Hotel Agra Lux, Hotel Kathmandu Palace",
        categoría: "5 estrellas",
        meal_plan: "Todo incluido",
        days: 8,
        nights: 7,
        stock: 30,
        original_stock: 30,
        availability: "Y",
        comments:
          "Tour exclusivo con guía privado y actividades culturales adicionales.",
        id_package: null,
      },
    ],
    fee: [
      {
        id: null,
        base: "DBL",
        fare: 1500,
        tax: 200,
        price: 1700,
        currency_type_id: 1,
        comments: "El precio incluye impuestos y tasas de aeropuerto.",
        id_tours: null,
      },
      {
        id: null,
        base: "DBL",
        fare: 2200,
        tax: 300,
        price: 2500,
        currency_type_id: 1,
        comments: "El precio incluye cena y actividades adicionales.",
        id_tours: null,
      },
      {
        id: null,
        base: "DBL",
        fare: 3000,
        tax: 400,
        price: 3400,
        currency_type_id: 1,
        comments: "Todo incluido, con un guía privado para todo el viaje.",
        id_tours: null,
      },
    ],
  },
  {
    id: 7,
    title: "DESCUBRIENDO EUROPA DEL ESTE",
    origin_code: "BUD", // Origin code: Budapest
    destination_code: "PRG", // Destination code: Prague
    description_general:
      "Un viaje fascinante a través de las joyas ocultas de Europa del Este. Desde las calles históricas de Budapest hasta la belleza arquitectónica de Praga.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 3 y 4 estrellas, desayuno diario, traslados y excursiones guiadas.",
    not_included: "Almuerzos y cenas no mencionadas, gastos personales, seguros de viaje.",
    itinerary:
      "Día 1: Llegada a Budapest. Día 2: Tour por Budapest, incluyendo el Parlamento y el Castillo de Buda. Día 3: Excursión a Viena. Día 4: Viaje a Praga. Día 5: Tour por Praga, incluyendo el Castillo de Praga. Día 6: Día libre en Praga. Día 7: Regreso a Budapest.",
    terms:
      "Cancelación gratuita hasta 30 días antes del viaje. Pago completo requerido 20 días antes de la salida.",
    flyer: "https://example.com/flyer-descubriendo-europa-este.pdf",
    portrait: "https://www.eurail.com/content/dam/images/eurail/charles_bridge_prague_czech_republic_2.adaptive.767.0.jpg",
    pictures: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKBkxqSGIpTvcG6yFcv35TTgATmET0_8DAjA&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8CPBudQF1kGGGNZ3MM2cj0i1ytMAT5Do6tQ&s",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKCs6QMxnA6n90Zhm1XJBqvTUoeyvpOD_SOA&s",
    ],
    country_id: 7,
    tour_op_id: 7,
    user_id: 1007,
    supplier_id: 7,
    package_category_id: 7,
    show: true,
    tours: [
      {
        id: 1,
        dateIn: "2025-04-01",
        dateOut: "2025-04-07",
        hoteles: "Hotel Budapest Central, Hotel Vienna Star, Hotel Prague Royal",
        categoría: "3 y 4 estrellas",
        meal_plan: "Desayuno incluido",
        days: 7,
        nights: 6,
        stock: 80,
        original_stock: 80,
        availability: "Y",
        comments:
          "Incluye entradas a los principales sitios turísticos y transporte entre ciudades.",
        id_package: null,
      },
      {
        id: 2,
        dateIn: "2025-05-10",
        dateOut: "2025-05-16",
        hoteles: "Hotel Budapest Luxury, Hotel Vienna Deluxe, Hotel Prague Elegant",
        categoría: "4 y 5 estrellas",
        meal_plan: "Desayuno y cena incluidos",
        days: 7,
        nights: 6,
        stock: 40,
        original_stock: 40,
        availability: "Y",
        comments:
          "Tour exclusivo con cena en un crucero por el Danubio y espectáculo folclórico.",
        id_package: null,
      },
      {
        id: 3,
        dateIn: "2025-06-15",
        dateOut: "2025-06-22",
        hoteles: "Hotel Budapest Heritage, Hotel Vienna Imperial, Hotel Prague Panorama",
        categoría: "5 estrellas",
        meal_plan: "Todo incluido",
        days: 8,
        nights: 7,
        stock: 25,
        original_stock: 25,
        availability: "Y",
        comments:
          "Incluye guía privado, tours personalizados y actividades culturales adicionales.",
        id_package: null,
      },
    ],
    fee: [
      {
        id: 1,
        base: "DBL",
        fare: 1300,
        tax: 250,
        price: 1550,
        currency_type_id: 4,
        comments: "Incluye impuestos y tasas de aeropuerto.",
        id_tours: 1,
      },
      {
        id: 2,
        base: "DBL",
        fare: 1800,
        tax: 300,
        price: 2100,
        currency_type_id: 1,
        comments: "El precio incluye cena en crucero y espectáculo folclórico.",
        id_tours: 1,
      },
      {
        id: 3,
        base: "DBL",
        fare: 2500,
        tax: 400,
        price: 2900,
        currency_type_id: 2,
        comments: "Todo incluido, con un guía privado y actividades personalizadas.",
        id_tours: 3,
      },
    ],
  },
  {
    id: 8,
    title: "AVENTURA EN ÁFRICA DEL SUR",
    origin_code: "CPT", // Origin code: Ciudad del Cabo
    destination_code: "JNB", // Destination code: Johannesburgo
    description_general:
      "Sumérgete en la vida salvaje y los paisajes impresionantes de África del Sur. Desde safaris en el Parque Nacional Kruger hasta la cultura vibrante de Ciudad del Cabo.",
    include:
      "Vuelos de ida y vuelta, alojamiento en lodges y hoteles de 4 y 5 estrellas, desayuno diario, traslados y tours guiados.",
    not_included: "Almuerzos y cenas no mencionadas, gastos personales, seguros de viaje.",
    itinerary:
      "Día 1: Llegada a Ciudad del Cabo. Día 2: Tour por Ciudad del Cabo. Día 3: Excursión a la Península del Cabo. Día 4: Vuelo a Kruger. Día 5: Safari en Kruger. Día 6: Safari en Kruger. Día 7: Vuelo a Johannesburgo. Día 8: Tour por Soweto. Día 9: Regreso a casa.",
    terms:
      "Cancelación gratuita hasta 60 días antes del viaje. Pago completo requerido 30 días antes de la salida.",
    flyer: "https://example.com/flyer-aventura-africa-del-sur.pdf",
    portrait: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsZ5c_D-ixCpSxgyTpKJaOXdfsHEPz3RW98Q&s",
    pictures: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ry2yGdsdOdlNKs_8waho9ALtQS_83ixKWg&s",
      "https://i.pinimg.com/550x/da/98/24/da9824b1a9cc273342da811764af78cf.jpg",
    ],
    country_id: 8,
    tour_op_id: 8,
    user_id: 1008,
    supplier_id: 8,
    package_category_id: 8,
    show: true,
    tours: [
      {
        id: 4,
        dateIn: "2025-07-01",
        dateOut: "2025-07-09",
        hoteles: "Hotel Cape Town Bay, Lodge Kruger Safari",
        categoría: "4 y 5 estrellas",
        meal_plan: "Desayuno incluido",
        days: 9,
        nights: 8,
        stock: 75,
        original_stock: 75,
        availability: "Y",
        comments:
          "Incluye entradas a parques, y safaris guiados con expertos.",
        id_package: null,
      },
      {
        id: 5,
        dateIn: "2025-08-10",
        dateOut: "2025-08-18",
        hoteles: "Hotel Cape Town Luxury, Lodge Kruger Deluxe",
        categoría: "5 estrellas",
        meal_plan: "Desayuno y cena incluidos",
        days: 9,
        nights: 8,
        stock: 40,
        original_stock: 40,
        availability: "Y",
        comments:
          "Incluye cena en un lodge privado y safari nocturno.",
        id_package: null,
      },
      {
        id: 6,
        dateIn: "2025-09-15",
        dateOut: "2025-09-23",
        hoteles: "Hotel Cape Town Heritage, Lodge Kruger Exclusive",
        categoría: "4 y 5 estrellas",
        meal_plan: "Todo incluido",
        days: 9,
        nights: 8,
        stock: 20,
        original_stock: 20,
        availability: "Y",
        comments:
          "Tour exclusivo con guía privado y actividades personalizadas en el parque.",
        id_package: null,
      },
      {
        id: 7,
        dateIn: "2025-10-05",
        dateOut: "2025-10-13",
        hoteles: "Hotel Ciudad del Cabo Centro, Lodge Safari Premier",
        categoría: "5 estrellas",
        meal_plan: "Desayuno y almuerzo incluidos",
        days: 9,
        nights: 8,
        stock: 15,
        original_stock: 15,
        availability: "Y",
        comments:
          "Incluye un tour cultural por Soweto y un safari de tres días.",
        id_package: null,
      },
    ],
    fee: [
      {
        id: 4,
        base: "DBL",
        fare: 2000,
        tax: 300,
        price: 2300,
        currency_type_id: 1,
        comments: "El precio incluye impuestos y tasas de aeropuerto.",
        id_tours: 4,
      },
      {
        id: 5,
        base: "DBL",
        fare: 2900,
        tax: 400,
        price: 3300,
        currency_type_id: 1,
        comments: "El precio incluye cena en un lodge privado y safari nocturno.",
        id_tours: 4,
      },
      {
        id: 6,
        base: "DBL",
        fare: 3800,
        tax: 500,
        price: 4300,
        currency_type_id: 1,
        comments: "Todo incluido con un guía privado y actividades personalizadas.",
        id_tours: 7,
      },
      {
        id: 7,
        base: "DBL",
        fare: 3200,
        tax: 450,
        price: 3650,
        currency_type_id: 1,
        comments: "Incluye un safari de tres días y un tour cultural por Soweto.",
        id_tours: 7,
      },
    ],
  },
  {
    id: 9,
    title: "AVENTURA EN AMÉRICA DEL SUR",
    origin_code: "LIM", // Origin code: Lima
    destination_code: "BOG", // Destination code: Bogotá
    description_general:
      "Explora los impresionantes paisajes y culturas de América del Sur, desde la antigua Machu Picchu hasta la vibrante Bogotá.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 4 y 5 estrellas, desayuno diario, traslados y tours guiados.",
    not_included: "Almuerzos y cenas no mencionadas, gastos personales, seguros de viaje.",
    itinerary:
      "Día 1: Llegada a Lima. Día 2: Tour por Lima. Día 3: Vuelo a Cusco. Día 4: Visita a Machu Picchu. Día 5: Regreso a Cusco. Día 6: Vuelo a Bogotá. Día 7: Tour por Bogotá. Día 8: Regreso a casa.",
    terms:
      "Cancelación gratuita hasta 45 días antes del viaje. Pago completo requerido 30 días antes de la salida.",
    flyer: "https://example.com/flyer-aventura-america-del-sur.pdf",
    portrait: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxKMU48sQYLsNbgey9OEAjQtN8r14NQgXK_g&s",
    pictures: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCsLP44gLwCAacbFbFvp8PQmstJy32LYUJww&s",
    ],
    country_id: 9,
    tour_op_id: 9,
    user_id: 1009,
    supplier_id: 9,
    package_category_id: 9,
    show: true,
    tours: [
      {
        id: null,
        dateIn: "2025-05-01",
        dateOut: "2025-05-08",
        hoteles: "Hotel Lima Central, Hotel Cusco Plaza",
        categoría: "4 estrellas",
        meal_plan: "Desayuno incluido",
        days: 8,
        nights: 7,
        stock: 50,
        original_stock: 50,
        availability: "Y",
        comments:
          "Incluye entradas a Machu Picchu y tours guiados en Lima y Bogotá.",
        id_package: null,
      },
      {
        id: null,
        dateIn: "2025-06-10",
        dateOut: "2025-06-17",
        hoteles: "Hotel Lima Luxury, Hotel Cusco Deluxe",
        categoría: "5 estrellas",
        meal_plan: "Desayuno y cena incluidos",
        days: 8,
        nights: 7,
        stock: 30,
        original_stock: 30,
        availability: "Y",
        comments:
          "Incluye cena típica peruana y un tour por los mercados locales.",
        id_package: null,
      },
      {
        id: null,
        dateIn: "2025-07-15",
        dateOut: "2025-07-22",
        hoteles: "Hotel Lima Heritage, Hotel Cusco Boutique",
        categoría: "4 y 5 estrellas",
        meal_plan: "Todo incluido",
        days: 8,
        nights: 7,
        stock: 20,
        original_stock: 20,
        availability: "Y",
        comments:
          "Tour exclusivo con guía privado y actividades culturales adicionales.",
        id_package: null,
      },
    ],
    fee: [
      {
        id: null,
        base: "DBL",
        fare: 1600,
        tax: 250,
        price: 1850,
        currency_type_id: 1,
        comments: "El precio incluye impuestos y tasas de aeropuerto.",
        id_tours: null,
      },
      {
        id: null,
        base: "DBL",
        fare: 2300,
        tax: 350,
        price: 2650,
        currency_type_id: 1,
        comments: "Incluye cena típica y entradas a Machu Picchu.",
        id_tours: null,
      },
      {
        id: null,
        base: "DBL",
        fare: 3100,
        tax: 400,
        price: 3500,
        currency_type_id: 1,
        comments: "Todo incluido con un guía privado y actividades personalizadas.",
        id_tours: null,
      },
    ],
  },{
    id: 10,
    title: "EXPLORANDO EL CARIBE",
    origin_code: "CUN", // Origin code: Cancún
    destination_code: "HAV", // Destination code: La Habana
    description_general:
      "Disfruta de las playas de ensueño y la rica cultura del Caribe, desde Cancún hasta La Habana.",
    include:
      "Vuelos de ida y vuelta, alojamiento en resorts de 4 y 5 estrellas, todo incluido, traslados y tours guiados.",
    not_included: "Gastos personales, seguros de viaje.",
    itinerary:
      "Día 1: Llegada a Cancún. Día 2: Relax en la playa. Día 3: Excursión a Tulum. Día 4: Vuelo a La Habana. Día 5: Tour por La Habana. Día 6: Playa Varadero. Día 7: Regreso a Cancún. Día 8: Vuelo de regreso.",
    terms:
      "Cancelación gratuita hasta 30 días antes del viaje. Pago completo requerido 15 días antes de la salida.",
    flyer: "https://example.com/flyer-explorando-caribe.pdf",
    portrait: "https://media.admagazine.com/photos/65e162fe0c63d54d11a70f0d/16:9/w_2992,h_1683,c_limit/Islas%20del%20Caribe%20para%20vacacionar.jpg",
    pictures: [
    ],
    country_id: 10,
    tour_op_id: 10,
    user_id: 1010,
    supplier_id: 10,
    package_category_id: 10,
    show: true,
    tours: [
      {
        id: null,
        dateIn: "2025-08-01",
        dateOut: "2025-08-08",
        hoteles: "Hotel Cancún Beach, Hotel La Habana Paradise",
        categoría: "4 estrellas",
        meal_plan: "Todo incluido",
        days: 8,
        nights: 7,
        stock: 100,
        original_stock: 100,
        availability: "Y",
        comments:
          "Incluye entradas a Tulum y tours guiados en La Habana.",
        id_package: null,
      },
      {
        id: null,
        dateIn: "2025-09-15",
        dateOut: "2025-09-22",
        hoteles: "Resort Cancún Luxury, Hotel La Habana Grande",
        categoría: "5 estrellas",
        meal_plan: "Todo incluido",
        days: 8,
        nights: 7,
        stock: 60,
        original_stock: 60,
        availability: "Y",
        comments:
          "Incluye cena de bienvenida y un tour en jeep por la playa.",
        id_package: null,
      },
      {
        id: null,
        dateIn: "2025-10-10",
        dateOut: "2025-10-17",
        hoteles: "Hotel Cancún All-Inclusive, Resort La Habana Exclusive",
        categoría: "5 estrellas",
        meal_plan: "Todo incluido",
        days: 8,
        nights: 7,
        stock: 30,
        original_stock: 30,
        availability: "Y",
        comments:
          "Tour exclusivo con guía privado y actividades culturales adicionales.",
        id_package: null,
      },
    ],
    fee: [
      {
        id: null,
        base: "DBL",
        fare: 2000,
        tax: 300,
        price: 2300,
        currency_type_id: 3,
        comments: "Incluye impuestos y tasas de aeropuerto.",
        id_tours: null,
      },
      {
        id: null,
        base: "DBL",
        fare: 2800,
        tax: 350,
        price: 3150,
        currency_type_id: 1,
        comments: "Incluye cena de bienvenida y entradas a Tulum.",
        id_tours: null,
      },
      {
        id: null,
        base: "DBL",
        fare: 3500,
        tax: 400,
        price: 3900,
        currency_type_id: 2,
        comments: "Todo incluido con un guía privado y actividades personalizadas.",
        id_tours: null,
      },
    ],
  },
  {
    id: 11,
    title: "DESCUBRE JAPÓN",
    origin_code: "NRT", // Origin code: Tokio
    destination_code: "HND", // Destination code: Tokio
    description_general:
      "Sumérgete en la rica cultura, historia y tecnología de Japón. Desde la modernidad de Tokio hasta la tradición de Kioto.",
    include:
      "Vuelos de ida y vuelta, alojamiento en hoteles de 4 estrellas, desayuno diario, traslados.",
    not_included: "Almuerzos y cenas no mencionadas, gastos personales, seguros de viaje.",
    itinerary:
      "Día 1: Llegada a Tokio. Día 2: Tour por Tokio. Día 3: Viaje a Kioto. Día 4: Exploración de templos en Kioto. Día 5: Regreso a Tokio. Día 6: Día libre en Tokio. Día 7: Regreso a casa.",
    terms:
      "Cancelación gratuita hasta 30 días antes del viaje. Pago completo requerido 14 días antes de la salida.",
    flyer: "https://example.com/flyer-descubre-japon.pdf",
    portrait: "https://example.com/portrait-descubre-japon.jpg",
    pictures: [
      "https://example.com/tokyo.jpg",
      "https://example.com/kioto.jpg",
      "https://example.com/japon.jpg",
    ],
    country_id: 11,
    tour_op_id: 11,
    user_id: 1011,
    supplier_id: 11,
    package_category_id: 11,
    show: true,
    tours: [],  // Empty array for tours
    fee: []     // Empty array for fees
  }
];
