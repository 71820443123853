import { useTravelers } from "../../../hooks/useTravelers";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SEO from "../../../utils/SEO";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import { saveTourOperator } from "../../../utils/saveTourOperator";
import { zodResolver } from "@hookform/resolvers/zod";
import { traveleSchema } from "../../../schemas/traveler";

export default function CreateTravelerForm() {
  const { tour_op_id } = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const navigate = useNavigate();
  const { createTraveler } = useTravelers();
  const [traveler, setTraveler] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    email_address: "",
    booking_id: "",
    agencyg_id: "",
    category: "",
    tour_op_id: tourOP.id,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(traveleSchema) });

  const handleInputChange = (e) => {
    const { value } = e.target;
    setTraveler((prevTraveler) => ({
      ...prevTraveler,
      gender: value,
    }));
  };

  const onSubmit = async (data) => {
    toast.promise(
      createTraveler(traveler)
        .then((e) => {
          navigate(`/${tour_op_id}/panel/travelers`, {
            replace: true,
          });
        })
        .catch((e) => {
          console.log(e);
          throw new Error();
        }),
      {
        loading: "Cargando...",
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };
  return (
    <>
      <SEO title={"Agregar Pasajero"} />
      <div className="w-full">
        <header className="flex justify-between my-16 max-w-[1200px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">
            Agregar Pasajero
          </h1>
          <Link
            to={`/${tour_op_id}/panel/travelers`}
            className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Pasajeros
          </Link>
        </header>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="my-10 mt-16 mx-auto w-3/4 bg-white rounded-lg p-8"
        >
          <section className="grid  gap-2 my-2 p-4">
            <div className="col">
              <div className="grid grid-cols-2 mb-4">
                <div className="w-full relative flex flex-col">
                  <label>
                    Nombre <span className="text-red-400">*</span>
                  </label>
                  <input
                    className="addPackInput"
                    name="first_name"
                    {...register("first_name", {
                      required: "Nombre es requerido",
                    })}
                    onChange={(e) =>
                      setTraveler({ ...traveler, first_name: e.target.value })
                    }
                  />
                  <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="first_name" as="p" />
                  </span>
                </div>

                <div className="w-full relative flex flex-col">
                  <label>
                    Apellido <span className="text-red-400">*</span>
                  </label>
                  <input
                    className="addPackInput"
                    name="last_name"
                    {...register("last_name", {
                      required: "Apellido es requerido",
                    })}
                    onChange={(e) =>
                      setTraveler({ ...traveler, last_name: e.target.value })
                    }
                  />
                  <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="last_name" as="p" />
                  </span>
                </div>
              </div>

              <div className="mb-4">
                <label>
                  Fecha de Nacimiento
                  <label>
                    <span className="text-red-400">*</span>
                  </label>
                </label>
                <div className="col-span-2 relative">
                  <input
                    name="date_of_birth"
                    {...register("date_of_birth")}
                    type="date"
                    className="addPackInput"
                    id="date_of_birth"
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setTraveler({
                        ...traveler,
                        date_of_birth: e.target.value,
                      })
                    }
                  />
                </div>
                <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="date_of_birth" as="p" />
                </span>
              </div>

              <div className="mb-4">
                <label>
                  Sexo
                  <label>
                    <span className="text-red-400">*</span>
                  </label>
                </label>
                <span className="flex items-center gap-4 col-span-2">
                  <fieldset>
                    <div className="flex items-center gap-3">
                      <input
                        {...register("gender")}
                        type="radio"
                        id="gender_F"
                        name="gender"
                        value="F"
                        checked={traveler.gender === "F"}
                        onChange={handleInputChange} // Unified handler
                      />
                      <label htmlFor="gender_F">Femenino</label>

                      <input
                        {...register("gender")}
                        type="radio"
                        id="gender_M"
                        name="gender"
                        value="M"
                        checked={traveler.gender === "M"}
                        onChange={handleInputChange} // Unified handler
                      />
                      <label htmlFor="gender_M">Masculino</label>
                    </div>
                  </fieldset>
                  <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="gender" as="p" />
                  </span>
                </span>
              </div>

              <div className="w-full relative flex flex-col mb-8">
                <label>
                  Categoria
                  <label>
                    <span className="text-red-400">*</span>
                  </label>
                </label>

                <select
                  className="addPackInput"
                  name="category"
                  {...register("category", {
                    required: "Categoria es requerido",
                  })}
                  onChange={(e) =>
                    setTraveler({ ...traveler, category: e.target.value })
                  }
                >
                  <option>ninguno</option>
                  <option>directivos</option>
                  <option>cuerpo tecnico</option>
                  <option>jugadores</option>
                  <option>plantel</option>
                  <option>reserva</option>
                  <option>juveniles</option>
                  <option>inferiores</option>
                  <option>otro1</option>
                  <option>otro2</option>
                  <option>otro3</option>
                </select>
              </div>
              <div className="w-full relative flex flex-col mb-8">
                <label>
                  Email
                  <label>
                    <span className="text-red-400">*</span>
                  </label>
                </label>
                <input
                  className="addPackInput"
                  name="email_address"
                  {...register("email_address", {
                    required: "Email es requerido",
                  })}
                  onChange={(e) =>
                    setTraveler({ ...traveler, email_address: e.target.value })
                  }
                />
                <span className="mt-2 text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="email_address" as="p" />
                </span>
              </div>
            </div>
          </section>

          <button className="font-semibold text-lg ml-auto mt-4 button w-1/4 bg-brightBlue text-white hover:shadow-lg transition-all">
            Guardar
          </button>
        </form>
      </div>
    </>
  );
}
