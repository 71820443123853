import React, { useState } from "react";
import TableRowPackages from "./tableRow/TableRowPackages";
import TableTourRow from "./tableRow/TableTourRow";

export default function TablePackagesBody({ setTour,packages }) {
  // Crear un arreglo de visibilidad para cada paquete
  const [isTourVisible, setIsTourVisible] = useState(
    Array(packages.length).fill(false)
  );

  // Alterna la visibilidad para el paquete en el índice dado
  const toggleTourVisibility = (index) => {
    setIsTourVisible((prev) => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  return (
    <>
      <tbody className="text-sm xl:text-base items-center text-gray">
        {packages.length > 0 ? (
          packages.map((pkg, index) => (
            <React.Fragment key={index}>
              <TableRowPackages
                index={index}
                pkg={pkg}
                isTourVisible={isTourVisible[index]}
                toggleTourVisibility={() => toggleTourVisibility(index)}
              />
              {isTourVisible[index] && (
                <tr className="rounded-lg">
                  <td colSpan="7">
                    <TableTourRow setTour={setTour} pkg={pkg} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan="7" className="text-left my-2">
              No hay paquetes cargados...
            </td>
          </tr>
        )}
      </tbody>
    </>
  );
}
