import React from "react";
import PageTitle from "../../../utils/PageTitle";
import TablePackages from "./tablePackages/TablePackages";
import SEO from "../../../utils/SEO";

export default function AdminPackages() {
  return (
    <>
    <div className="mt-8">
    <SEO title={"Mis Paquetes"} />
      <PageTitle
        title={"Mis Paquetes"}
        subTitle={" Controlá, Gestioná y Personalizá cada uno de tus paquetes."}
        route={"panel/packages/add-package"}
        btnText={"Agregar Paquete"}
        buttonVisivility={true}
      />
      <TablePackages />
    </div>
      
      
    </>
  );
}
