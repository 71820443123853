import React, { useEffect, useMemo, useState } from "react";
import PackageCard from "./PackageCard/PackageCard";
import PackageListFilters from "./PackageListFilters/PackageListFilters";
import { monthMap } from "../../../utils/months";


export default function PackageListing({ packages, parsedHash, currencies }) {
  const getMonthNumber = (monthName) => {
    return monthMap[monthName]; // Returns the month number (1-12)
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [visiblePackages, setVisiblePackages] = useState(20);
  const [sortCriteria, setSortCriteria] = useState({
    key: "price",
    direction: "asc",
  });
  const [filters, setFilters] = useState({
    month: parsedHash?.dateIn
      ? [getMonthNumber(parsedHash.dateIn.split(" ")[0])]
      : [],
    destination_code: parsedHash?.destination_code
      ? [parsedHash.destination_code.toUpperCase()]
      : [],
    origin_code: parsedHash?.origin_code
      ? [parsedHash.origin_code.toUpperCase()]
      : [],
    currency: [],
    year: parsedHash?.dateIn ? [String(parsedHash.dateIn.split(" ")[1])] : [],

    cities: {},
  });

  // Debounce variables
  let scrollTimeout;

  // Function to handle scrolling with debounce
  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    scrollTimeout = setTimeout(() => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 500
      ) {
        setVisiblePackages((prev) => prev + 20);
      }
    }, 200); // 200ms delay to avoid firing the scroll event handler too frequently
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [visiblePackages]);

  const [passengers, setPassengers] = useState({
    adults: parsedHash.adults || "1",
    children: parsedHash.children || "0",
    infants: parsedHash.infants || "0",
  });

  const filteredPackages = useMemo(
    () =>
      packages?.filter(({ origin_code, destination_code, tours }) => {
        const descodeValues = Array.isArray(filters?.destination_code)
          ? filters.destination_code.map((code) => code.trim().toUpperCase())
          : [];
        const oricodeValues = filters?.origin_code || [];
        const monthValues = filters?.month || [];
        const currencyValues = filters?.currency || [];
        const yearValues = filters?.year || [];
        const stock = tours.reduce((maxStock, tour) => {
          return Math.max(maxStock, tour.stock || 0); // Compare and find the maximum stock value
        }, 0);
        const totalPassengers =
          Number(passengers.adults || 0) +
          Number(passengers.children || 0) +
          Number(passengers.infants || 0);
        const arrivalMonth = tours.map((tour) => {
          const dateIn = new Date(tour.dateIn);
          return dateIn.getMonth() + 1; // Month is zero-indexed, so we add 1
        });

        const arrivalYear = tours.map((tour) => {
          const dateIn = new Date(tour.dateIn);
          const yearNumber = dateIn.getFullYear();
          return String(yearNumber);
        });

        // Handle null values for currency_type_id inside each tour's fees array
        const isCurrencyValid = tours.some(({ fees }) =>
          fees.some(({ currency_type_id }) =>
            currencyValues.includes(currency_type_id)
          )
        );

        const upperCaseSearchTerm = searchTerm.toUpperCase();
        const searchFilter =
          searchTerm.trim() === ""
            ? true
            : (origin_code &&
                origin_code.toUpperCase().includes(upperCaseSearchTerm)) ||
              (destination_code &&
                destination_code.toUpperCase().includes(upperCaseSearchTerm));

        return (
          (descodeValues.length >= 0 ||
            (destination_code &&
              parsedHash.destination_code &&
              destination_code.toUpperCase() ===
                parsedHash.destination_code.toUpperCase())) &&
          (currencyValues.length === 0 || isCurrencyValid) &&
          (descodeValues.length === 0 ||
            (destination_code &&
              descodeValues.some((code) =>
                destination_code.toUpperCase().includes(code.toUpperCase())
              ))) &&
          (oricodeValues.length === 0 ||
            (origin_code &&
              oricodeValues.some((code) =>
                origin_code.toUpperCase().includes(code.toUpperCase())
              ))) &&
          (monthValues.length === 0 ||
            (arrivalMonth &&
              arrivalMonth.some((month) => monthValues.includes(month)))) &&
          (yearValues.length === 0 ||
            (arrivalYear &&
              arrivalYear.some((year) => yearValues.includes(year)))) &&
          stock !== null &&
          totalPassengers !== null &&
          stock >= totalPassengers &&
          searchFilter
        );
      }),
    [packages, filters, parsedHash, searchTerm, passengers]
  );

  const sortedPackages = useMemo(() => {
    const sorted = [...filteredPackages]; // Make a copy of the filtered packages

    if (sortCriteria.key) {
      sorted.sort((a, b) => {
        let aValue, bValue;

        if (sortCriteria.key === "price") {
          // Sort by price - assuming fees is an array and we take the first price from the first tour
          aValue = a.tours[0]?.fees[0]?.price || 0;
          bValue = b.tours[0]?.fees[0]?.price || 0;
        } else if (sortCriteria.key === "title") {
          // Sort by title
          aValue = a.title;
          bValue = b.title;
        } else if (sortCriteria.key === "date") {
          // Sort by the earliest date in tours
          aValue = new Date(a.tours[0]?.dateIn).getTime();
          bValue = new Date(b.tours[0]?.dateIn).getTime();
        } else if (sortCriteria.key === "stock") {
          // Sort by stock value in tours
          aValue = a.tours[0].stock;
          bValue = b.tours[0].stock;
        } else {
          return 0; // No valid sorting key
        }

        if (sortCriteria.direction === "asc") {
          if (typeof aValue === "string" && typeof bValue === "string") {
            return aValue.localeCompare(bValue);
          }
          return aValue > bValue ? 1 : -1;
        } else {
          if (typeof aValue === "string" && typeof bValue === "string") {
            return bValue.localeCompare(aValue);
          }
          return aValue < bValue ? 1 : -1;
        }
      });
    }

    return sorted;
  }, [filteredPackages, sortCriteria]);

  const reducedPackages = packages?.reduce(
    (acc, { origin_code, destination_code, tours }) => {
      const dateInValues = tours?.map((tour) => tour.dateIn) ?? [];

      return {
        origin_code: [...acc.origin_code, origin_code],
        destination_code: [...acc.destination_code, destination_code],
        dateIn: [...acc.dateIn, ...dateInValues],
      };
    },
    { origin_code: [], destination_code: [], dateIn: [] }
  );

  return (
    <div className="flex mb:flex-col lg:flex-row">
      <div className="lg:ml-[10%]">
        <PackageListFilters
          packageData={sortedPackages}
          reducedPackages={reducedPackages}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setFilters={setFilters}
          setPassengers={setPassengers}
          passengers={passengers}
          filters={filters}
          sortCriteria={sortCriteria}
          setSortCriteria={setSortCriteria}
        />
      </div>

      <div className="flex flex-col gap-4 mb:mt-8 lg:mt-0">
        {(sortedPackages.slice(0, visiblePackages) || []).map((pkg, index) => (
          <div
            key={index}
            className="flex mb:flex-col lg:flex-row items-center mb:-ml-20 lg:ml-0 justify-center gap-4"
          >
            <PackageCard
              packageData={pkg}
              currencies={currencies}
              passengers={passengers}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
