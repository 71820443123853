import React from 'react'
import { useLocationQuery } from '../../hooks/useLocationQuery';
import FlightsBookings from '../flights/booking/FlightsBookings';
import PackagesBooking from '../packages/booking/PackagesBooking';

export default function Detail() {
    const { parsedHash } = useLocationQuery();
    const type = parsedHash?.type;
  return (
   <>
   {type === "flights" ? (<FlightsBookings/>) : (<PackagesBooking/>)}
   </>
  )
}
