import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelectPaquetes } from "../../../useSelectPaquetes";
import { useToggleVisibility } from "../../../../../hooks/useToggleVisibility";
import clsx from "clsx";
import numberWithCommas from "../../../../../utils/numberWithCommas";
import { ModalGeneric } from "../../../../../utils/ModalGeneric";
import destino from "../../../../../iconos/destino_gris.png";
import missing from "../../../../../iconos/missing.jpg";
import gym from "../../../../../iconos/gym.png";
import swim from "../../../../../iconos/swim.png";
import dinner from "../../../../../iconos/dinner.png";
import shop from "../../../../../iconos/shop.png";
import wifi from "../../../../../iconos/wifi.png";

import info from "../../../../../iconos/info.png";
import PackageCardModal from "./PackageCardModal";
import { Button } from "react-day-picker";

export default function PackageCardDetails({
  packageData,
  currencies,
  passengers,
}) {
  const { tour_op_id, package_id } = useParams();
  const selectPackage = useSelectPaquetes((st) => st.selectPackage);
  const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  const [isVisible, toggleVisibility] = useToggleVisibility();
  // Get the current date
  const currentDate = new Date();

  const Pasajeros =
    Number(passengers.adults) +
    Number(passengers.children) +
    Number(passengers.infants);

  if (package_id && !packageData?.id) {
    return <h1>No se encontró el paquete con el id: {package_id}</h1>;
  }

  const { id, title, destination_code, description, stock, tours, portrait } =
    packageData;

  // Handle case where the fees array might be empty
  const closestTour =
    tours.length > 0
      ? tours.reduce((closest, tour) => {
          const tourDate = new Date(tour.dateIn); // Convert "YYYY-MM-DD" string to a Date object
          const closestDate = new Date(closest.dateIn);

          return Math.abs(tourDate - currentDate) <
            Math.abs(closestDate - currentDate)
            ? tour
            : closest;
        }, tours[0])
      : null;

  // Handle fees inside closest tour
  const fees = closestTour?.fees || [];
  const minFee =
    fees.length > 0
      ? fees.reduce((min, fee) => (fee.price < min.price ? fee : min), fees[0])
      : null;

  // Provide fallback values if fees array is empty
  const price = minFee ? minFee.price : 0;
  const tax = minFee ? minFee.tax : 0;
  const currency_type_id = minFee ? minFee.currency_type_id : null;

  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  // Handle case where the tours array might be empty

  // Destructure the closest tour details if the array is not empty
  const closestDateIn = closestTour
    ? new Date(closestTour.dateIn).toLocaleDateString()
    : "No hay tours disponibles";

  return (
    <>
      <Link
        to={`/${tour_op_id}/package-detail/`}
        aria-label="View package details"
        state={{ packageData: packageData }}
        // onClick={() => {
        //   if (packageSelected?.id === packageData.id) {
        //     selectPackage(!packageData); // Deselect if the same package is clicked
        //   } else {
        //     selectPackage(packageData); // Select the new package
        //   }
        // }}
        className={clsx(
          "lg:hover:scale-105  mb:flex-col mb:w-3/5 md:flex-row lg:w-3/4 font-body flex mb:justify-center lg:justify-between items-center h-full  rounded-xl border-2 gap-8 cursor-pointer transition-all",
          {
            "border-2 border-brightBlue": packageSelected.id === id,
          },
          {
            "border-lightGray2 hover:border-lightBlue":
              packageSelected.id !== id,
          }
        )}
      >
        <div
          onClick={toggleVisibility}
          className="mb:w-full lg:w-[20%] mb:height:1/4 lg:h-full flex-shrink-0 "
        >
          {" "}
          {/* Ensure the container has full height */}
          <img
            className="w-full h-full object-cover rounded-md"
            src={portrait || missing} // Try to load the portrait first, then missing
            alt="" // No alt text
            onError={(e) => {
              if (e.target.src !== missing) {
                // Prevents infinite loop
                e.target.src = missing; // Fallback to the missing image
              }
            }}
          />
        </div>

        <div
          onClick={toggleVisibility}
          className="lg:w-1/2 flex flex-col h-full p-4"
        >
          <span className="flex flex-col gap-1">
            <h2 className="text-[#242423] text-2xl font-[700] capitalize">
              {title?.toLowerCase()}
            </h2>
            <span className="flex items-center gap-1">
              <img className="w-3" src={destino} alt="destino" />
              <p className="text-[#8C8C8C] text-xs">{destination_code}</p>
            </span>
          </span>
          <span>
            <div className="text-[#242423] font-[500] line-clamp-3">
              {description?.split("<br>").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <span className="flex gap-4 mt-2">
              <p className="text-[#8C8C8C] text-sm whitespace-nowrap">
                ({Pasajeros} {Pasajeros > 1 ? "pasajeros" : "pasajero"})
              </p>
              <span className="flex items-center gap-3">
                <img src={gym} alt="gym" className="w-3" />
                <img src={swim} alt="swim" className="w-3" />
                <img src={dinner} alt="dinner" className="w-3" />
                <img src={shop} alt="shop" className="w-3" />
                <img src={wifi} alt="wifi" className="w-3" />
              </span>
            </span>
          </span>
          <span className="flex flex-inline gap-2 mt-2 ">
            <div>Proxima Salida:</div>
            {closestDateIn}
          </span>
          {/* <div className="flex items-end justify-between">
          <div className="flex gap-4">
            <span className="relative bg-[#FAF2AE] h-7 flex justify-end rounded-full py-1 px-4 w-[74px]">
              <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-2 bg-[#EAD40B]">
                <img src={sun} alt="sun" className="w-4" />
              </span>
              <p className="bottom-1.5 w2">{nights + 1}</p>
            </span>
            <span className="relative bg-[#1E0F5033] h-7 flex justify-end rounded-full py-1 px-4 w-[74px]">
              <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-2 bg-[#091832]">
                <img src={moon} alt="sun" className="w-4 " />
              </span  >
              
              <p className="bottom-1.5 w2 ">{nights}</p>

            
            </span>
          </div>
          <span className="flex items-center gap-2 ml-4 mt-4">
            {/* <p>{format(new Date(departure_date), "dd-MM-yyyy")}</p>
            <p>
              {format(new Date(return_date), "dd-MM-yyyy", { locale: es })}
            </p> 
          </span>
        </div> */}
        </div>

        <div className="flex flex-col lg:justify-between lg:items-start lg:w-1/4 h-full border-l-2 border-lightGray p-4">
          <span className="flex flex-col items-end">
            <p className="text-sm">Desde</p>
            <span className="flex items-center gap-2 mt-1 w-full max-w-xs overflow-hidden">
              <img src={info} alt="icon_info" className="w-5" />
              <p className="text-xl text-[#242423] font-bold truncate">
                {translateCurrency(currency_type_id) ?? "USD"}{" "}
                {numberWithCommas(Number(price + Number(tax ?? "0")), 2)}
              </p>
            </span>
          </span>

          <Link
            className="py-6 lg:px-4 lg:py-2 text-center rounded-full text-sm shadow-lg mt-6 mb-1 bg-[#cedef1] hover:bg-[#c0d5ed] transition-colors duration-300"
            to={`/${tour_op_id}/package-detail/`}
            aria-label="View package details"
            state={{ packageData: packageData }}
          >
            Ver detalle
          </Link>
        </div>
      </Link>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <PackageCardModal
          title={title}
          portrait={portrait}
          description={description}
          stock={stock}
        />
      </ModalGeneric>
    </>
  );
}
