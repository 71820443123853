import { z } from "zod";

export const tourSchema = z.object({
    dateIn: z.string().min(1, { message: "Selecciona una fecha de Salida" }),
    dateOut: z.string().min(1, { message: "Selecciona una fecha de Llegada" }),
    hotels: z.string(),
    category: z.string(),
    meal_plan: z.string(),
    days: z.number(),
    nights: z.number(),
    stock: z
    .number()
    .refine((value) => value > 0, { message:"Ingresa un Stock" }),
    comments: z.string(),
})