import { z } from "zod";

export const feeSchema = z.object({
    base: z.string().min(1, { message: "Campo requerido" }),
    fare:z
    .number()
    .refine((value) => value > 0, { message:"Ingresa una Impuesto" }),
    tax:z
    .number()
    .refine((value) => value > 0, { message:"Ingresa una Tarifa" }),
    currency_type_id: z.number().refine((value) => value > 0, {message: "Selecciona un tipo de moneda"}),
  })
