import React from "react";
import { useParams } from "react-router-dom";
import ToggleButton from "../../../../../../utils/tableButtons/ToggleButton";
import SubMenuTourRow from "../tableButtons/SubMenuTourRow";

export default function TourRowAccionButtons({
  data,
  isVisible,
  setVisible,
}) {
  const tour_op_id = useParams();

  return (
    <div className="flex flex-row ">
      <ToggleButton
        toggleVisibility={setVisible}
        isVisible={isVisible}
      />



      <SubMenuTourRow tour_op_id={tour_op_id} pkg={data} />
    </div>
  );
}
