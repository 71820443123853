import React from "react";

export default function TableFeeRow({ cols }) {
  return (
    <tr className=" bg-lightBlue py-2 px-8 rounded-lg ">
      {cols.map((header, index) => (
        <th className="font-medium text-brightBlue text-center px-4" key={index}>
          {header}
        </th>
      ))}
    </tr>
  );
}