export const checkErrors = (errors, firstPassenger, type) => {
    const errorLength = Object.keys(errors)?.filter((key) => {
      return !errors[key]?.correct;
    }).length;
  
    if (
      errorLength > 0 ||
      Object.keys(errors).length <
        (type === "flights" ? (firstPassenger ? 8 : 7) : 4)
    ) {
      const btn = document.getElementById("reserve_btn");
      btn.classList.add("bg-red-400");
      btn.classList.add("hover:bg-red-400");
      btn.textContent = "Completar todos los campos";
  
      setTimeout(() => {
        btn.classList.remove("bg-red-400");
        btn.classList.remove("hover:bg-red-400");
        btn.textContent = "Reserva";
      }, 2000);
  
      return false;
    }
    return true;
  };
  