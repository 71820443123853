import React from "react";
import TravelersTableButtons from "../TravelersTableButtons";
import { ModalGeneric } from "../../../utils/ModalGeneric";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import ViewTravelerModal from "../ViewTravelerModal";
import { format } from "date-fns";

export default function TravelerRow({
  traveler,
  selectedRows,
  handleRowSelect,
  openModal
}) {
  const [isVisible, toggleVisibility] = useToggleVisibility();

  return (
    <>
      <tr
       
        key={traveler.id}
        className={`  ${
          selectedRows.includes(traveler)
            ? "hover:bg-lightBlue bg-blue-100"
            : "hover:bg-lightBlue bg-light-gray"
        }`}
      >
        <td className="py-2.5 px-4">
          <input
            className="form-checkbox h-5 w-5 text-blue-600 bg-gray-100 border-gray-300 rounded"
            type="checkbox"
            checked={selectedRows.includes(traveler)}
            onChange={() => handleRowSelect(traveler)}
          />
        </td>
        <td onClick={toggleVisibility} className="py-2.5 px-4">{traveler.last_name}</td>
        <td onClick={toggleVisibility} className="py-2.5 px-4 mb:hidden lg:table-cell">
          {traveler.first_name}
        </td>

        <td onClick={toggleVisibility} className="py-2.5 px-4 mb:hidden lg:table-cell">
          {traveler.gender === "M" ? "Masculino" : "Femenino"}
        </td>
        <td  onClick={toggleVisibility} className="py-2 px-4 text-left font-medium  mb:hidden lg:table-cell justify-center">
          {traveler?.traveler_document?.doc_type === "IDENTITY_CARD"
            ? "DNI"
            : traveler?.traveler_document?.doc_type === "PASSPORT"
            ? "Pasaporte "
            : traveler?.traveler_document?.doc_type ?? "-"}
        </td>
        <td onClick={toggleVisibility} className="py-2 px-4 text-left font-medium justify-center mb:hidden lg:table-cell">
          {traveler?.traveler_document?.number
            ? traveler?.traveler_document?.number
            : "-"}
        </td>
        <td  onClick={toggleVisibility} className="py-2.5 px-4 mb:hidden lg:table-cell">
          {format(new Date(traveler.date_of_birth), "dd/MM/yyyy")}
        </td>
        <td  onClick={toggleVisibility} className="py-2.5 px-4 mb:hidden lg:table-cell">
          {traveler.email_address}
        </td>
        <td>
          <TravelersTableButtons openModal={openModal} travelerData={traveler} />
        </td>
      </tr>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <ViewTravelerModal travelerData={traveler}  />
      </ModalGeneric>
    </>
  );
}
